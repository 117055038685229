// Customizable Area Start
//https://shrmawardapp-91344-ruby.b91344.dev.eastus.az.svc.builder.cafe
import React from "react";
import { message, Modal } from "antd";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import PaymentSuccessCard from "./container/PaymentSuccessCard.web";
import { logo } from './customAssets'
import { FailedToast, SuccessToast, WarningToast } from "./Toast";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  match: any;
}

interface Category {
  attributes: {
    created_at: string,
    id: string,
    name: string,
    price: any,
    updated_at: string,
    gst: any
  }
}

interface Organization {
  attributes: {
    id: string,
    created_at: string,
    updated_at: string,
    status: any,
    clearance_status: any,
    account: {
      organization_name: string
    },
    application_form: {
      data: {
        attributes: {
          id: string,
          media: any,
          feedback: string
        }
      }
    },

    has_both_docs: boolean

  }
}


interface SupportingDocuments {
  filename: any,
  file_url: any
}

interface Evaluation {
  attributes: {
    id: string,
    media: any,
    name: string

  }
}
interface NDAForm {
  attributes: {
    id: string,
    document: any

  }
}

interface SetScore {
  attributes: {
    id: string,
    initiative_score: number,
    stakeholders_score: number,
    scalibility_score: number,
    feedback: string,
    created_at: string,
    updated_at: string,

  }

}



interface S {
  selectedCategories: Category[],
  numberOfSelectedCategories: number,
  subTotalAmount: number,
  gstAmount: number,
  promoCodeDiscount: any,
  totalAmount: number,
  defaultselectedCategories: Category[],
  allCategories: Category[],
  defaultCatName: any,
  acceptTerms: boolean,
  errorMsg: string,
  data: string[],
  email: string,
  organization_name: string,
  spoc_name: string,
  designation: string,
  industry_type: string,
  user_role: string,
  full_phone_number: string,
  secondry_email: string,
  promoCode: string,
  // date_of_incorporation: string,
  address: string,
  industryTypes: any,
  countryCode: string,
  redirect: boolean,
  regiterBtnLoader: boolean,
  onRegistrationPage: boolean,
  redirectToDashboard: boolean,
  redirectToJuryDashboard: boolean,
  redirectToLeaderboard: boolean,
  redirectToLogin: boolean,
  leaderboardData: any,
  //jury
  visible: boolean,
  visibleTwo: boolean,
  juryDocNotUploaded: boolean,
  visibleThree: boolean,
  visibleForZoomForm: boolean,
  visibleZoomForm: boolean,
  visibleForTnC: boolean,
  visibleForZoomMeeting: boolean,
  name: string,
  organizationList: Organization[],
  organizationLoading: boolean,
  acceptTermsOfNDAForm: boolean,
  makePaymentButton: boolean,
  promoCodeApplied: boolean,
  media: any,
  feedback: string,
  applicationFormFeedBack: string,
  document: any,
  showNDAForm: boolean,
  successfullySubmitModal: boolean,
  document_urls: SupportingDocuments[],
  organization_id: any,
  org_score_id: any,
  user_category_id: any,
  score: any,
  selectedCategoryId: any,
  scoreData: any,
  scoreDataL3: any,
  scoreUpdating: boolean,
  topic: string,
  start_date: any,
  duration: number,
  zoom_id: any,
  note: any,
  start_url: any,
  accepted: boolean,
  enable_set_score: boolean,
  visibleForForgetPassword: boolean,
  zoomMeetingLoading: boolean,
  categoryLoading: boolean,
  registeredEmail: any,
  applyPromocodeStatusMsg: any,
  gstNumber: any,
  max_number_of_categories: any,
  gstNumberErrorMsg: any,
  addMoreCatPriceErrorMsg: any,
  discountAmount: any
  loadingLogin: boolean,
  goToConsolidated: boolean,
  registrationLoading: boolean,
  url: any,
  terms_and_conditions: any,
  privacy_policy: any,
  selectCat: any,
  is_special: any,
  viewDocumentUrl: any,
  viewDocumentType: any,
  viewDocumentName: any,
  viewDocument: any
}

interface SS {
  id: any;
}

interface Registration {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;

}

export default class UserController extends BlockComponent<Props, S, SS> {

  gotoConsolidateLeaderboardApiCallId: any = "";
  getUserCategoryDataApiCallId: any = "";
  getUserUploadedDocuments: any = "";
  apiResetGetAllCategoriesCallId: string = "";
  apiResetGetIndustryType: string = "";
  apiResetGetAllCategoryPricesCallId: string = "";
  apiResetPaymentPostCallId: string = "";
  apiZeroPaymentPostCallId: string = "";
  apiGstNumberPostCallId: string = '';
  apiResetRegistrationPostCallId: string = "";
  apiResetPaymentTwoPostCallId: string = "";
  apiResetLoginPostCallId: string = "";
  apiResetConsolidatedData: string = "";
  apiResetFinalRegistrationPostCallId: string = "";
  apiResetPaymentResPostCallId: string = "";
  //jury
  apiResetGetOrganizationListCallId: string = "";
  apiResetGetApplicationFormCallId: string = "";
  apiResetGetNDAFormCallId: string = "";
  apiResetSubmitApplicationFormPostCallId: string = "";
  apiResetSubmitSetScorePostCallId: string = "";
  apiResetUpdateSetScorePostCallId: string = "";
  apiResetGetSupportingDocsCallId: string = "";
  apiSelectedCategoriesPriceCallId: string = "";
  apiResetSubmitNDAFormCallId: string = "";
  apiApplyPromocodeId: String = "";
  apiDiscountValue: String = "";
  //zoom
  apiResetZoomPostCallId: string = "";
  apiResetZoomPostCallId2: string = "";
  apiResetZoomGetCallId: string = "";

  apiResetSetScoreEnableApiPostCallId: string = "";

  // triggerEmail
  apiTriggerSendEmail: string = "";

  // applicationFormFeedback
  apiApplicationFormFeedback: string = "";
  // category price
  apiCategorypriceCallId: String = "";

  //forget password
  apiResetForgetPasswordPostCallId: string = "";

  // username
  username: string = "";

  //banner
  apiResetGetBannerFormCallId: string = "";

  //terms and condition
  apiResetGetTermsAndConditionFormCallId: string = "";

  //terms and condition
  apiResetGetPrivacyAndPolicyFormCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      leaderboardData: {},
      selectedCategories: [],
      numberOfSelectedCategories: 0,
      subTotalAmount: 0,
      gstAmount: 0,
      promoCodeDiscount: 0,
      totalAmount: 0,
      defaultselectedCategories: [],
      acceptTerms: false,
      errorMsg: '',
      data: [],
      email: '',
      organization_name: '',
      spoc_name: '',
      designation: '',
      industry_type: '',
      user_role: '',
      full_phone_number: '',
      secondry_email: '',
      promoCode: '',
      // date_of_incorporation:'',
      address: '',
      industryTypes: [],
      countryCode: '91',
      redirect: false,
      regiterBtnLoader: false,
      allCategories: [],
      defaultCatName: '',
      onRegistrationPage: true,
      redirectToDashboard: false,
      redirectToJuryDashboard: false,
      redirectToLeaderboard: false,
      redirectToLogin: false,
      //jury
      visible: false,
      visibleTwo: false,
      juryDocNotUploaded: false,
      visibleThree: false,
      visibleForZoomForm: false,
      visibleZoomForm: false,
      visibleForTnC: false,
      visibleForZoomMeeting: false,
      name: '',
      organizationList: [],
      organizationLoading: false,
      acceptTermsOfNDAForm: false,
      makePaymentButton: false,
      promoCodeApplied: true,
      media: '',
      feedback: '',
      applicationFormFeedBack: '',
      document: '',
      showNDAForm: false,
      successfullySubmitModal: false,
      document_urls: [],
      organization_id: '',
      org_score_id: '',
      user_category_id: '',
      score: null,
      selectedCategoryId: '',
      scoreData: {

      },
      scoreDataL3: {

      },
      scoreUpdating: false,
      topic: '',
      start_date: '',
      duration: 0,
      zoom_id: '',
      note: '',
      start_url: '',
      accepted: false,
      enable_set_score: false,
      visibleForForgetPassword: false,
      registeredEmail: '',
      zoomMeetingLoading: false,
      categoryLoading: false,
      registrationLoading: false,
      applyPromocodeStatusMsg: '',
      gstNumber: '',
      max_number_of_categories: 1,
      gstNumberErrorMsg: '',
      addMoreCatPriceErrorMsg: '',
      discountAmount: '',
      loadingLogin: false,
      goToConsolidated: false,
      url: '',
      terms_and_conditions: '',
      selectCat: 0,
      is_special: false,
      privacy_policy: '',
      viewDocumentUrl: '',
      viewDocumentType: '',
      viewDocumentName: '',
      viewDocument: false


    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleFormClose() {
    this.setState({ visibleZoomForm: false })
  }
  setScoreData(data: any) {
    this.setState({ scoreData: data });
  }
  setScoreDataL3(data: any) {
    this.setState({ scoreDataL3: data });
  }

  setScoreUpdating(value: boolean) {
    this.setState({ scoreUpdating: value });
  }

  changeScoreData(event: any, name: string) {
    let _data = this.state.scoreData;
    _data[name] = event.target.value
    if (localStorage.getItem("level") == '2') {
      this.setState({ scoreData: _data })
    } else {
      this.setState({ scoreDataL3: _data })
    }

  }


  receiveBanner(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetBannerFormCallId &&
      this.apiResetGetBannerFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ url: responseJson.data.attributes.banner_image.url });

    }
  }

  receiveTermsAndConditions(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetTermsAndConditionFormCallId &&
      this.apiResetGetTermsAndConditionFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ visibleForTnC: false, terms_and_conditions: responseJson.terms_and_conditions });

    }
  }


  receivePrivacyPolicy(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetPrivacyAndPolicyFormCallId &&
      this.apiResetGetPrivacyAndPolicyFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ privacy_policy: responseJson.privacy_policy });

    }
  }

  receiveSubmitNdaForm(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetSubmitNDAFormCallId &&
      this.apiResetSubmitNDAFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ showNDAForm: false, successfullySubmitModal: true });
      SuccessToast("Success", "You have successfully submitted NDA form.");
    }
  }

  receiveSetScore(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetSubmitSetScorePostCallId &&
      this.apiResetSubmitSetScorePostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.errors) {
        FailedToast("Error", responseJson.errors[0].feedback);
        return;
      }
      this.getOrganizationList(this.state.selectedCategoryId)
      this.setState({ visibleThree: false })
    }
  }

  receiveUpdateSetScore(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetUpdateSetScorePostCallId &&
      this.apiResetUpdateSetScorePostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.getOrganizationList(this.state.selectedCategoryId)
      this.setState({ visibleThree: false })
    }
  }

  receiveNdaForm(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetNDAFormCallId &&
      this.apiResetGetNDAFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (

        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {

        if (this.props?.navigation?.navigate && (typeof this.props?.navigation?.navigate === "function")) {
          localStorage.clear()
          this.props?.navigation?.navigate("EmailAccountLoginBlock")
        }
      }

      if (responseJson.has_accepted == false) {
        this.setState({ document: responseJson.nda.data.attributes.document });
        const openNDAForm = localStorage.getItem("NDAFormStatus")
        this.setState({ showNDAForm: true });

      }
    }
  }

  receiveDocs(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetSupportingDocsCallId &&
      this.apiResetGetSupportingDocsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (responseJson && responseJson.message) {
        FailedToast("Error", responseJson.message);
        return;
      } else if (responseJson && responseJson.errors) {
        this.setState({ juryDocNotUploaded: true })
        return;
      } else {
        this.setState({ document_urls: responseJson.document_urls, visibleTwo: true, });
      }


    }

  }

  receiveCategoryPrice(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSelectedCategoriesPriceCallId &&
      this.apiSelectedCategoriesPriceCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson === undefined) {
        return;
      } else if (responseJson && responseJson.message) {
        FailedToast("Error", responseJson.message);
        return;
      } else if (responseJson.promo_code_discount) {
        this.setState({
          applyPromocodeStatusMsg: `Promo Code Applied ${responseJson.promo_code_discount} discount`,
          discountAmount: responseJson.discount_amount,
          promoCodeDiscount: responseJson.promo_code_discount,
          promoCodeApplied: false,
          makePaymentButton: false,
          subTotalAmount: responseJson.sub_total,
          gstAmount: responseJson.gst_amount,
          totalAmount: responseJson.total_amount,
        })

      }
      else if (responseJson.errors && responseJson.errors[0]?.promo_code) {
        this.setState({
          applyPromocodeStatusMsg: `${responseJson.errors[0].promo_code}`,
          makePaymentButton: true,
          subTotalAmount: responseJson.sub_total,
          gstAmount: responseJson.gst_amount,
          totalAmount: responseJson.total_amount,
        })
      } else if (responseJson.errors && responseJson.errors[0]?.category_price) {

        this.setState({
          addMoreCatPriceErrorMsg: `${responseJson.errors[0].category_price}`,
          makePaymentButton: true,

        })
      } else {

        this.setState({
          applyPromocodeStatusMsg: ``,
          subTotalAmount: responseJson?.sub_total ? responseJson?.sub_total : 0,
          gstAmount: responseJson?.gst_amount ? responseJson?.gst_amount : 0,
          totalAmount: responseJson?.total_amount ? responseJson?.total_amount : 0,
          addMoreCatPriceErrorMsg: '',

        });
        if (this.state.addMoreCatPriceErrorMsg == '') {
          this.setState({
            makePaymentButton: false,
          })
        }

      }


    }
  }

  async receiveLogin(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetLoginPostCallId &&
      this.apiResetLoginPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.data) {
        let data = responseJson.data.attributes
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('user_id', data.id);
        localStorage.setItem('jury_panel', data.jury_panel);
        localStorage.setItem('role', data.user_role);
        localStorage.setItem('display_name', data.display_name);
        localStorage.setItem('special', data.is_special);
        localStorage.setItem('categories_assigned', JSON.stringify(data.categories_assigned));
        localStorage.setItem('username', this.username)
        localStorage.setItem('newly_login', 'true');

        this.setState({ is_special: data.is_special });

        if (data.user_role == 'shrm_admin') {
          await this.handelLeaderboard(true);
        } else if (data.user_role == 'organization') {
          this.setState({ redirectToDashboard: true });
        } else if (data.user_role == 'jury_penalist') {
          localStorage.setItem('level', data.jury_level == "level_2" ? "2" : "3");
          this.setState({ redirectToJuryDashboard: true });
        }

      }
      else if (responseJson && responseJson.errors) {
        responseJson.errors.forEach((error: any) => {
          if (error["failed_login"]) {
            FailedToast("Error", error["failed_login"]);
          }
        })
      }
      this.setState({ loadingLogin: false });
    }
  }

  receilvecategoryCallData(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUserCategoryDataApiCallId &&
      this.getUserCategoryDataApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        selectedCategories: responseJson.data.map((res: any) => res.attributes.category.data),
        selectCat: responseJson?.data?.length
      })
      this.setState({
        defaultselectedCategories: responseJson.data.map((res: any) => res.attributes.category.data)
      })


    }
  }

  receiveResetCategory(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetAllCategoriesCallId &&
      this.apiResetGetAllCategoriesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const level = localStorage.getItem("level")
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let _selectedCategories: any = [];
      if (localStorage.getItem('categories_assigned')) {
        _selectedCategories = JSON.parse(localStorage.getItem('categories_assigned') ?? "[]");
      }
      if (_selectedCategories.length > 0) {
        this.setState({ allCategories: responseJson.data.filter((e: any) => _selectedCategories.includes(e.attributes.name)) });
        let a: any = this.state.allCategories.filter((e: any) => { return e.attributes?.has_organization == true })
        this.setState({
          defaultCatName: a[0]?.name
        })
        this.getOrganizationList(a[0]?.id)
      } else if (level == '2') {
        this.setState({ allCategories: [] });
      } else {
        this.setState({ allCategories: responseJson.data });
      }
      if (responseJson && responseJson.data && responseJson.data.length) {
        localStorage.setItem("leaderboardData", JSON.stringify(responseJson.data))
      }
      if (responseJson && responseJson.gst_in) {
        this.setState({ gstNumber: responseJson.gst_in })
      }
      if (responseJson && responseJson.max_number_of_categories) {
        this.setState({ max_number_of_categories: responseJson.max_number_of_categories })
      }
      this.setState({ categoryLoading: false });
    }
  }

  receiveGetIndustryType(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetIndustryType ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.industries) {
        this.setState({ industryTypes: responseJson.industries });
      }
    }
  }

  receiveGetAllCategoryPrices(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetAllCategoryPricesCallId &&
      this.apiResetGetAllCategoryPricesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (

        responseJson &&
        responseJson.errors &&
        responseJson.errors[0].token
      ) {

        this.setState({ subTotalAmount: responseJson.sub_total });
      }
    }
  }

  receiveRegsitration(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetRegistrationPostCallId &&
      this.apiResetRegistrationPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        localStorage.clear();
        localStorage.setItem('authToken', responseJson.meta.token);
        localStorage.setItem('user_id', responseJson.data.id);

        this.setState({ redirect: true });
      }
      else if (responseJson && responseJson.errors) {
        responseJson.errors.forEach((error: any) => {
          if (error["account"]) {
            FailedToast("Error", error["account"]);
          } else if (error["email"]) {
            FailedToast("Error", "Email " + error["email"]);
          } else if (error["secondry_email"]) {
            FailedToast("Error", error["secondry_email"]);
          } else if (error["industry_type"]) {
            FailedToast("Error", "Industry type can't be blank, please select a type.");
          }
        })
      }
      this.setState({ regiterBtnLoader: false });
    }
  }

  receiveFinalRegistration(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetFinalRegistrationPostCallId &&
      this.apiResetFinalRegistrationPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        if (this.state.promoCodeDiscount == "100%") {
          this.zeroAmountPayment();
          setTimeout(() => {
            this.setState({ redirect: true, registrationLoading: false });
          }, 5000);
        }


      }
      else if (responseJson && responseJson.errors) {
        responseJson.errors.forEach((error: any) => {
          if (error["account"]) {
            FailedToast("Error", error["account"]);
          }
        })
      }

    }
  }

  receiveFinalPaymentTwo(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetPaymentTwoPostCallId &&
      this.apiResetPaymentTwoPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      PaymentSuccessCard();
      this.createFinalRegistration();
      localStorage.setItem('paymentId', responseJson.payment_details.attributes.id);
      SuccessToast("Thank you", localStorage.getItem('display_name') ? 'You have successfully paid the application payment.' : "You have successfully paid the application payment. Please check inbox for further assistance.");
      this.setState({ redirectToLogin: true, registrationLoading: false, defaultselectedCategories: this.state.selectedCategories })
      this.resForPayment(responseJson);

      if (responseJson.status === 200) {
        this.applyPromocode()
      }

    }
  }

  receiveFinalPayment(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetPaymentPostCallId &&
      this.apiResetPaymentPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const options = {
        key: __DEV__ ? 'rzp_test_Zh9IP49m4ahdlJ' : 'rzp_live_XzoINcxRAijRZ7',
        currency: responseJson.currency,
        amount: parseInt(responseJson.amount),
        order_id: responseJson.id,
        name: 'Payment',
        description: 'Thank you. The SHRM team',
        handler: async (response: any) => {
          const userToken = localStorage.getItem("authToken");
          const userId = localStorage.getItem("user_id");
          this.setState({ registrationLoading: true });
          const header = {
            "Content-Type": configJSON.contentTypeApiAddDetail,
            "token": userToken
          };

          const httpBody = {
            payment_id: response.razorpay_payment_id,
            id: userId
          };

          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.apiResetPaymentTwoPostCallId = requestMessage.messageId;
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.paymentAnotherApiEndPoint
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
          );

          runEngine.sendMessage(requestMessage.id, requestMessage);
          return true;
        },
        image: { logo },
        prefill: {
          name: 'Shipa'
        }
      }
      const _window = window as any;
      const paymentObject = new _window.Razorpay(options)
      paymentObject.open()
    }

  }

  receiveResetPaymentPost(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetPaymentResPostCallId &&
      this.apiResetPaymentResPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.data) {
        SuccessToast("Success", ".");
      }

    }
  }

  receiveZeroPayment(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiZeroPaymentPostCallId &&
      this.apiZeroPaymentPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson &&
        responseJson.organization) {
        setTimeout(() => {
          PaymentSuccessCard();
          SuccessToast("Thank you", "You have successfully paid the application payment. Please check inbox for further assistance.");
          this.setState({ registrationLoading: false, redirectToLogin: true, defaultselectedCategories: this.state.selectedCategories })

          this.applyPromocode()
        }, 100)
      }
    }
  }

  receiveGst(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGstNumberPostCallId &&
      this.apiGstNumberPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (

        responseJson &&
        responseJson.error
      ) {
        FailedToast("Error", "Something wrong please try after some time");
        return;
      }
      if (responseJson && responseJson.errors) {
        this.setState({ gstNumberErrorMsg: responseJson.errors[0].gst_in, makePaymentButton: true })
        if (this.state.gstNumber.length == 0) {
          this.setState({
            gstNumberErrorMsg: '',
            makePaymentButton: false
          })
        }
      }
      if (responseJson && responseJson.gst_in) {
        this.setState({
          gstNumberErrorMsg: '',
          makePaymentButton: false
        })
      }

    }
  }

  receiveOrganizationList(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetOrganizationListCallId &&
      this.apiResetGetOrganizationListCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.errors) {
        WarningToast("Alert", responseJson.errors[0].message);
      }
      this.setState({ organizationList: responseJson.data, organizationLoading: false });
    }
  }
  receiveApplicationForm(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetGetApplicationFormCallId &&
      this.apiResetGetApplicationFormCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson === undefined) {
        return;
      } else if (responseJson && responseJson.errors) {
        responseJson.errors.forEach((error: any) => {
          if (error["account"]) {
            WarningToast("Error", error["account"]);
          }
        })
        return;
      }
      this.setState({ media: responseJson.data?.attributes?.media, applicationFormFeedBack: responseJson.data?.attributes?.form_feedback?.feedback });
      this.setState({
        visible: true,
      });
    }
  }

  recieveZoom2(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetZoomPostCallId2 &&
      this.apiResetZoomPostCallId2 ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson?.data?.zoom_id) {
        localStorage.setItem('zoom_id', responseJson.data.zoom_id);
        this.setState({ visibleForZoomForm: false, visibleForZoomMeeting: true, start_date: responseJson?.data?.start_date, zoom_id: responseJson?.data?.zoom_id, topic: responseJson?.data?.topic, note: responseJson?.data?.note, zoomMeetingLoading: false, visibleZoomForm: false });

      }
    }
  }


  recieveZoomget(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetZoomGetCallId &&
      this.apiResetZoomGetCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson?.data?.zoom_id) {
        this.setState({ visibleForZoomForm: false, visibleForZoomMeeting: true, start_date: responseJson.data.start_date, zoom_id: responseJson.data.zoom_id, note: responseJson.data.note, topic: responseJson.data.topic, zoomMeetingLoading: false });

      } else {
        this.setState({ visibleZoomForm: true });
        return;
      }
    }
  }

  receiveEnableSetScore(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetSetScoreEnableApiPostCallId &&
      this.apiResetSetScoreEnableApiPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ enable_set_score: responseJson?.enable_set_score == 'true' })

    }
  }

  receiveLeaderboardData(message: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.gotoConsolidateLeaderboardApiCallId &&
      this.gotoConsolidateLeaderboardApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({
        leaderboardData: responseJson?.assigned_categories_leaderboard?.data
      }, () => {
      })

      if (responseJson?.assigned_categories_leaderboard?.data) {
        localStorage.setItem('leaderboardData', JSON.stringify(responseJson?.assigned_categories_leaderboard?.data))

        // }else{ 
        if (!this.state.goToConsolidated) {
          this.props.navigation.navigate('Leaderboard')
        } else {
          this.setState({ redirectToLeaderboard: true });
        }
        // }
      } else {
        FailedToast("Error", responseJson.errors[0]);
      }
    }
  }
  async receive(from: String, message: Message) {
    //Banner
    this.receiveBanner(message);

    //terms and conditions

    this.receiveTermsAndConditions(message);

    //privacy and policy
    this.receivePrivacyPolicy(message);




    // submit NDA form 
    this.receiveSubmitNdaForm(message)
    ///set score

    this.receiveSetScore(message)

    ///update set score
    this.receiveUpdateSetScore(message)

    //NDA form
    this.receiveNdaForm(message);




    /// docs
    this.receiveDocs(message);


    // category price 
    this.receiveCategoryPrice(message)


    //login
    this.receiveLogin(message)

    this.receilvecategoryCallData(message);

    this.receiveResetCategory(message);

    this.receiveGetIndustryType(message);

    //all categories prices

    this.receiveGetAllCategoryPrices(message)





    // for registration 

    this.receiveRegsitration(message)

    //final registration
    this.receiveFinalRegistration(message)


    //payment api two
    this.receiveFinalPaymentTwo(message)

    //response for payment
    this.receiveFinalPayment(message);


    //another res for payment

    this.receiveResetPaymentPost(message);

    // zero payment 
    this.receiveZeroPayment(message);

    //gst number
    this.receiveGst(message);


    //payment two

    //jury organization list
    this.receiveOrganizationList(message);



    //application form
    this.receiveApplicationForm(message);

    // zoom

    this.recieveZoom2(message);

    this.recieveZoomget(message);

    //enable set score 
    this.receiveEnableSetScore(message);

    // trigger send email
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiTriggerSendEmail &&
      this.apiTriggerSendEmail ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson) {
        alert(responseJson.success)
      }
    }
    // application form
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiApplicationFormFeedback &&
      this.apiApplicationFormFeedback ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.errors) {
        FailedToast("Error", responseJson.errors[0].message);
        return
      }
      if (responseJson.success) {
        SuccessToast("Success", responseJson.success[0].message);
      }
    }


    // get discount value (with add promocode)

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDiscountValue &&
      this.apiDiscountValue ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {


      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson.promo_code_discount) {
        this.setState({
          applyPromocodeStatusMsg: `Promo Code Applied ${responseJson.promo_code_discount} discount`,
          discountAmount: responseJson.discount_amount,
          promoCodeDiscount: responseJson.promo_code_discount,
          // subTotalAmount: responseJson.sub_total,
          // gstAmount: responseJson.gst_amount,
          // totalAmount: responseJson.total_amount,      
          promoCodeApplied: false,
          makePaymentButton: false,
        })

        if (responseJson.sub_total > 0) {
          this.setState({
            subTotalAmount: responseJson.sub_total,
            gstAmount: responseJson.gst_amount,
            totalAmount: responseJson.total_amount,
          })
        }
      }
      if (responseJson.errors && responseJson.errors[0]?.promo_code) {
        this.setState({
          applyPromocodeStatusMsg: `${responseJson.errors[0].promo_code}`,
          discountAmount: 0,
          makePaymentButton: true,
          gstAmount: 0,
          subTotalAmount: 0,
          totalAmount: 0
        })
        this.getSelectedCategoriesPrice(this.state.numberOfSelectedCategories)
        // this.getDiscountValue(this.state.promoCode)
      }

      if (responseJson.error) {
        this.setState({
          applyPromocodeStatusMsg: "Promo Code Does Not Exist",
          discountAmount: 0,
          makePaymentButton: true
        })
      }

    }


    // LeaderBoard

    this.receiveLeaderboardData(message)


    //forget password 

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResetForgetPasswordPostCallId &&
      this.apiResetForgetPasswordPostCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ visibleForForgetPassword: false, registeredEmail: responseJson.email, user_role: responseJson.user_role });

      if (responseJson.success) {
        let successMsg = responseJson.success
        SuccessToast("Success", <span dangerouslySetInnerHTML={{ __html: successMsg }}></span>);
      }
      if (responseJson.errors) {
        FailedToast("Failed", responseJson.errors);
      }
      //@ts-ignore
      this.formRef.current.resetFields();
    }

  }

  getUserCategory = async () => {
    try {
      const header = {
        token: localStorage.getItem("authToken"),
        "Content-Type": configJSON.contentTypeApiAddDetail
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getUserCategoryDataApiCallId = requestMessage.messageId;
      this.getUserUploadedDocuments = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.userCategoryApiEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.userCategoryApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } catch (error) {
    }

  }

  async componentDidMount() {

    const token = localStorage.getItem("authToken");
    const role = localStorage.getItem("role");
    this.getBanner();
    if (token && role) {
      if (role == 'organization')
        this.setState({ redirectToDashboard: true });
      if (role == 'jury_penalist')
        this.setState({ redirectToJuryDashboard: true });
      return;
    }
    // navigate("/jury-member-dashboard")

    this.getIndustryTypes()
    this.getPrivacyAndPolicy();
    if (localStorage.getItem('zoom_id')) {
      this.enableSetScore();
    }
  }

  getAllCategories = () => {
    this.setState({ categoryLoading: true });
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAllCategoriesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getIndustryTypes = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetGetIndustryType = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getIndustryTypeApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({ "Content-Type": configJSON.contentTypeApiAddDetail })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodGetIndustryType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //all category price
  getAllCategoryPrices = (id: any) => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetAllCategoryPricesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoryPriceApiEndPoint}?token=${userToken}&number_of_categories=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrganizationList = (id: any) => {
    let catName = this.state?.allCategories.filter((e: any) => id == e.id)
    this.setState({ selectedCategoryId: id, organizationLoading: true, defaultCatName: catName[0]?.attributes?.name });
    const userToken = localStorage.getItem("authToken");
    const level = localStorage.getItem("level") ?? '2'
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetOrganizationListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.organizationListApiEndPoint}?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getApplicationForm = (id: any) => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetApplicationFormCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.applicationFormApiEndPoint}?user_category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNDAForm = () => {
    const userToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetNDAFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.NDAFormApiEndPoint}?id=${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //advertising banner

  getBanner = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetBannerFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.advertisingBanner}?name=advertising_banner`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //terms and condition

  //advertising banner

  getTermsAndConditions = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetTermsAndConditionFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.termsAndConditionsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  //privacy and policy
  getPrivacyAndPolicy = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetPrivacyAndPolicyFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.privacyAndPolicyApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };




  //Payment post api call
  performPayment(): boolean {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };


    const httpBody = {
      amount: `${this.state.totalAmount * 100}`,
      currency: "INR",
      receipt: "TEST"

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetPaymentPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // zeroAmountPayment(): boolean {
  zeroAmountPayment = () => {
    this.setState({ registrationLoading: true })
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      // token: userToken,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiZeroPaymentPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.zeroAmountPaymentApiEndPoint + `?token=${userToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeZeroAmount
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createRegistration(values: any): boolean {
    this.setState({ regiterBtnLoader: true })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const data = {
      type: "email_account",
      attributes: values
    };

    const httpBody = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetRegistrationPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.registrationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //res for payment

  resForPayment(response: any): boolean {
    const userToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id");
    const paymentId = localStorage.getItem("paymentId");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken

    };
    const httpBody = {
      response,
      payment_id: paymentId,
      id: userId
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetPaymentResPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAnotherApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  //final registration
  createFinalRegistration(): boolean {
    const userToken = localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken
    };


    let categories_ids = [...this.state.selectedCategories.map(e => e.attributes.id.toString())];

    if (this.props?.navigation?.getParam('id')) {
      categories_ids = categories_ids.filter((cat) => {
        const valid = this.state.defaultselectedCategories.find((defaultCat: any) => defaultCat.id === cat)
        return !valid
      })

    }

    const httpBody = {
      categories_ids,
      is_new: this.props?.navigation?.getParam('id') ? false : true
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetFinalRegistrationPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.finalRegistrationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //submit application form 

  submitApplicationForm(values: any): boolean {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken
    };

    const data = {
      type: "application_form",
      attributes: values
    };

    const httpBody = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetSubmitApplicationFormPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitApplicationFormEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  submitSetScore(values: any): boolean {
    const userToken = localStorage.getItem("authToken");
    const level = localStorage.getItem("level") ?? '2';
    const userId = localStorage.getItem("user_id");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken
    };

    const httpBody = {

      initiative_score: values.initiative_score,
      stakeholders_score: values.stakeholders_score,
      scalibility_score: values.scalibility_score,
      user_category_id: this.state.user_category_id,
      feedback: values.feedback

    };
    // if(!values)
    this.setScoreData({});

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetSubmitSetScorePostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postSetScoreApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //update set score

  updateSetScore(values: any): boolean {
    const userToken = localStorage.getItem("authToken");
    const level = localStorage.getItem("level") ?? '2';
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken
    };

    const httpBody = {

      initiative_score: values.initiative_score,
      stakeholders_score: values.stakeholders_score,
      scalibility_score: values.scalibility_score,
      user_category_id: this.state.user_category_id,
      feedback: values.feedback

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetSubmitSetScorePostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateSetScoreApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  //submit NDA form
  submitNDAForm(): boolean {

    if (this.state.acceptTermsOfNDAForm == false) {
      WarningToast("Warning", 'Please accept the Terms and Conditions.');
      return false;
    }

    const userToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      "token": userToken
    };

    const httpBody = {
      id: userId
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetSubmitNDAFormCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.NDAFormAcceptApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    //
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodPatchType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSupportingDoc = (id: any) => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetSupportingDocsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDocumentsEndPoints}/${id}/documents`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // onselect category
  onSelectCategory = (category: any, select: boolean) => {

    let qty = 0

    if (select && this.state.selectedCategories.length == this.state.max_number_of_categories && !this.state.is_special) {

      Modal.warning({
        title: 'Alert',
        content: `You can select maximum ${this.state.max_number_of_categories} categories.`,
      });

      return;
    }
    if (select) {
      qty = this.state.selectedCategories.length + 1;
      this.setState({
        selectedCategories: [...this.state.selectedCategories, category],
        makePaymentButton: false,
        promoCodeApplied: false,
        numberOfSelectedCategories: this.state.selectedCategories.length + 1
      }, () => {
        if (this.state.selectedCategories.length != 0) {
          this.getSelectedCategoriesPrice(qty)
        }
        if (this.state.selectedCategories.length == 0) {
          this.setState({
            subTotalAmount: 0,
            gstAmount: 0,
            totalAmount: 0,
            applyPromocodeStatusMsg: ''
          }, () => {
          });
        }

      });
      // this.setState({promoCode:'',applyPromocodeStatusMsg:''})


    } else {
      qty = this.state.selectedCategories.length - 1
      this.setState({
        selectedCategories: [...this.state.selectedCategories.filter(e => e != category)],
        numberOfSelectedCategories: this.state.selectedCategories.length - 1,
      }, () => {
        if (this.state.selectedCategories.length != 0) {
          this.getSelectedCategoriesPrice(qty)
        }
        if (this.state.selectedCategories.length - this.state?.selectCat == 0) {
          this.setState({
            subTotalAmount: 0,
            gstAmount: 0,
            totalAmount: 0,
            applyPromocodeStatusMsg: '',
            promoCode: ''

          });
        }
      });
      // this.setState({promoCode:'',applyPromocodeStatusMsg:''})
    }

  }

  // get categories price 
  getSelectedCategoriesPrice = (qty: number) => {
    qty = qty - this.state?.selectCat;
    const userToken = localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };
    const httpBody = {
      qty: qty,
      discount_code: this.state.promoCode,
    };

    let reqPayload = `${configJSON.selectedCategoryApiEndPoint}/get_price?token=${userToken}&number_of_categories=${qty}`;

    if (this.state.promoCode.length != 0) {
      reqPayload = `${configJSON.selectedCategoryApiEndPoint}/get_price?token=${userToken}&number_of_categories=${qty}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSelectedCategoriesPriceCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      reqPayload
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.categoryPriceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTotalPrice = () => {
    let totalPrice = 0;
    if (this.props?.navigation?.getParam('id')) {
      for (let i = 0; i < this.state.selectedCategories.length; i++) {
        if (this.state.defaultselectedCategories.some((e: any) => e.id == this.state.selectedCategories[i].attributes.id)) {
          continue;
        }
        totalPrice += parseFloat(this.state.selectedCategories[i].attributes.price) ?? 10
      }
    } else {
      for (let i = 0; i < this.state.selectedCategories.length; i++) {
        totalPrice += parseFloat(this.state.selectedCategories[i].attributes.price) ?? 10
      }
    }
    if (!(this.state.selectedCategories.length == 0)) {
      if (typeof this.state.discountAmount == "string" && this.state.discountAmount.includes("%")) {
        totalPrice -= totalPrice * (parseFloat(this.state.discountAmount) / 100)
      }
      else {
        totalPrice = totalPrice - this.state.discountAmount
      }
    }
    return totalPrice;
  }

  // gst number
  gstNumber = (e: any) => {
    this.setState({ gstNumber: e.target.value })

    if (e.target.value == '') {
      this.setState({ gstNumberErrorMsg: '', makePaymentButton: false })
    } else {

      const userToken = localStorage.getItem("authToken");
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail,
        token: userToken,
      };


      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiGstNumberPostCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.gstNumberApiEndPoint + `?gst_in=${e.target.value}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGstNumber
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }





  closeNDAForm = () => {
    this.setState({ showNDAForm: false });
  }

  closeSuccessfullySubmit = () => {
    this.setState({ successfullySubmitModal: false })
  }
  onMakePayment = () => {

    if (this.state.selectedCategories.length > this.state.max_number_of_categories && !this.state.is_special) {
      Modal.warning({
        title: 'Alert',
        content: `You can select maximum ${this.state.max_number_of_categories} categories.`,
      });

      return;
    }

    if (this.state.selectedCategories.length < 1) {
      Modal.warning({
        title: 'Alert',
        content: 'Please select at-least 1 category.',
      });

      return;
    }


    if (!this.state.acceptTerms) {
      Modal.warning({
        title: 'Warning',
        content:
          'Please accept the Terms and Conditions.',
      });
      return;
    }

    if (this.state.promoCodeDiscount == "100%") {
      this.createFinalRegistration()
      this.setState({ registrationLoading: true });
    } else {
      this.setState({ registrationLoading: true });
      this.displayRazorpay();
    }

    setTimeout(() => {
      this.setState({ registrationLoading: false });
    }, 12000);


  }

  handleChange = (value: String) => {
    this.setState({ industry_type: value as string });
  }
  handleUserRoleChange = (value: String) => {
    this.setState({ user_role: value as string });
  }



  //registration
  onFinish = (values: any) => {
    values.industry_type = this.state.industry_type;
    this.createRegistration(values);

  };

  //post zoom 
  onFinishForZoom = (values: any) => {
    this.createZoomMeeting2(values);
  };

  //forget password 
  onFinishForgetPassword = (values: any) => {
    values.user_role = this.state.user_role;

    this.changePassword(values);


  };


  // submit application form 

  onSubmitApplication = (values: any) => {
    this.submitApplicationForm(values);

  };

  handleSubmit = (e: React.ChangeEvent<{ value: unknown }>) => {
    e.preventDefault();
  };

  loadScript(src: any) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  async displayRazorpay() {
    const res = this.loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }
    this.performPayment();



  }
  //login
  login(values: any): boolean {
    this.setState({ loadingLogin: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    this.username = values.user_name

    const data = {
      type: "email_account",
      attributes: values
    };

    const httpBody = {
      data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetLoginPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //login
  onLogin = (values: any) => {
    this.login(values);

  };

  // promo code 
  applyPromocode() {


    if (this.state.promoCode.length > 2) {
      const authToken = localStorage.getItem('authToken');
      const org_id = localStorage.getItem("user_id");
      const amount = this.getTotalPrice();

      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };
      const data = {
        // code: procode,
        amount: this.getTotalPrice(),
        // token: authToken
      };

      const httpBody = {
        data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiApplyPromocodeId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        // configJSON.applyPromocodeApiEndpoint+`?code=${procode}&token=${authToken}&amount=${amount}`
        configJSON.applyPromocodeApiEndpoint + `apply_promo_code?token=${authToken}&amount=${this.state.subTotalAmount}&discount_code=${this.state.promoCode}`

      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeApplyPromocode
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }

  }

  // calculateDiscountValue 

  getDiscountValue = (e: any) => {
    this.setState({ promoCode: e.target.value.trim() }, () => {
      if (this.state?.promoCode.length == 0) {
        this.setState({
          applyPromocodeStatusMsg: "Enter Promo Code",
          makePaymentButton: false,
          promoCodeApplied: false,
          discountAmount: 0,
          promoCodeDiscount: ''
        })
      }
      this.getSelectedCategoriesPrice(this.state.numberOfSelectedCategories)
    })
    // }


  }

  createZoomMeeting2(values: any) {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const httpBody = {
      "store_value": {
        note: values.note,
        topic: values.topic,
        panel: localStorage.getItem("jury_panel"),
        start_time: values.start_date,
        start_date: values.start_date,
        zoom_id: values.zoom_id
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetZoomPostCallId2 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.zoomPostApiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  //forget password

  changePassword(values: any): boolean {

    // this.setState({registeredEmail: values.email})

    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const httpBody = {
      email: values.email,
      user_role: values.user_role

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetForgetPasswordPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.forgetPasswordApiEndPoint}?email=${values.email}&user_role=${values.user_role}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  //enable set score

  enableSetScore(): boolean {

    const userToken = localStorage.getItem("authToken");
    const zoom_id = localStorage.getItem("zoom_id");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetSetScoreEnableApiPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.setScoreEnableApiEndPoint}/time_check?token=${userToken}&zoom_id=${zoom_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //jury

  showDocumentModal = (id: any) => {
    this.getSupportingDoc(id);
  }
  showSetScoreModal = (e: any, updating: boolean) => {
    this.setState({
      visibleThree: true,
      organization_id: e.id,
      scoreData: e.attributes,
      user_category_id: e.attributes.user_category.data.id,
    });
    if (!updating) {
      this.setState({ scoreData: {} });
    }
  }
  showZoomFormModal = () => {
    const userToken = localStorage.getItem("authToken");
    const userId = localStorage.getItem("user_id")

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetZoomGetCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.zoomGetApiEndPoint}${userId ? '?account_id=' + userId : ''}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  showTnCModal = () => {
    this.setState({
      visibleForTnC: true,
    });
  }
  handleOkDocument = (event: any) => {
    this.setState({
      visibleTwo: false,
    });
  }
  handleCancelDocument = (event: any) => {
    this.setState({
      visibleTwo: false,
    });
  }
  handleOk = (event: any) => {
    this.setState({
      visible: false,
    });
  }

  feedBackForm = (values: any) => {
    this.setState({
      visible: false,
    });
    this.handleSendFeedback()
  };
  forgetPasswordModal = () => {
    this.setState({
      visibleForForgetPassword: true,
    });
  }

  // Leaderboard
  handelLeaderboard = async (e: any) => {
    let categories_assigned = localStorage.getItem('categories_assigned')
    if (categories_assigned == '[]') {
      FailedToast("Error", `Panelist ${localStorage.getItem('display_name')} is not associated with any Category. Please contact SHRM admin for further assistance.`);
      return;
    }
    const id = await localStorage.getItem('user_id');

    this.setState({ goToConsolidated: e });

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gotoConsolidateLeaderboardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getLeaderboardApiEndPoint}?account_id=${id}`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  changeFeedback(event: any, name: string) {
    let _data = event.target.value;
    this.setState({ applicationFormFeedBack: _data })
  }

  handleSendFeedback = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };
    const httpBody = {
      user_category_id: this.state.user_category_id,
      feedback: this.state.applicationFormFeedBack
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiApplicationFormFeedback = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.applicationFormFeedbackApiEndPoint}?form_feedback`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeFeedback
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSendEmail = (e: any, id: any) => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiTriggerSendEmail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.triggerEmailEndPoint}?category_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleOkThree = (event: any) => {
    this.setState({
      visibleThree: false,
    });
  }
  handleCancel = (event: any) => {
    this.setState({
      visible: false,
    });
  }
  handleCancelThree = (event: any) => {
    this.setState({
      visibleThree: false,
      juryDocNotUploaded: false,
    });
  }
  zoomMeetingHandleCancel = (event: any) => {
    this.setState({
      visibleForZoomMeeting: false,
    });
  }
  tncHandleCancel = (event: any) => {
    this.setState({
      visibleForTnC: false,
    });
  }
  zoomMeetingFormHandleCancel = (event: any) => {
    this.setState({
      visibleForZoomForm: false,
    });
  }
  forgetPasswordFormHandleCancel = (event: any) => {
    this.setState({
      visibleForForgetPassword: false,
    });
  }
  showModal = (id: any) => {
    this.setState({ media: null, user_category_id: id });
    this.getApplicationForm(id);

  }

  handleViewDocument = (viewFile: any) => {
    this.setState({
      viewDocumentUrl: viewFile.file_url,
      viewDocumentType: viewFile.file_type,
      viewDocumentName: viewFile.filename,
      viewDocument: true
    })
  }
  handleCloseViewDocument = () => {
    this.setState({
      viewDocument: false
    })
  }

  onSubmitSetScore = (e: any) => {
    if (this.state.scoreUpdating) {

      this.updateSetScore(this.state.scoreData);
      this.setScoreUpdating(false);
      return;
    }
    this.submitSetScore(this.state.scoreData);
  };

  onUpdateSetScore = (values: any) => {
    this.updateSetScore(values);

  };

  acceptTermsOfNDAForm = () => {
    if (!this.state.acceptTermsOfNDAForm) {
      Modal.warning({
        title: 'Warning',
        content:
          'Please accept the Terms and Conditions.',
      });
      return;
    }

  }

}

// Customizable Area End

