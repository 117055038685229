 // Customizable Area Start
 import React from 'react';
 import { makeStyles } from '@material-ui/core/styles';
 import AppBar from '@material-ui/core/AppBar';
 import { Divider } from '@material-ui/core';
 import Toolbar from '@material-ui/core/Toolbar';
 import Typography from '@material-ui/core/Typography';
 import Button from '@material-ui/core/Button';
 import IconButton from '@material-ui/core/IconButton';
 import MenuIcon from '@material-ui/icons/Menu';
 // import logo from "./assets/logo.png";
 import logo from "./assets/image_logo@3x.png";
 import EmailAccountRegistrationController, { Props, configJSON } from "../../blocks/email-account-registration/src/EmailAccountRegistrationController.web";
import AppHeaderWeb from './AppHeaderWeb.web';
import CustomFooter from './Footer.web';
 
 const useStyles = makeStyles((theme) => ({
   root: {
     flexGrow: 1,
   },
   menuButton: {
     marginRight: theme.spacing(2),
   },
   title: {
     flexGrow: 1,
   },
 }));
 
 class TermsAndConditionScreen extends EmailAccountRegistrationController {
   constructor(props: any) {
       super(props);
   }
   async componentDidMount(){
    this.getTermsAndConditions(); 
  }
   render(): React.ReactNode {
     return (
       <>
        <AppHeaderWeb navigation id = ''  match = ''/>
         <div style={{width: "100%", padding: '40px'}}>
            <h1 style={{textAlign: "center"}}>Terms and conditions</h1>
            <div  dangerouslySetInnerHTML={{__html: this.state.terms_and_conditions}}></div>
         </div>
  
         <CustomFooter/>
 
       </>
     );
   }
 
 }
 
 
 export default TermsAndConditionScreen;
  // Customizable Area Start