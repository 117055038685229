// Customizable Area Start
import { Modal, notification } from "antd";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface Category{
    attributes: {
        created_at: string,
        id: string,
        name: string,
        price: any,
        updated_at: string,
    }
}

interface S {
   
    redirect: boolean,
    onRegistrationPage: boolean,
    user_name: string,
    password: string,
    visible: boolean,
    visibleTwo: boolean
    name: string,
    media: any,
    evaluation_pdf: any
}

interface SS {
  id: any;
}

export default class UserController extends BlockComponent<Props, S, SS> {

    apiResetLoginPostCallId: string = "";
    apiResetGetEvaluationFormCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
        redirect: false,
        onRegistrationPage: true,
        user_name: '',
        password: '',
        visible: false,
        visibleTwo: false,
        name: '',
        media: '',
        evaluation_pdf: ''

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async receive(from: String, message: Message) {
      // for login 

      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.apiResetLoginPostCallId &&
        this.apiResetLoginPostCallId ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      }
         //evaluation parameters
         if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.apiResetGetEvaluationFormCallId &&
          this.apiResetGetEvaluationFormCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson === undefined) {
            return;
          } else if (
            
                  responseJson &&
            responseJson.errors &&
            responseJson.errors[0].token
          ) {
            
            return;
          }
          
          this.setState({media: responseJson.data.attributes.media});
        
        }

  }


  login(values: any): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const data = {
      type: "email_account",
      attributes: values
    };

    const httpBody = {
        data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiResetLoginPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //login
  onFinish = (values: any) => {
    this.login(values);

  };
  //get evaluation form 
  getEvaluationForm = () => {
    const userToken = localStorage.getItem("authToken");
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: userToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResetGetEvaluationFormCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EvaluationFormApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  async componentDidMount(){
    this.getEvaluationForm()
  }


  showDocumentModal = () => {
    this.setState({
      visibleTwo: true,
    });
  }
  handleOkDocument = (event: any) => {
    this.setState({
      visibleTwo: false,
    });
  }
  handleCancelDocument = (event:any) => {
    this.setState({
      visibleTwo: false,
    });
  }
  handleOk = (event: any) => {
    this.setState({
      visible: false,
    });
  }
  handleCancel = (event:any) => {
    this.setState({
      visible: false,
    });
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  }

    handleChange = (value: String) => {
      this.setState({name: value as string});
  }


  

}
// Customizable Area End

