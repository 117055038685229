//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {  Card, Box, TextField, Button, CardContent, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyleSheet} from 'react-native';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from '../CustomformController';
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import Grid from '@material-ui/core/Grid';
import OrgHeaderWeb from '../OrgHeader.web';
import CustomFooter from '../../../../components/src/Footer.web';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    box: {
        width: '100%',
    },
}); 
    // Customizable Area End
 export default class LeaveragingHRTechnologySectionB extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
      render(){ 
        //Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0]
        return (
					<>
						<OrgHeaderWeb/>
						<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
                                    <Box>
								<h1 style={{ textAlign: 'center' }}>
									<u>Section B-Quantitative Measurement</u>
								</h1>
								<p>
									<b>This section covers the quantitative aspects of the
									initiative under the concerned category. Providing all the
									information under this section will enhance your nomination
									and aid the Jury and accurate evaluation of application.
									</b>
								</p>
							</Box>
							<Box>
							<Paper style={{overflowX: "auto"}}>
								<Table className={styles.table} aria-label='simple table'>
									<TableHead className={styles.tableHead}>
										<TableRow className={styles.tableRow}>
										<TableCell style={{width: '80px'}}><b> Sr. No.</b></TableCell>
											<TableCell> <b>Metrics</b></TableCell>
											<TableCell><b> Unit</b></TableCell>
											<TableCell> <b>FY 2018-19</b></TableCell>
											<TableCell><b> FY 2019-20</b></TableCell>
											<TableCell><b> FY 2020-21</b></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow className={styles.tableRow}>
										<TableCell><b>1</b> </TableCell>
											<TableCell>
												<p><b>Scale of implementation</b></p>
											</TableCell>
											<TableCell>  </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.implementationScale["19"]}
													onChange={(e) =>
														this.setState({
															implementationScale: {
																...this.state.implementationScale,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.implementationScale["20"]}
													onChange={(e) =>
														this.setState({
															implementationScale: {
																...this.state.implementationScale,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.implementationScale["21"]}
													onChange={(e) =>
														this.setState({
															implementationScale: {
																...this.state.implementationScale,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p> 1a. Number of offices covered</p>
											</TableCell>
											<TableCell>No. of facilities covered & percentage</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfofficesCovered["19"]}
													onChange={(e) =>
														this.setState({
															noOfofficesCovered: {
																...this.state.noOfofficesCovered,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfofficesCovered["20"]}
													onChange={(e) =>
														this.setState({
															noOfofficesCovered: {
																...this.state.noOfofficesCovered,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfofficesCovered["21"]}
													onChange={(e) =>
														this.setState({
															noOfofficesCovered: {
																...this.state.noOfofficesCovered,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>1b. Number of HR processes fully digitized</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.digitizedHRprocess["19"]}
													onChange={(e) =>
														this.setState({
															digitizedHRprocess: {
																...this.state.digitizedHRprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.digitizedHRprocess["20"]}
													onChange={(e) =>
														this.setState({
															digitizedHRprocess: {
																...this.state.digitizedHRprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.digitizedHRprocess["21"]}
													onChange={(e) =>
														this.setState({
															digitizedHRprocess: {
																...this.state.digitizedHRprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>1c. List/ name the HR processes fully digitized (as on date)</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state?.fullyDigitizedHRprocess["19"]}
													onChange={(e) =>
														this.setState({
															fullyDigitizedHRprocess: {
																...this.state.fullyDigitizedHRprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state?.fullyDigitizedHRprocess["20"]}
													onChange={(e) =>
														this.setState({
															fullyDigitizedHRprocess: {
																...this.state.fullyDigitizedHRprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state?.fullyDigitizedHRprocess["21"]}
													onChange={(e) =>
														this.setState({
															fullyDigitizedHRprocess: {
																...this.state.fullyDigitizedHRprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p> 1d. Number of HR processes partially digitized</p>
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell> 
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRprocess["19"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRprocess: {
																...this.state.partialDigitizedHRprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRprocess["20"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRprocess: {
																...this.state.partialDigitizedHRprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRprocess["21"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRprocess: {
																...this.state.partialDigitizedHRprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>1e. List/ name the HR processes partially digitized</p>
											</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRNameprocess["19"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRNameprocess: {
																...this.state.partialDigitizedHRNameprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRNameprocess["20"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRNameprocess: {
																...this.state.partialDigitizedHRNameprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.partialDigitizedHRNameprocess["21"]}
													onChange={(e) =>
														this.setState({
															partialDigitizedHRNameprocess: {
																...this.state.partialDigitizedHRNameprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>1f. Number of HR processes still manual</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRprocess["19"]}
													onChange={(e) =>
														this.setState({
															manualHRprocess: {
																...this.state.manualHRprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRprocess["20"]}
													onChange={(e) =>
														this.setState({
															manualHRprocess: {
																...this.state.manualHRprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRprocess["21"]}
													onChange={(e) =>
														this.setState({
															manualHRprocess: {
																...this.state.manualHRprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell> 1g. List/ name the HR processes still manual</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRNameprocess["19"]}
													onChange={(e) =>
														this.setState({
															manualHRNameprocess: {
																...this.state.manualHRNameprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRNameprocess["20"]}
													onChange={(e) =>
														this.setState({
															manualHRNameprocess: {
																...this.state.manualHRNameprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.manualHRNameprocess["21"]}
													onChange={(e) =>
														this.setState({
															manualHRNameprocess: {
																...this.state.manualHRNameprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell><b>2</b> </TableCell>
											<TableCell>
												<p> <b>Budget Allocation for HR Digitization</b></p>
											</TableCell>
											<TableCell>in INR</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetAllocation["19"]}
													onChange={(e) =>
														this.setState({
															budgetAllocation: {
																...this.state.budgetAllocation,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetAllocation["20"]}
													onChange={(e) =>
														this.setState({
															budgetAllocation: {
																...this.state.budgetAllocation,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetAllocation["21"]}
													onChange={(e) =>
														this.setState({
															budgetAllocation: {
																...this.state.budgetAllocation,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell><b>3</b> </TableCell>
											<TableCell>
												<p><b>Budget Utilization</b></p>
											</TableCell>
											<TableCell> % </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["19"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["20"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["21"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> <b>4</b></TableCell>
											<TableCell><p>
												<b>Number of people impacted through these initiatives (employees using digitized formats)
												</b></p></TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfPeopleImpacted["19"]}
													onChange={(e) =>
														this.setState({
															noOfPeopleImpacted: {
																...this.state.noOfPeopleImpacted,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfPeopleImpacted["20"]}
													onChange={(e) =>
														this.setState({
															noOfPeopleImpacted: {
																...this.state.noOfPeopleImpacted,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfPeopleImpacted["21"]}
													onChange={(e) =>
														this.setState({
															noOfPeopleImpacted: {
																...this.state.noOfPeopleImpacted,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>
													4a. Total number of employees impacted
												</p>
											</TableCell>
											<TableCell> Nos. </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["19"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["20"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["21"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>4b. % of total headcount impacted</p>
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.totalHeadCountImpacted["19"]}
													onChange={(e) =>
														this.setState({
															totalHeadCountImpacted: {
																...this.state.totalHeadCountImpacted,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.totalHeadCountImpacted["20"]}
													onChange={(e) =>
														this.setState({
															totalHeadCountImpacted: {
																...this.state.totalHeadCountImpacted,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.totalHeadCountImpacted["21"]}
													onChange={(e) =>
														this.setState({
															totalHeadCountImpacted: {
																...this.state.totalHeadCountImpacted,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell><b>5</b> </TableCell>
											<TableCell>
												<p><b>Number of man-days saved in each of the HR processes</b></p>
											</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfmanSavedHRprocess["19"]}
													onChange={(e) =>
														this.setState({
															noOfmanSavedHRprocess: {
																...this.state.noOfmanSavedHRprocess,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfmanSavedHRprocess["20"]}
													onChange={(e) =>
														this.setState({
															noOfmanSavedHRprocess: {
																...this.state.noOfmanSavedHRprocess,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfmanSavedHRprocess["21"]}
													onChange={(e) =>
														this.setState({
															noOfmanSavedHRprocess: {
																...this.state.noOfmanSavedHRprocess,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>5a. Recruitment Techniques</TableCell>
											<TableCell>No. of Man days</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.recruitmentTechniques["19"]}
													onChange={(e) =>
														this.setState({
															recruitmentTechniques: {
																...this.state.recruitmentTechniques,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.recruitmentTechniques["20"]}
													onChange={(e) =>
														this.setState({
															recruitmentTechniques: {
																...this.state.recruitmentTechniques,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.recruitmentTechniques["21"]}
													onChange={(e) =>
														this.setState({
															recruitmentTechniques: {
																...this.state.recruitmentTechniques,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>5b. Employee induction and orientation</p>
											</TableCell>
											<TableCell> No. of Man days </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.empInduction_orieo["19"]}
													onChange={(e) =>
														this.setState({
															empInduction_orieo: {
																...this.state.empInduction_orieo,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.empInduction_orieo["20"]}
													onChange={(e) =>
														this.setState({
															empInduction_orieo: {
																...this.state.empInduction_orieo,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.empInduction_orieo["21"]}
													onChange={(e) =>
														this.setState({
															empInduction_orieo: {
																...this.state.empInduction_orieo,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>5c. Compensation and Benefits</p>
											</TableCell>
											<TableCell>No. of Man days</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.compensation_benifits["19"]}
													onChange={(e) =>
														this.setState({
															compensation_benifits: {
																...this.state.compensation_benifits,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.compensation_benifits["20"]}
													onChange={(e) =>
														this.setState({
															compensation_benifits: {
																...this.state.compensation_benifits,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.compensation_benifits["21"]}
													onChange={(e) =>
														this.setState({
															compensation_benifits: {
																...this.state.compensation_benifits,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>5d. Performance Management systems</TableCell>
											<TableCell>No. of Man days</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.performMgmtSystm["19"]}
													onChange={(e) =>
														this.setState({
															performMgmtSystm: {
																...this.state.performMgmtSystm,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.performMgmtSystm["20"]}
													onChange={(e) =>
														this.setState({
															performMgmtSystm: {
																...this.state.performMgmtSystm,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.performMgmtSystm["21"]}
													onChange={(e) =>
														this.setState({
															performMgmtSystm: {
																...this.state.performMgmtSystm,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>5e. Administrative systems (Attendance, Leave, Travel, etc.)</TableCell>
											<TableCell> No. of Man days </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.administrativeSystems["19"]}
													onChange={(e) =>
														this.setState({
															administrativeSystems: {
																...this.state.administrativeSystems,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.administrativeSystems["20"]}
													onChange={(e) =>
														this.setState({
															administrativeSystems: {
																...this.state.administrativeSystems,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.administrativeSystems["21"]}
													onChange={(e) =>
														this.setState({
															administrativeSystems: {
																...this.state.administrativeSystems,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>5f. Learning and Development</p>
											</TableCell>
											<TableCell>No. of Man days</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.learn_develop["19"]}
													onChange={(e) =>
														this.setState({
															learn_develop: {
																...this.state.learn_develop,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.learn_develop["20"]}
													onChange={(e) =>
														this.setState({
															learn_develop: {
																...this.state.learn_develop,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.learn_develop["21"]}
													onChange={(e) =>
														this.setState({
															learn_develop: {
																...this.state.learn_develop,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>5g. Communication</p>
											</TableCell>
											<TableCell> No. of Man days </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.communication["19"]}
													onChange={(e) =>
														this.setState({
															communication: {
																...this.state.communication,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.communication["20"]}
													onChange={(e) =>
														this.setState({
															communication: {
																...this.state.communication,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.communication["21"]}
													onChange={(e) =>
														this.setState({
															communication: {
																...this.state.communication,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>5h.  Satisfaction surveys, Exit interviews, etc. </p>
											</TableCell>
											<TableCell> No. of Man days </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satidifactionSurveys["19"]}
													onChange={(e) =>
														this.setState({
															satidifactionSurveys: {
																...this.state.satidifactionSurveys,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satidifactionSurveys["20"]}
													onChange={(e) =>
														this.setState({
															satidifactionSurveys: {
																...this.state.satidifactionSurveys,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satidifactionSurveys["21"]}
													onChange={(e) =>
														this.setState({
															satidifactionSurveys: {
																...this.state.satidifactionSurveys,
																21: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>
												5i. Other HR System (Please specify)
												</p>
											</TableCell>
											<TableCell>  Specify measure being used</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.otherHr["18"]}
													onChange={(e) =>
														this.setState({
															otherHr: {
																...this.state.otherHr,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.otherHr["19"]}
													onChange={(e) =>
														this.setState({
															otherHr: {
																...this.state.otherHr,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.otherHr["20"]}
													onChange={(e) =>
														this.setState({
															otherHr: {
																...this.state.otherHr,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell><b>6</b> </TableCell>
											<TableCell>
												<p> <b>Measure of success of the initiatives</b></p>
											</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.successMeasureInitiatives["18"]}
													onChange={(e) =>
														this.setState({
															successMeasureInitiatives: {
																...this.state.successMeasureInitiatives,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.successMeasureInitiatives["19"]}
													onChange={(e) =>
														this.setState({
															successMeasureInitiatives: {
																...this.state.successMeasureInitiatives,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.successMeasureInitiatives["20"]}
													onChange={(e) =>
														this.setState({
															successMeasureInitiatives: {
																...this.state.successMeasureInitiatives,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>6a. Satisfaction Score with the initiatives</p>
											</TableCell>
											<TableCell> On a scale of 1 to 10 </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satisfactionScore["18"]}
													onChange={(e) =>
														this.setState({
															satisfactionScore: {
																...this.state.satisfactionScore,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satisfactionScore["19"]}
													onChange={(e) =>
														this.setState({
															satisfactionScore: {
																...this.state.satisfactionScore,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.satisfactionScore["20"]}
													onChange={(e) =>
														this.setState({
															satisfactionScore: {
																...this.state.satisfactionScore,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>6b. Increases in productivity due to initiatives</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.productiveIncrease["18"]}
													onChange={(e) =>
														this.setState({
															productiveIncrease: {
																...this.state.productiveIncrease,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.productiveIncrease["19"]}
													onChange={(e) =>
														this.setState({
															productiveIncrease: {
																...this.state.productiveIncrease,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.productiveIncrease["20"]}
													onChange={(e) =>
														this.setState({
															productiveIncrease: {
																...this.state.productiveIncrease,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												<p>6c. Others</p>
											</TableCell>
											<TableCell> Specify measure being used </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.others["18"]}
													onChange={(e) =>
														this.setState({
															others: {
																...this.state.others,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.others["19"]}
													onChange={(e) =>
														this.setState({
															others: {
																...this.state.others,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.others["20"]}
													onChange={(e) =>
														this.setState({
															others: {
																...this.state.others,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell><b>7</b></TableCell>
											<TableCell>
												<p> <b>Reduction in Response time of inquiries to HR</b></p>
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.inquiresToHR["18"]}
													onChange={(e) =>
														this.setState({
															inquiresToHR: {
																...this.state.inquiresToHR,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.inquiresToHR["19"]}
													onChange={(e) =>
														this.setState({
															inquiresToHR: {
																...this.state.inquiresToHR,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.inquiresToHR["20"]}
													onChange={(e) =>
														this.setState({
															inquiresToHR: {
																...this.state.inquiresToHR,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								</Paper>
							</Box>
							<Box>
								
								<p><b>
								Please share any other HR Technology metrics/ measurements that you track and feel relevant to support your application. 
								</b>
								</p>
								
							</Box>
										
							<Grid container spacing={3}>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
								<Button size='large' variant='contained' id='print' onClick={() => { window.history.back() }} style={{
									backgroundColor: 'white',
									color: '#fff',
									marginLeft: '10px',
									marginTop: '15px',
									cursor: 'pointer'
								}}> <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "center" }}>
							<Button
							onClick={this.handleSaveDraftSecB}
								size='large'
								variant='contained'
								size='large' variant='contained' id='print' style={{
									backgroundColor: '#e6257e',
									color: '#fff',
									marginRight: '10px',
									marginTop: '15px',
									cursor: 'pointer',
								}}
								
								>SAVE AS DRAFT
								</Button>
								</Grid>
								<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
									<Link to='/hr_technology_c'>
										<Button
											onClick={this.handleHRTechnologySecB}
											size='large'
											variant='contained'
											style={{
												backgroundColor: '#e6257e',
												color: '#fff',
												marginLeft: '10px',
												marginTop: '15px',
											}}
										>
											{' '}
										NEXT
								        </Button>
									</Link>
									</Grid>
									</Grid>
									</Box>
								</CardContent>
							</Card>
						</div>
						<CustomFooter/>
					</>
				);
        //Customizable Area End
      }
}
