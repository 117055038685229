//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react';
// Customizable Area Start
import { Card, Box, TextField, Button, CardContent } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'
import CustomformController , {Props} from './CustomformController';
import {StyleSheet} from 'react-native';
import {srmLogo} from './assets';
import {facebookIcon} from './assets';
import {twitterIcon} from './assets';
import {linkdlnIcon} from './assets';
import {youtubeIcon} from './assets';
import CustomFooter from '../../../components/src/Footer.web';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
    },
    container: {
        // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
        width:'50%'
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
});
// Customizable Area End
export default class CustomForm extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    render(){
        // Customizable Area Start
        const rows = [
            1, 159, 6.0, 24, 4.0
        ]
        return (
            <>
			<div>
                 <div className="makeStyles-root-1">
                    <header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{background:'black', height:'200px', justifyContent: 'center'}}>
                        <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
                            <h6 className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
                                <img src={srmLogo} alt="companyName"  style={{ height:"50px", margin:"15px"}}/>
                            </h6>
                        
                    <h5>
                    <hr className="MuiDivider-root MuiDivider-flexItem MuiDivider-vertical" style={{color:"black", marginRight:"10px", marginLeft:"10px"}}/>
                    </h5>
                    
                            <h2 style={{textAlign:"center", marginTop:"10px", color:"white"}}>Application Form</h2>
                            <h1 style={{textAlign:"center",color:"white", fontWeight:"bolder", marginBottom:"15px", marginTop:"5px"}}>{this.state.customFormHeading}</h1>
                            </div>
                    </header>      
                </div>
                <div   style={{display:"flex",justifyContent: 'center', alignItems: "center", margin: '50px auto', borderRadius:"20px", width:"50%"}}>
                    <Card className={styles.container} style={{boxShadow: "none"}} elevation={0}>
                        <CardContent className={styles.cardContent}>
                            <Box>
                                <Box>
                                    <p>Definition: To recognize organisations that have effectively used HR analytics, HR metrics, analytical/technology tools etc. to measure and improve the overall performance of the organization.</p>
                                </Box>
                                <Box>
                                    <h1 style={{ textAlign: 'center' }}><u>Section A-Participant Information</u></h1>
                                    <p> All questions are mandatory, unless stated otherwise; your questionnaire may not be considered complete if these questions are left blank.</p>
                                </Box>
                                <Box>
                                    <Box>
                                        <h4 >Company Name and Date of Incorporation</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Enter Company Name and Date of Incorporation"
                                            className={styles.textField}
                                            placeholder="Placeholder"
                                            // helperText="Full width!"
                                            fullWidth
                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            variant="outlined"
                                            onChange={(e)=>this.setState({company:e.target.value})}
                                        />
                                    </Box>
                                    <Box>
                                        Details of SPOC (Single Point of Contact): This could be the CHRO or relevant Functionoal Head.
                                    </Box>
                                    
                                        <Box  >
                                            <h4>Name</h4>
                                            <TextField
                                                id="outlined-full-width"
                                                label="Enter Name"
                                                className={styles.textField}
                                                placeholder="Placeholder"
                                                // helperText="Full width!"
                                                fullWidth

                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                variant="outlined"
                                                onChange={(e)=>this.setState({name:e.target.value})}
                                            />
                                        </Box>
                                        <Box  >
                                            <h4>Designation</h4>
                                            <TextField
                                                id="outlined-full-width"
                                                label="Enter Designation"
                                                className={styles.textField}
                                                placeholder="Enter Designation"
                                                // helperText="Full width!"
                                                fullWidth

                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                variant="outlined"
																								onChange={(e) =>
																									this.setState({ designation: e.target.value })
																								}
                                            />
                                        </Box>
                                        <Box  >
                                            <h4>Contact Number</h4>
                                            <TextField
                                                id="outlined-full-width"
                                                label="Enter Contact Number"
                                                className={styles.textField}
                                                placeholder="Enter Contact Number"
                                                // helperText="Full width!"
                                                fullWidth

                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                variant="outlined"
                                                onChange={(e)=>this.setState({contact:e.target.value})}
                                            />
                                        </Box>
                                        <Box  >
                                            <h4>Email Address</h4>
                                            <TextField
                                                id="outlined-full-width"
                                                label="Enter Email Address"
                                                className={styles.textField}
                                                placeholder="Enter Email Address"
                                                // helperText="Full width!"
                                                fullWidth

                                                // InputLabelProps={{
                                                //     shrink: true,
                                                // }}
                                                variant="outlined"
                                                onChange={(e)=>this.setState({mail:e.target.value})}
                                            />
                                        </Box>
                                    <Box>
                                        <h4>Nature of Business</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Enter Nature of Business"
                                            className={styles.innerTextField}
                                            placeholder="Enter Nature of Business"
                                            // helperText="Full width!"
                                            fullWidth
                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            variant="outlined"
                                            onChange={(e)=>this.setState({bussiness:e.target.value})}
                                        />
                                    </Box>
                                    <Box>
                                        <h4>Type of Entity</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Enter Type of Entity"
                                            className={styles.innerTextField}
                                            placeholder="Placeholder"
                                            // helperText="Full width!"
                                            fullWidth

                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            variant="outlined"
                                            onChange={(e)=>this.setState({entity:e.target.value})}
                                        />
                                    </Box>
                                    <Box  >
                                        <h4>Nature of Ownership in India</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Enter Nature of Ownership in India"
                                            className={styles.innerTextField}
                                            placeholder="Nature of Ownership in India"
                                            // helperText="Full width!"
                                            fullWidth

                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            variant="outlined"
                                            onChange={(e)=>this.setState({ownership:e.target.value})}
                                        />
                                    </Box>
                                    <Box  >
                                        <h4>Revenues (as of FY 2020-21)</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Enter Revenues "
                                            className={styles.innerTextField}
                                            placeholder="Enter Revenues"
                                            // helperText="Full width!"
                                            fullWidth

                                            // InputLabelProps={{
                                            //     shrink: true,
                                            // }}
                                            variant="outlined"
                                            onChange={(e)=>this.setState({revenue:e.target.value})}
                                        />
                                    </Box>
                                    <Box >
                                        <h4>Is HR analytics an independent function in your organization? Please elaborate on the existing team composition</h4>
                                        <TextField
                                            id="outlined-full-width"
                                            label="HR analytics"
                                            className={styles.innerTextField}
                                            placeholder="Enter HR analytics"
                                            // helperText="Full width!"
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e)=>this.setState({team:e.target.value})}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
								<h4>HR Metrics</h4>
								<Table className={styles.table} aria-label='simple table'>
									<TableHead className={styles.tableHead}>
										<TableRow className={styles.tableRow}>
											<TableCell>Metrics</TableCell>
											<TableCell>Unit</TableCell>
											<TableCell>FY 2018-19</TableCell>
											<TableCell>FY 2019-20</TableCell>
											<TableCell>FY 2020-21</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow className={styles.tableRow}>
											<TableCell>Revenue Growth</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Profit Growth
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total Employee Headcount – FTE/ Permanent Employees			
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total Contractual Employee Headcount
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total HR Employee Strength
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												HR to Employee Ratio
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Gender Diversity Ratio [females to male]
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Average Employee Age
											</TableCell>
											<TableCell>Years/Months</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Voluntary Attrition Rate (Annual)
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Employee Engagement Scores
											</TableCell>
											<TableCell>On a scale of 1 to 10</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
													fullWidth
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								
								
							</Box>
                        </CardContent>
                    </Card>
                </div>
				</div>
				<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
										<Box>
											<h1 style={{ textAlign: 'center' }}>
												<u>Section B-Quantitative Measurement</u>
											</h1>
											<p>
												<b>
												This section covers the quantitative aspects of the
												initiative under the concerned category. Providing all the
												information under this section will enhance your nomination
												and aid the Jury and accurate evaluation of application</b>
											</p>
										</Box>
										<Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell><b>Metrics</b></TableCell>
														<TableCell><b> Unit</b></TableCell>
														<TableCell><b> FY 2018-19</b></TableCell>
														<TableCell> <b>FY 2019-20</b></TableCell>
														<TableCell> <b>FY 2020-21</b></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow className={styles.tableRow}>
														<TableCell>
															Cost of Human Capital to the total operating cost
															(Direct and Indirect spend) <br />
															<i>
																Cost of Human Capital :All direct and indirect spend
																on Human Capital in your organization
															</i>
														</TableCell>
														<TableCell> % </TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Please specify the components you have taken into
															consideration to account for total human capital cost-
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Net profit per employee(PAT) <br />
															<i>
																Net profit per employee: Net profit after
																taxes/total number of FTE.
															</i>
														</TableCell>
														<TableCell>Ratio/ in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Average cost per hire</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Average benifit cost per employee</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Average training spend per employee
														</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box>
												<p>
													Please share any other HR analytics measurement that you
													track and feel relevant to support your application
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.table}>
														<TableCell>Metrics</TableCell>
														<TableCell>Unit</TableCell>
														<TableCell>FY 2018-19</TableCell>
														<TableCell>FY 2019-20</TableCell>
														<TableCell className={styles.tableHead}>
															FY 2020-21
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		unti: {
																			...this.state.unti,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		unti: {
																			...this.state.unti,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box>
												<p>
													Please specify in percentage(%) terms, what type of HR
													analytics is currently practiced in the organisation. Also
													share the details of which HR processes it is used across
													the below listed types.
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell>Type of Analytics</TableCell>
														<TableCell>Percentage (%)</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															Descriptive analytics(using past trends for reporting){' '}
															<br /> <i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		descriptivAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Diagnostic analytics (Testing casual relationships){' '}
															<br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diagnosticAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Predictive Analytics (Using data for future descision
															making) <br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		predictiveAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Prescriptive Analytics (Data used for descision making
															and recommending changes to HR processes/ practices){' '}
															<br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		prescriptiveAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box item xs={12}>
												<p>
													On which of the following do you track and preserve
													metrics as part of your HR dashboard to the management?
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell>HR Process</TableCell>
														<TableCell>Do not have specific metrics</TableCell>
														<TableCell>
															Have specific metrics and track them
														</TableCell>
														<TableCell>
															List of specific tracks that are tracked
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>Talent Acquisition</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			listOfMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Transfer and Job Rotation</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Performance Management</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Rewards and Recognition</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Learning and Development</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Career/Succession Planning</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Employee Grievances</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Employee Retention/Hi-potential retention
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Diversity and Inclusion</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Any others (please specify)</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											{/* <Box styles={styles.buttons}>
												<Link to='/section-a'>
													<Button
														size='large'
														variant='outlined'
														style={{ color: '#e6257e', marginTop: '15px' }}
													>
														BACK
													</Button>
												</Link>
												<Button
													size='large'
													variant='contained'
													style={{
														backgroundColor: '#e6257e',
														color: '#fff',
														marginLeft: '40%',
														marginTop: '15px',
													}}
													onClick={this.handleSaveDraftSecB}
												>
													SAVE AS DRAFT
												</Button>
												<Link to='/section-c'>
													<Button
														size='large'
														variant='contained'
														style={{
															backgroundColor: '#e6257e',
															color: '#fff',
															marginLeft: '10px',
															marginTop: '15px',
														}}
													>
														{' '}
														NEXT
													</Button>
												</Link>
											</Box> */}
										</Box>
									</Box>
								</CardContent>
							</Card>
				</div>
				<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
										<Box>
											<h1 style={{ textAlign: 'center' }}>
												<u>Section C-Qualitative Measurement</u>
											</h1>
											<p>
												Qualitative measurements are based on initiatives that
												have been implemented or are ongoing in the organisation.
												These would allow for understanding and evaluating the
												nature of initiatives, the issues which were addressed by
												these initiatives, the impact on stakeholders and efforts to
												sustain these initiatives.
												<b>
													Please take your response in the section to approximately
													500 words for response
												</b>
											</p>
										</Box>
										<Box>
											<Box>
												<h4>
													What were the key issues /challenges faced for which an
													innovative initiative was required?
												</h4>
												<p>
													[Key pointers -Integration of HR analytics, alignment of
													HR strategy, contribution of human capital, employee
													productivity levels, employee turnover, employee
													absenteeism etc.]
												</p>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ issues: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													Describe the initiatives that emerged to address the above
													mentioned issues/challenges and their key objectives
												</h4>
												<p>
													[Key pointers - HR dashboard set-up, applying HR
													Technology, integrating Human Capital analytics, educating
													stakeholders on HR analytics, measurement of employee
													productivity and efficiency etc.]
												</p>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ initiatives: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													How did you assess the effectiveness of the initiatives
													undertaken and what were the results?
												</h4>
												<p>
													[Key pointers -ROI Human Capital, cost per hire,
													comparison with industry benchmarks, profit per employee,
													rate of customer satisfaction, rate of product
													development, change in quality levels etc.]
												</p>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ effectiveness: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													What impact have your initiatives had on your key
													stakeholders?
												</h4>
												<p>
													[Key pointers -Enhanced organisational performance,
													improved competency levels, developed HR analytics
													orientation, leadership effectiveness, employee
													satisfaction etc.]
												</p>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ impact: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													What strategic level changes are envisioned, in the near
													future, to make the HR analytics function in your
													organisation more effective?
												</h4>
												<p>
													[Key pointers -Financial and people investments,
													adaptation of HR analytics, updating of HR systems, HR
													analytics training programs, dynamic HR policy etc.]
												</p>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ futureStrategies: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													Please provide an action plan with suggested timelines and
													metrics to implement the strategy outlined above.
												</h4>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ actionPlan: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													Please share the details of any other HR analytics
													efforts/initiatives designed to address needs that emerged
													during the COVID-19 pandemic.
												</h4>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ covidInitiatives: e.target.value })
													}
												/>
											</Box>
											<Box>
												<h4>
													Please use the space below to add any other for the
													details/information
												</h4>
												<TextField
													fullWidth
													variant='outlined'
													onChange={(e) =>
														this.setState({ furtherDetails: e.target.value })
													}
												/>
											</Box>
										</Box>
										<Box>
											<p>
												<i>
													Note: You may choose to attach supporting documents of
													maximum 15 pages/slides for a particular award category.
													These collaterals will be accepted only in the form of
													PPT, PDF or Word documents. No excel format, links
													hyperlinked in text will be accepted. Additionally,
													applicants may also submit a video of up to 5 minute to
													support their application. This video needs to talk
													specifically about the intervention/practice been
													showcased and should not be genetic one.{' '}
												</i>
											</p>
										</Box>
										<Box>
											<Link to='/section-b'>
												<Button
													size='large'
													variant='outlined'
													style={{ color: '#e6257e', marginTop: '20px' }}
												>
													BACK
												</Button>
											</Link>
											<Button
												size='large'
												variant='contained'
												onClick={this.handleSaveDraftSecC}
												style={{
													backgroundColor: '#e6257e',
													color: '#fff',
													marginLeft: '45%',
													marginTop: '15px',
												}}
											>
												SAVE AS DRAFT
											</Button>
											{/* <Link to='/organization'> */}
												<Button
													size='large'
													variant='contained'
													id="print"
													onClick={this.generatePDF}
													style={{
														backgroundColor: '#e6257e',
														color: '#fff',
														marginLeft: '10px',
														marginTop: '15px',
														cursor:'pointer'
													}}
												>
													SUBMIT
												</Button>
											{/* </Link> */}
										</Box>
									</Box>
								</CardContent>
							</Card>
				</div>
				<CustomFooter />
            </>
        );
        //Customizable Area End
    }
}
