 // Customizable Area Start
 import React from 'react';
 import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
 import AppHeaderWeb from './AppHeaderWeb.web';
 import Footer from './Footer.web';
 import EmailAccountRegistrationController, { Props, configJSON } from "../../blocks/email-account-registration/src/EmailAccountRegistrationController.web";
import CustomFooter from './Footer.web';
 
 class PrivacyPolicyScreen extends EmailAccountRegistrationController {
  constructor(props: any) {
      super(props);
  }
  async componentDidMount(){
   this.getPrivacyAndPolicy(); 
 }
  render(): React.ReactNode {
    return (
      <>
       <AppHeaderWeb navigation id = ''  match = ''/>
        <div style={{width: "100%", padding: '40px'}}>
           <h1 style={{textAlign: "center"}}>Privacy and Policy</h1>
           <div  dangerouslySetInnerHTML={{__html: this.state.privacy_policy}}></div>
        </div>
 
        <CustomFooter/>

      </>
    );
  }

}
 export default PrivacyPolicyScreen;
  // Customizable Area Start