import { Modal } from 'antd';
import React from 'react';
// Customizable Area Start
import {logo} from '../assets';


const PaymentSuccessCard = () => {
    return(
        <div style={{ height: '500px', margin: '0 auto'}}>
            <Modal title={<img style={{ marginRight: '10px', height: '30px'}} src={logo} alt="L" />} >
                <p>Dear Organization,</p>
                <h3 style={{color: "#f00768",}}>Congratulations</h3>
                <p>You have successfully registered in online platform and here is the your login details as below.</p>
                <br/>
                <br/>
                <p>User id:</p>
                <p>Password</p>
                <br/>
                <br/>
                <h3>Thanks,</h3>
                <h3>The SHRM team</h3>
                <br/>
                <br/>
                <div style={{ textAlign: 'center', backgroundColor: "#191075"}}>
                    <p style={{color:'#eeedf0'}}> @2021 SHRM india. All rights reserved.</p>
                </div>
            </Modal>
        </div>
    )
}
// Customizable Area End
export default PaymentSuccessCard;