//@ts-ignore
//@ts-nocheck
import React from 'react';
import { 
    Card,
    Box,
    TextField,
    Button, 
    CardContent
} from '@material-ui/core';
// Customizable Area Start
import CustomformController, {Props} from "../CustomformController";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
        width:'50%'
    },
    cardContent: {
        padding:'16px',
     },
    textField: {
        width: 'inherit',
        height: 'inherit',
        border: 'none'
    },
    innerTextField: {
        marginBottom: 10,
    },
    formControl: {
        margin: 5,
    },
    table: {
        minWidth: 650,
        border: '0.1px solid #D3D3D3',
        borderRadius: 3,
        marginTop: 10,
        marginBottom: 10
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',

    },

    tableHead: {
        fontWeight: 600,
        borderRight: '0.1px solid #D3D3D3',
        backgroundColor: '#DDDDDD'
    },
    buttons: {
        display: 'flex',
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
};
    // Customizable Area End
 class HealthWealthSectionC extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render(){
        // Customizable Area Start
        
        return (
			<div id='content'></div>
				);
        
  }
}
export default withStyles(styles)(HealthWealthSectionC);
// Customizable Area End
