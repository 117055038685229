
import { Modal } from 'antd';
import React from 'react';
import {Button } from '@material-ui/core';
import { borderRadius } from 'react-select/src/theme';
import CustomformController,{Props} from '../CustomformController';
import { withStyles } from "@material-ui/core/styles";
import Dropzone from 'react-dropzone';
import { uploadIcon } from '../assets'
// import Dropzone from './Dropzone.web';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
// const styles = {
//     container: {
//         height: 150,
//         width: '100%',
//         // border: '2px dashed #e6257e',
//         borderRadius: '10px',
//         backgroundColor: '#EFEFEF'
//     },
//     heading: {
//         textAlign: 'center'
//     }
// }
// class UploadDocument extends CustomformController{
//     const styles = useStyles()
//     return (
//         <div style={{ height: 'auto', margin: '0 auto' }}>
//             <h1 className={styles.heading}>Upload your Supporting Documents</h1>
//             <p>File type supported Word,PPT,Excel,Video,Audio,Image,PDF,etc</p>
//             <div className={styles.container}>
//                 {/* <UploadFileIcon /> */}
//                 {/* <Dropzone /> */}
//             </div>
//         </div>
//     )
// }
export default class UploadDocument extends CustomformController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.onDrop = (files:any) => {
          this.props.UploadFile(files);
        };
      // Customizable Area End
    }
    render() {
      // Customizable Area Start
      const files = this.props.files.map((file:any) => (
        <li key={file.name}>
          {file.name} - {file.size>1000000?((file.size)/1000000).toFixed(2):((file.size)/1000000).toFixed(4)} mb
        </li>
      ));

      return (
        <div >
        <Dropzone onDrop= {this.onDrop} accept="image/*, video/mp4, audio/*, .pdf ,.pptx ,.ppt ,
         application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,
         application/vnd.ms-powerpoint , application/vnd.openxmlformats-officedocument.presentationml.presentation,
           application/msword , application/vnd.openxmlformats-officedocument.wordprocessingml.document">
          {({getRootProps, getInputProps,isDragReject}) => (
            <section className="container dropzone-main">
              <div {...getRootProps({className: 'dropzone'})} style={{textAlign: 'center', alignItems: "center",height:'auto',padding:30,cursor:'pointer'}}>
              {/*@ts-ignore */}
              <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <p style={{
                                    color: 'FFA500'
                                }}>Maximum upload file size: 50MB</p>
                <aside >
                    <ul>{files}</ul>
                </aside>
                {isDragReject && "File type not accepted, sorry!"}
              </div>
            </section>
          )}
        </Dropzone>
        </div>
      );
    }
}
  
// export default withStyles(styles)(UploadDocument);
// Customizable Area End