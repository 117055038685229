 // Customizable Area Start
import React from 'react';
import {Divider} from '@material-ui/core';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import AppHeaderWeb from './AppHeaderWeb.web';
import Footer from './Footer.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';

class HomeScreen extends React.Component {
  
  render(): React.ReactNode {
    return(
      <>
        <AppHeaderWeb navigation id = ''  match = '' />
    
        <EmailAccountRegistration navigation id = ''  match = '' />
        <Footer />
       </>
     );
  }

}
export default HomeScreen;
 // Customizable Area Start