//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {  Card, Box, TextField, Button, CardContent, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyleSheet} from 'react-native';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from '../CustomformController';
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import Grid from '@material-ui/core/Grid';
import OrgHeaderWeb from '../OrgHeader.web';
import CustomFooter from '../../../../components/src/Footer.web';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    box: {
        width: '100%',
    },
}); 
    // Customizable Area End
 export default class TalentAcquistionSectionB extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
      render(){ 
        //Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0]
        return (
					<>
						<OrgHeaderWeb/>
						<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
										<Box>
											<h1 style={{ textAlign: 'center' }}>
												<u>Section B-Quantitative Measurement</u>
											</h1>
											<p>
												<b>
												<b>
												This section covers the quantitative aspects of the
												initiative under the concerned category. Providing all the
												information under this section will enhance your nomination
												and aid the Jury and accurate evaluation of application.</b></b>
											</p>
										</Box>
										<Box>
										<Paper style={{overflowX: "auto"}}>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
													<TableCell style={{width: '80px'}}><b> Sr. No.</b></TableCell>
													<TableCell> <b>Metrics</b></TableCell>
													<TableCell><b> Unit</b></TableCell>
													<TableCell> <b>FY 2018-19</b></TableCell>
													<TableCell><b> FY 2019-20</b></TableCell>
													<TableCell><b> FY 2020-21</b></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow className={styles.tableRow}>
													<TableCell><b>1</b> </TableCell>
														<TableCell>
														<b>Total Manpower/Hiring Budget </b><br />
															<i>
															Annual budget identified to meet current and future manpower/ hiring needs
															</i>
														</TableCell>
														<TableCell> INR </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manpower['18']}
																onChange={(e) =>
																	this.setState({
																		manpower: {
																			...this.state.manpower,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manpower['19']}
																onChange={(e) =>
																	this.setState({
																		manpower: {
																			...this.state.manpower,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manpower['20']}
																onChange={(e) =>
																	this.setState({
																		manpower: {
																			...this.state.manpower,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>2</b> </TableCell>
														<TableCell>
															<b>Total Budgeted Headcount (Manpower)</b>
														</TableCell>
														<TableCell>Nos.</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.headcount['18']}
																onChange={(e) =>
																	this.setState({
																		headcount: {
																			...this.state.headcount,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.headcount['19']}
																onChange={(e) =>
																	this.setState({
																		headcount: {
																			...this.state.headcount,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.headcount['20']}
																onChange={(e) =>
																	this.setState({
																		headcount: {
																			...this.state.headcount,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>3</b> </TableCell>
														<TableCell><b>Average cost per hire</b> <i><br/>Cost per Hire includes costs incurred on Advertising, Job fair participations, travel costs for interviews and selection, agency fees, test administration, sign-on bonuses, etc.</i></TableCell>
														<TableCell>INR</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.avarageCostPerHire['18']}
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.avarageCostPerHire['19']}
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.avarageCostPerHire['20']}
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>4</b> </TableCell>
														<TableCell><b>Average Time to hire/fill a vacancy <sup>3</sup></b><br/>
															<i>
															<sup>3</sup>Time to hire is computed as total number of days of open jobs / total number of jobs open
															</i>
														</TableCell>
														<TableCell>in days</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averageTime['18']}
																onChange={(e) =>
																	this.setState({
																		averageTime: {
																			...this.state.averageTime,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averageTime['19']}
																onChange={(e) =>
																	this.setState({
																		averageTime: {
																			...this.state.averageTime,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averageTime['20']}
																onChange={(e) =>
																	this.setState({
																		averageTime: {
																			...this.state.averageTime,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
												<TableCell> </TableCell>
														<TableCell>
														4a. Executives
														</TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.executive['18']}
																onChange={(e) =>
																	this.setState({
																		executive: {
																			...this.state.executive,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.executive['19']}
																onChange={(e) =>
																	this.setState({
																		executive: {
																			...this.state.executive,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.executive['20']}
																onChange={(e) =>
																	this.setState({
																		executive: {
																			...this.state.executive,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell> </TableCell>
														<TableCell>
														4b. Senior managers
														</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.seniorManager['18']}
																onChange={(e) =>
																	this.setState({
																		seniorManager: {
																			...this.state.seniorManager,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.seniorManager['19']}
																onChange={(e) =>
																	this.setState({
																		seniorManager: {
																			...this.state.seniorManager,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.seniorManager['20']}
																onChange={(e) =>
																	this.setState({
																		seniorManager: {
																			...this.state.seniorManager,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												<TableRow>
												<TableCell> </TableCell>
														<TableCell>
														4c. Managers
														</TableCell>
														<TableCell></TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manager['18']}
																onChange={(e) =>
																	this.setState({
																		manager: {
																			...this.state.manager,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manager['19']}
																onChange={(e) =>
																	this.setState({
																		manager: {
																			...this.state.manager,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.manager['20']}
																onChange={(e) =>
																	this.setState({
																		manager: {
																			...this.state.manager,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													
													<TableRow>
													<TableCell> </TableCell>
														<TableCell>
													4d. Others
														</TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.others['18']}
																onChange={(e) =>
																	this.setState({
																		others: {
																			...this.state.others,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.others['19']}
																onChange={(e) =>
																	this.setState({
																		others: {
																			...this.state.others,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.others['20']}
																onChange={(e) =>
																	this.setState({
																		others: {
																			...this.state.others,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>5</b> </TableCell>
													<TableCell>
														<b>Hire Yield Ratio <sup>6</sup></b><br/>
														<i> <sup>6</sup>Applicants who get hired divided by total number of applicants</i>
														</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.applicants['18']}
																onChange={(e) =>
																	this.setState({
																		applicants: {
																			...this.state.applicants,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.applicants['19']}
																onChange={(e) =>
																	this.setState({
																		applicants: {
																			...this.state.applicants,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.applicants['20']}
																onChange={(e) =>
																	this.setState({
																		applicants: {
																			...this.state.applicants,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>

																
													<TableRow>
													<TableCell><b>6</b> </TableCell>
													<TableCell>
													<b>Satisfaction rates (Candidate Experience)</b>			
														</TableCell>
														<TableCell>On a scale of 1 to 10</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.satisfactionRate['18']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.satisfactionRate['19']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.satisfactionRate['20']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell> </TableCell>
														<TableCell>6a. Satisfaction with interview process</TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																value= {this.state.interviewProcess["doNotHaveMetrics"]}
																fullWidth
																onChange={(e) =>
																	this.setState({
																		interviewProcess: {
																			...this.state.interviewProcess,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.interviewProcess['haveMetricsAndTrack']}
																onChange={(e) =>
																	this.setState({
																		interviewProcess: {
																			...this.state.interviewProcess,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.interviewProcess['listOfMetricsAndTrack']}
																onChange={(e) =>
																	this.setState({
																		interviewProcess: {
																			...this.state.interviewProcess,
																			listOfMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell> </TableCell>
														<TableCell>6b. Satisfaction with on-boarding process</TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.onBoardingProcess["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		onBoardingProcess: {
																			...this.state.onBoardingProcess,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.onBoardingProcess["haveMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		onBoardingProcess: {
																			...this.state.onBoardingProcess,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.onBoardingProcess["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		onBoardingProcess: {
																			...this.state.onBoardingProcess,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>7</b> </TableCell>
														<TableCell><b>Business manager satisfaction on the hiring process/ outcome achieved</b></TableCell>
														<TableCell>On a scale of 1 to 10</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.hiringProcess["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		hiringProcess: {
																			...this.state.hiringProcess,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.hiringProcess["haveMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		hiringProcess: {
																			...this.state.hiringProcess,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.hiringProcess["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		hiringProcess: {
																			...this.state.hiringProcess,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>8</b> </TableCell>
														<TableCell><b>Quality of new hires</b></TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.quality["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		quality: {
																			...this.state.quality,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
															value= {this.state.quality["haveMetricsAndTrack"]}
																fullWidth
																onChange={(e) =>
																	this.setState({
																		quality: {
																			...this.state.quality,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.quality["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		quality: {
																			...this.state.quality,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell> </TableCell>
														<TableCell>8a. New hire mortality <sup>8</sup> <br/>
															 <i>8% of total attrition due to separation on the first year of employment</i></TableCell>
															 <TableCell>%</TableCell>
														<TableCell>
															
															<TextField type="number" required
																fullWidth
																value= {this.state.newHire["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		newHire: {
																			...this.state.newHire,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.newHire["haveMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		newHire: {
																			...this.state.newHire,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.newHire["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		newHire: {
																			...this.state.newHire,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													
													<TableRow>
													<TableCell> </TableCell>
														<TableCell> 	8b. New hire average performance in first six months				</TableCell>
														<TableCell> </TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averagePerformance["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		averagePerformance: {
																			...this.state.averagePerformance,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averagePerformance["haveMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		averagePerformance: {
																			...this.state.averagePerformance,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.averagePerformance["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		averagePerformance: {
																			...this.state.averagePerformance,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>9</b> </TableCell>
														<TableCell><b>Vacancies filled internally (through Internal Job Postings)</b></TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.vacancies["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		vacancies: {
																			...this.state.vacancies,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.vacancies["haveMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		vacancies: {
																			...this.state.vacancies,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.vacancies["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		vacancies: {
																			...this.state.vacancies,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>10</b> </TableCell>
														<TableCell>
														<b>Employees hired through referrals</b>
																
														</TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.referrals["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		referrals: {
																			...this.state.referrals,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
															value= {this.state.referrals["haveMetricsAndTrack"]}
																fullWidth
																onChange={(e) =>
																	this.setState({
																		referrals: {
																			...this.state.referrals,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.referrals["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		referrals: {
																			...this.state.referrals,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>11</b> </TableCell>
														<TableCell><b>Employees hired through Social Recruiting Channels</b></TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.recruiting["doNotHaveMetrics"]}
																onChange={(e) =>
																	this.setState({
																		recruiting: {
																			...this.state.recruiting,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
															value= {this.state.recruiting["haveMetricsAndTrack"]}
																fullWidth
																onChange={(e) =>
																	this.setState({
																		recruiting: {
																			...this.state.recruiting,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number" required
																fullWidth
																value= {this.state.recruiting["listMetricsAndTrack"]}
																onChange={(e) =>
																	this.setState({
																		recruiting: {
																			...this.state.recruiting,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											</Paper>
										
							<Grid container spacing={3}>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
										<Button size='large' variant='contained' id='print' onClick={() => { window.history.back() }} style={{
											backgroundColor: 'white',
											color: '#fff',
											marginLeft: '10px',
											marginTop: '15px',
											cursor: 'pointer'
										}}> <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
									</Grid>
									<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "center" }}>
									<Button
										size='large'
										variant='contained'
										size='large' variant='contained' id='print' style={{
											backgroundColor: '#e6257e',
											color: '#fff',
											marginRight: '10px',
											marginTop: '15px',
											cursor: 'pointer',
										}}
										onClick={this.handleSaveDraftSecB}
										>SAVE AS DRAFT
										</Button>
										</Grid>
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
											<Link to='/talent_acquisition_c'>
												<Button
													onClick={this.handleSaveDraftSecB}
													size='large'
													variant='contained'
													style={{
														backgroundColor: '#e6257e',
														color: '#fff',
														marginLeft: '10px',
														marginTop: '15px',
													}}
												>
													{' '}
												NEXT
												</Button>
											</Link>
										</Grid>
						</Grid>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</div>
						<CustomFooter/>
					</>
				);
        //Customizable Area End
      }
}
