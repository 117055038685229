//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react';
// Customizable Area Start
import { Modal, Card, Box, TextField, Button, CardContent,Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom'
import {Props} from './Customform';
import CustomformController from './CustomformController';
import Paper from '@material-ui/core/Paper';
import UploadDocument from './Containers/UploadDocument.web';
import { withStyles } from "@material-ui/core/styles";
import {congragulations} from './assets';
import cancelIcon from '../assets/cancelIcon.png'
import './org.css';
import  uploadIcon1  from '../assets/uploadIcon.png';
import  AlertIcon  from '../assets/alertIcon.png';
import CustomFooter from '../../../components/src/Footer.web';
import OrgHeaderWeb from './OrgHeader.web';
import { Progress , Spin} from "antd";
import { FailedToast,WarningToast} from "../../email-account-registration/src/Toast";
import FilePreviewModal from '../../../components/src/FilePreviewModal';
import CircularProgress from '@material-ui/core/CircularProgress';
const styles = {
    root: {
        flexGrow: 1,
        height: '80px'
    },
    card: {
        maxWidth: 850,
    },
    textField: {
        marginBottom: '10px',
        maxWidth: 850,
    },
    innerTextField: {
        marginBottom: '10px',
        maxWidth: 750,
    },
    formControl: {
        margin: '10px',
        width:"60%"
    },
    table: {
        minWidth: 650,
        marginTop: '10px',
        marginBottom: '10px'
    },
    tableCell: {
        padding: '15px 30px'
    },
    tableHead: {
        fontWeight: 600,
        borderRight: '0.1px solid #D3D3D3',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'right'
    },
    container: {
        width: '100%',
        border: '2px dashed #e6257e',
        borderRadius: '10px',
        backgroundColor: '#EFEFEF',
        display: 'flex',
        justifyContent: 'center',
    },
    heading: {
        textAlign: 'center'
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
};
// Customizable Area End
  class Org extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start        
        this.UploadFile= (files)=>{
            if(this.state.supportiveFiles.length > 20 || files.length > 20 || (this.state.supportiveFiles.length+files.length) > 20){
                this.setState({fileSize:true})

            }else{              
            
            let allfileSize = 0
            files.map((items:any )=>{
                return(
                allfileSize=allfileSize+items.size
                )
            })            
            this.setState({
                totalFileSize:allfileSize>1000000?(allfileSize/1000000).toFixed(2):(allfileSize/1000000).toFixed(4),
                numOfFile:files.length,
            })
            this.setState({uploadButton:false})

            if(files.some(file => file.size >  50000000)){                
                this.setState({
                    fileSize: true,
                    uploadButton:true,
                    uploadedDocumentPercentage:0,
                    totalFileSize:0,
                    numOfFile:0
                })                
                return
            }else{
            this.setState({ ...this.state, files: files })
            if(files[0].name){
                this.setState({uploadedDocumentPercentage:100})                
                }
            }
            this.setState({fileSize: false})
        }
        }
        this.id = localStorage.getItem('user_id')
        this.handleClickModal = this.handleClickModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        // Customizable Area End
      }
      
       //Customizable Area Start
     componentWillUnmount(): Promise<void> {
         clearInterval(this.intervalId)
     }

      async componentDidMount(){
        const role = localStorage.getItem("role");
        const is_special = JSON.parse(localStorage.getItem("special"));
        this.setState({is_special:is_special})        
        if (role == 'jury_penalist') {
          this.props.navigation.navigate("JuryMemberDashboard");
          return;
        }
        this.getAllCategory();
      }
      handleClickModal(){
        this.handleDeleteSupportingDocument(this.state.deleteDocumentFileId)   
      }
      handleCloseModal(){
        this.setState({deleteDocumentModal:false,deleteSupportingDocument:false})
      }
      handelFileUploadModal(id:any,deadline_passed:any,data:any){
        if(data?.status== "completed"){this.setState({finalSubmittedFormStatus:true})}
        else{this.setState({finalSubmittedFormStatus:false})}
        if(deadline_passed){
            WarningToast("Alert", <>The Deadline time for this application form is over. You will not be able to Edit, Upload or Submit this form any longer. Please reach out to <a href="mailto:SHRMIAwards@shrm.org">SHRMIAwards@shrm.org</a> for any assistance..</>);
        }else{
            this.setState({
                selectedCategoryID:id,
                fileUploadModal:true
            })
        }
      }

      submitSection = (params)=>{
        if(params?.status === "completed") 
            
            return <p style={{color:'rgba(0, 0, 0, 0.87)',fontWeight:600}}>Submitted</p>
        else if(params?.ready_to_submit)
        {      
            return   <Button  id="s-form"
               variant="contained" className='btn-primary'                                    
               onClick={() => this.handleCategorySubmit(params)}
               disabled={this.state.formLoader[params.category?.data?.attributes?.id] } startIcon={this.state.formLoader[params.category?.data?.attributes?.id] ? <CircularProgress style={{width:'15px',height:'15px',color:'white'}}/>:null} 
           >Submit Form </Button>
        }                                         
        return <p className="form-submit-error">You can submit, only <br/>after filling the form</p>
        }

        UploadButtonFunction = (attributes)=>{
            if((attributes?.supporting_documents != null)){                   
                return (<>{attributes?.status === "completed" ?<Button
                id="support-doc"
                className='btn-white'
                onClick={() =>this.getSupportingDoc(attributes?.id,false,attributes)}
            >View</Button>:
                <Button
                    className='btn-white'
                    onClick={() =>this.getSupportingDoc(attributes?.id,attributes?.category?.data?.attributes?.deadline_passed,attributes)}
                >Upload</Button>
            }</>)
            }
                else{
                    return (
                        <>{attributes?.status === "completed" ?
                            <Button
                            id="status-completed"
                        className='btn-white'
                        onClick={() =>this.setState({docNotUploaded:true})}
                    >View</Button>:
                    <Button  
                       className='btn-white' id="handelFileUploadModal"
                       onClick={() =>this.handelFileUploadModal(attributes?.id,attributes?.category?.data?.attributes?.deadline_passed ,attributes)}
                   >Upload
                   </Button>
                        }</>
                    )                    
                }
        }

      // Customizable Area End
    render(){
        // Customizable Area Start
        const { classes } = this.props; 
        const submitted_cat = localStorage.getItem("submitted_cat") 


        return (
            <>
            <OrgHeaderWeb/>            
            <div className="communityImpactSection-header">
                <header className="header-section">
                    <div className="" id="heading">
                        <h1>Organization Dashboard</h1>
                    </div>
                </header>
            </div>
            <div style={{ margin:'50px auto', flex:1}}>            
                <div id="categoryDiv">
                    {this.state.categoryListApi
            .filter(status => status?.showMessage)
            .map((e, i) => {
                const { reminder_pop_up, name, deadline_date } = e.attributes.category.data.attributes;

                if (reminder_pop_up) {
                return (
                    <div className='reminder-popup' key={i} id="inner-cat-div">
                    <p style={{ marginRight: "20px" }}>
                        You have pending "{name}" Application form. Please submit before {deadline_date}.
                    </p>
                    <p className='red-icon' id="hideAlertMessage" onClick={() => this.hideAlertMessage(e.id)}>
                        <b>X</b>
                    </p>
                    </div>
                );
                }
            })}
                
            </div>
            <div className="organization-dashboard-main" id="category-button-div">
            {this.state.categoryListApi.length === 0 && !this.state.categoryLoading && ( <div style={{ color: 'rgba(0, 0, 0, 0.85)', textAlign: "center", alignItems: "center", marginBottom: "1rem"}}><span>Categories are not available, Please select</span>
                </div> )}

                { ((this.state.categoryListApi.length < this.state.max_number_of_categories) || this.state.is_special) && 
                    <Box style={{ textAlign:'end',margin:'0px'}} m={1} >
                        <Link 
                            to={`/registration-category/${this.id}`}  
                        >
                                <Button style={{ backgroundColor: '#e6257e', color: '#fff', fontWeight: '600'}} >
                                    Add Additional Categories
                            </Button>
                        </Link>
                    </Box>
                    } 
                <div className="Categories_table" id="table-main-div">
                <Card className={styles.card}>                    
                    <CardContent style={{ padding: '0px' }}>
                        <Table id="table" className={styles.table} aria-label="simple table">
                            <TableHead id="table-head">
                                <TableRow id="table-row" className='categories-table-header'>
                                    <TableCell className="table-border-right">Category Name</TableCell>
                                    <TableCell className="table-border-right" align="center">Application <br /> Form</TableCell>
                                    <TableCell className="table-border-right" align="center">Supporting <br /> Document</TableCell>
                                    <TableCell className="table-border-right" align="center">Status</TableCell>
                                    <TableCell className="table-border-right" align="center">Evaluation <br /> Status</TableCell>
                                    <TableCell align="right"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody id="table-body">                            
                                {this.state.categoryListApi.sort((a,b)=>a.id-b.id).map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell id="table-cell" className="table-border-right" component="th" scope="row">
                                    {row?.attributes?.category?.data?.attributes?.name}
                                    </TableCell>
                                    <TableCell id="table-cell" className="table-border-right" align="center">
                                        {
                                        row?.attributes?.status === "pending" 
                                        ? 
                                        <>{
                                            <Button
                                            id={"edit-btn"+row.attributes?.category?.data.id} 
                                                className='btn-white' 
                                                onClick={() => this.handleEditClick(
                                                    row.attributes.category.data.attributes.name, 
                                                    row.attributes.category.data.attributes.deadline_passed, 
                                                    row.attributes?.application_form?.data?.attributes,
                                                    row.attributes?.application_form?.data,
                                                    row.attributes.category.data.id,
                                                    row.attributes?.application_form?.data?.attributes?.current_section.section_id,
                                                    row.attributes?.application_form?.data?.id
                                                )}
                                                >
                                                Edit
                                                </Button> 
                                           }</>
                                            :<>
                                            {<Button 
                                            id={"view-btn"+row.attributes?.category?.data.id}
                                            className='btn-white'
                                                onClick={()=>{if(row?.attributes?.application_form_pdf){
                                                    window.open(`${row?.attributes?.application_form_pdf}`)}else{
                                                        this.setState({pdfNotReady:true})
                                                    }
                                                }}                                            
                                            >
                                                View 

                                            </Button>}
                                            </>
                                        }
                                            
                                    </TableCell>
                                    <TableCell id="table-cell" className="table-border-right" align="center">
                                        {this.UploadButtonFunction(row?.attributes) }                                 
                                    </TableCell>
                                    <TableCell id="table-cell" className="table-border-right user-status" align="center">
                                        {row?.attributes?.status} 
                                    </TableCell>
                                    <TableCell id="table-cell" className="table-border-right clearance-status" align="center">                                       
                                        {row?.attributes?.clearance_status} 
                                    </TableCell>
                                    <TableCell id="table-cell" className="submit-form-btn" align="center">
                                    { this.submitSection(row?.attributes) }
                                    </TableCell>
                                </TableRow>
                                ))}
                 
                                       
                                            <TableRow className="table-footer-row">
                                                <TableCell id="table-cell" colSpan={5} >
                                                    Final Submission
                                                </TableCell>
                                                <TableCell id="table-cell" align='right' style={{ color: '#817fa6', }}>
                                                    {this.state.finalStatus}
                                                </TableCell>
                                            </TableRow>

                                
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
                </div>

                <Modal
                id="fileUploadModal"
                    open={this.state.fileUploadModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                <div style={{ height: '100%', margin: 'auto',display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'30rem' }}>
                    <Paper style={{padding:'1rem'}}>
                    <div className="modal-close-button"><img src={cancelIcon} className="modal-close-icon" onClick={this.handleClose}/></div>
                        <Typography variant="h5" style={{textAlign:'center',fontWeight:600,margin:'15px 0px'}}>Upload your Supporting Documents</Typography>
                        <p>File Type Supported Word, PPT, Excel, Video, Audio, Image, PDF, etc...(Maximum upload file size: 50MB)</p>
                                
                        <div className={classes.container}>
                            <UploadDocument files={this.state.files} UploadFile={this.UploadFile}  multiple onChange={(e)=> {}}  />
                        </div>
                        <div className='progressbar' >
                            <img src={uploadIcon1} alt="icon" className="modal-icon-size" />
                            <div className='progressbar1' >
                                <Progress percent={this.state.progressBarPercentage} status="active" />
                                    <div>
                                    <p className='file-name'>Uploading {this.state.numOfFile} files </p>
                                        <p className='mb'>{(this.state.uploadFileSize)} mb of {this.state.totalFileSize} mb</p>
                                        {!this.state.uploadButton && <p className='uploading'>Uploading...</p>}
                                    </div>
                            </div>
                            
                        </div>
                        <div style={{alignItems: 'center',textAlign: 'center', marginTop:"10px"}}>
                        {this.state.uploadedDocumentLoading ? <Spin size="large" /> : 
                            <Button id="uploadedDocumentSupport" variant="contained" disabled={this.state.uploadButton || this.state.finalSubmittedFormStatus} onClick={()=>{this.uploadedDocumentSupport(this.state.files)}} onClose={this.handleClose} 
                            style={{background: (this.state.uploadButton|| this.state.finalSubmittedFormStatus) ? "#faa8ca" : "#e6257e"
                            , color: '#fff', fontWeight: '600'}}
                            loading={this.state.files}>Upload</Button>}
                        </div>
                    </Paper>
                    </div>
                </Modal>
                <Modal
                id="viewModal"
                        open={this.state.fileSize}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >   
                        <div style={{ height: 'auto', margin: '70px auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '30rem' }}>
                        
                            <Paper style={{ padding: '15px' }}>
                            <div className="modal-close-button"><img src={cancelIcon} className="modal-close-icon" onClick={this.handleClose2}/></div>
                           <div style={{ padding: '20px 60px' }}>
                           <div style={{display:'flex', justifyContent:'center'}}>
                                <img src={AlertIcon} alt="icon" style={{ height: "55px", width: "60px" }} />
                            </div>
                                <Typography variant="h5" style={{ textAlign: 'center', fontWeight: 600, margin: '15px 0px' }}>
                                    Alert</Typography>
                                    <p style={{ textAlign:'center'}}>You can upload upto the maximum file size of <span style={{fontWeight:600}}>50 MB </span>and can't upload more than <span style={{fontWeight:600}}>20 files</span>.</p>
                                    
                           </div>
                               </Paper>
                        </div>
                    </Modal>
        <Modal
        id="fileViewModal"
            open={this.state.fileViewModal}
            aria-labelledby="simple-modal-title"
            className='upload_file_model'
            aria-describedby="simple-modal-description"
        >
            <div style={{ height: '100%', margin: 'auto', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'30rem' }}>
                <Paper style={{padding:'1rem',width:"100%" }}>
                <div className="modal-close-button"><img src={cancelIcon} className="modal-close-icon" onClick={this.handleViewClick}/></div>
                <Typography variant="h5" style={{ textAlign: 'center', fontWeight: 600, margin: '15px 0px' }}>Upload your Supporting Document</Typography>
                        <div >
                            <ul style={{paddingLeft:'5px',  margin:'0px',minHeight:'250px', maxHeight:'480px', overflowY:'auto'}}>
                                {(this.state.supportiveFiles).map((file) => {
                                    return (<span key={file}>
                                        <div style={{display:'flex'}} >
                                    <div style={{display:'flex' ,padding:'10px 0px',}}>
                                    <img src={uploadIcon1} className="modal-icon-size" alt="icon" 
                                    onClick={(e)=>this.handleViewDocument(file)}
                                    /></div>

                                    <div className='uploadDocumnet_list'>
                                    <li style={{display:'flex',justifyContent:'space-between', alignItems:'center',width: '100%'}}> 
                                    <span style={{width:'150px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}} onClick={(e)=>this.handleViewDocument(file)}>{file.filename} </span>                                    
                                    <Button variant="contained" disabled={this.state.ifSubmitedBlockFileUploadModal == 'completed'|| this.state.finalSubmittedFormStatus} onClick={(e)=>this.handleDeleteSupportingDocument(file.file_id)} className={this.state.ifSubmitedBlockFileUploadModal == 'completed' || this.state.finalSubmittedFormStatus ? "btn-disabled":"btn-primary"}  style={{marginRight:'10px'}}                                   
                                    > X </Button>  
                                        </li> 
                                    </div>
                                        </div>
                                    
                                        </span>
                                ) })}
                            </ul>
                        </div>                    
                        <div style={{textAlign: 'center', marginTop:"10px"}}>
                        <Button id="one" variant="contained" className="btn-primary" onClick={this.handleViewClick} style={{marginRight:'20px'}}> OK </Button>
                        
                        <Button id="two" loading variant="contained" className={this.state.ifSubmitedBlockFileUploadModal == 'completed' || this.state.finalSubmittedFormStatus? "btn-disabled":"btn-primary"} onClick={() =>this.setState({
                            fileUploadModal:true,fileViewModal:false
                        })}
                            disabled={this.state.ifSubmitedBlockFileUploadModal == 'completed' || this.state.finalSubmittedFormStatus}> Upload</Button>

                    </div>
                </Paper>
                </div>
        </Modal>
                   
        <Modal
            id="delete-modal"
            open={this.state.deleteDocumentModal}
            onClose={this.handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={{ height: 'auto', margin: '50px auto', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'25rem' }}>
                <Paper style={{padding:'1rem',width:'100%'}}>
                    <Typography variant="h5" style={{textAlign:'center',fontWeight:600,margin:'5px 0px'}}>Delete Document</Typography>
                    <div style={{display: 'inline-flex',justifyContent:'center',width:'100%',padding:'10px 0px'}}>
                        <p>Are you sure you want to delete ?</p>
                    </div>
                   <div className='itemCente'>
                    <Button variant="contained"
                    id="clickModal"
                    onClick={this.handleClickModal} 
                    style={{  backgroundColor: '#e6257e', color: '#fff',marginRight:'10px'}}> Confirm </Button>
                    <Button variant="contained" onClick={this.handleCloseModal} id="handleCloseModal"
                     style={{  backgroundColor: '#e6257e', color: '#fff'}}> Cancel </Button>
                    
                   </div>
                </Paper>
                </div>
        </Modal>  
        <FilePreviewModal viewDocument={this.state.viewDocument}
              handleCloseViewDocument={this.handleCloseViewDocument}
              viewDocumentType={this.state.viewDocumentType}
              viewDocumentUrl={this.state.viewDocumentUrl}
              viewDocumentName={this.state.viewDocumentName}
            />     
       
        <Modal 
            open={this.state.successfullySubmitDocModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className='congragulations-modal'>
                <Paper style={{padding:'1rem'}}>
                    <div className="modal-close-button">
                        <img src={cancelIcon} className="modal-close-icon" onClick={this.handleSuccessfullySubmitDoc}/>
                    </div>
                    <div className="modal-padding">               
                        <img src={congragulations} alt="icon" className='congragulations-modal-icon'/>
                        <h2 style={{textAlign:'center', fontWeight:'bolder'}}>Congratulations</h2>
                        <p>You have successfully submitted application form for the Category <strong>"{submitted_cat}"</strong>. Further process is under review.</p>
                    </div>
                    <Button variant="contained" onClick={this.handleSuccessfullySubmitDoc} id="handleCloseModal"
                     style={{backgroundColor: '#e6257e', color: '#fff',alignSelf:'center',marginTop:10}}> OK </Button>
                </Paper> 
            </div>
        </Modal>
        <Modal 
            open={this.state.ConfirmationModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className='congragulations-modal' >
                <Paper style={{padding:'1rem'}}>
                    <div className="modal-close-button">
                        <img src={cancelIcon} className="modal-close-icon" onClick={this.closeConfirmationPopup}/>
                    </div>
                    <div className="modal-padding">               
                        <h2 style={{textAlign:'center', fontWeight:'bolder'}}>Confirmation!!</h2>
                        <p>Once you continue and submit, you will not be able to make any changes. Are you sure you want to submit? </p>
                        <div className="condition">               
                            <p><span>*</span>You can edit the application form and add/delete supporting documents, until you submit the form. </p>
                        </div>
                    </div>
                    <div className="modal-button-div">
                            <Button variant="contained" className='btn-primary'  onClick={this.handleSuccessfullySubmitDocOpen}
                            > Submit </Button>
                            <Button variant="outlined" className='btn-secondary' 
                            onClick={this.closeConfirmationPopup} 
                            > Cancel </Button>
                        </div>
                </Paper> 
            </div>
        </Modal>
        <Modal 
            open={this.state.pdfNotReady}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className='congragulations-modal' >
                <Paper style={{padding:'1rem'}}>
                    <div className="modal-close-button">
                        <img src={cancelIcon} className="modal-close-icon" onClick={()=>this.setState({pdfNotReady:false})}/>
                    </div>
                    <div className="modal-padding">               
                        <h2 style={{textAlign:'center', fontWeight:'bolder'}}>In progress!!</h2>
                        <p>PDF generation is in progress. Please check after some time.</p>
                    </div>
                </Paper> 
            </div>
        </Modal>
        <Modal 
            open={this.state.docNotUploaded}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className='congragulations-modal' >
                <Paper style={{padding:'1rem'}}>
                    <div className="modal-close-button">
                        <img src={cancelIcon} className="modal-close-icon" onClick={()=>this.setState({docNotUploaded:false})}/>
                    </div>
                    <div className="modal-padding no-document">               
                        <h2 style={{textAlign:'center', fontWeight:'bolder'}}>No Documents!!</h2>
                        <p>There are no supporting documents uploaded.</p>
                    </div>
                </Paper> 
            </div>
        </Modal>
        </div>
            </div>
       
       <CustomFooter/>
        </>
        );
    }
}
export default withStyles(styles)(Org);
//Customizable Area End
