//@ts-ignore
//@ts-nocheck
import React from 'react';
import { 
    Card,
    Box,
    TextField,
    Button, 
    CardContent
} from '@material-ui/core';
// Customizable Area Start
import {StyleSheet} from 'react-native';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from "../CustomformController";
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import { withStyles } from "@material-ui/core/styles";
import CustomFooter from '../../../../components/src/Footer.web';
import {Redirect} from 'react-router-dom';
import OrgHeaderWeb from '../OrgHeader.web';

const styles = {
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
        width:'50%'
    },
    cardContent: {
        padding:'16px',
     },
    textField: {
        width: 'inherit',
        height: 'inherit',
        border: 'none'
    },
    innerTextField: {
        marginBottom: 10,
    },
    formControl: {
        margin: 5,
    },
    table: {
        minWidth: 650,
        border: '0.1px solid #D3D3D3',
        borderRadius: 3,
        marginTop: 10,
        marginBottom: 10
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',

    },

    tableHead: {
        fontWeight: 600,
        borderRight: '0.1px solid #D3D3D3',
        backgroundColor: '#DDDDDD'
    },
    buttons: {
        display: 'flex',
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
};
    // Customizable Area End
 class HRAnalyticSectionC extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render(){
        // Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0 ]
		if(this.state.redirectToOrganization){
            return  <Redirect to = '/organization'/>   
          }

        return (
					<>
					<OrgHeaderWeb/>
					<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
						{/* <div className='makeStyles-root-1'>
							<header
								className='MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4'
								style={{
									background: 'black',
									height: '200px',
									justifyContent: 'center',
								}}
							>
								<div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
									<h6 className='MuiTypography-root makeStyles-title-3 MuiTypography-h6'>
										<img
											src={srmLogo}
											alt='companyName'
											style={{ height: '50px', margin: '15px' }}
										/>
									</h6>
									<h5>
										<hr
											className='MuiDivider-root MuiDivider-flexItem MuiDivider-vertical'
											style={{
												color: 'black',
												marginRight: '10px',
												marginLeft: '10px',
											}}
										/>
									</h5>
		
									<h2
										style={{
											textAlign: 'center',
											marginTop: '10px',
											color: 'white',
										}}
									>
										Application Form
									</h2>
									<h1
										style={{
											textAlign: 'center',
											color: 'white',
											fontWeight: 'bolder',
											marginBottom: '15px',
											marginTop: '5px',
										}}
									>
										Excellence In HR Analytics
									</h1>
								</div>
							</header>
						</div> */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
									<Box>
										<h1 style={{ textAlign: 'center' }}>
											<u>Section C-Qualitative Measurement</u>
										</h1>
										<p><b>

										Qualitative measurements are based on initiatives that have been implemented or are ongoing in the organization. These would allow for understanding and evaluating the nature of initiatives, the issues which were addressed by these initiatives, the impact on stakeholders and efforts to sustain these initiatives. 
										</b>
											
										</p>
										<p><b>
												<u>Please take your response in the section to approximately
												500 words for response.</u>
											</b></p>
									</Box>
									<Box>
										<Box>
											<h4><b>
											What were the key issues/challenges faced for which an innovative initiative was required? 
											</b></h4>	
											<p>
											[Key pointers –Integration of HR analytics, alignment of HR strategy, contribution of human capital, employee productivity levels, employee turnover, employee absenteeism etc.]
											</p>											
											<TextField
												fullWidth
												value={this.state.challengeIssues}
												variant='outlined'
												onChange={(e) =>
													this.setState({ challengeIssues: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											Describe the initiatives that emerged to address the above-mentioned issues/challenges and their key objectives
											</b></h4>	
											<p>[Key pointers –HR dashboard set-up, applying HR technology, integrating Human Capital analytics, educating stakeholders on HR analytics, measurement of employee productivity and efficiency etc.]</p>											
											<TextField
												fullWidth
												value={this.state.emergedInitiatives}
												variant='outlined'
												onChange={(e) =>
													this.setState({ emergedInitiatives: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											How did you assess the effectiveness of the initiatives undertaken and what were the results?
											</b></h4>
											<p>
											[Key pointers –ROI on Human capital, cost per hire, comparison with industry benchmarks, profit per employee, rate of customer satisfaction, rate of product development, change in quality levels etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.effectivenessInitiatives}
												variant='outlined'
												onChange={(e) =>
													this.setState({ effectivenessInitiatives: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											What impact have your initiatives had on your key stakeholders?
											</b></h4>
											<p>
											[Key pointers –Enhanced organizational performance, improved competency levels, developed HR analytics orientation, leadership effectiveness, employee satisfaction etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.keyStakeholders}
												variant='outlined'
												onChange={(e) =>
													this.setState({ keyStakeholders: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											What strategic level changes are envisioned, in the near future, to make the HR analytics function in your organization more effective?
											</b></h4>
											<p>
											[Key Pointers – Financial and people investments, adaptation of HR analytics, updating of HR systems, HR analytics training programs, dynamic HR policy etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.keyFactors}
												variant='outlined'
												onChange={(e) =>
													this.setState({ keyFactors: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											Please provide an action plan with suggested timelines and metrics to implement the strategy outlined above.
											</b></h4>
											
											<TextField
												fullWidth
												value={this.state.communityInitiative}
												variant='outlined'
												onChange={(e) =>
													this.setState({ communityInitiative: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											Please share details of any other HR Analytics efforts / initiatives designed to address needs that emerged during the COVID – 19 pandemic.
											</b></h4>
											<TextField
												fullWidth
												value={this.state.covid}
												variant='outlined'
												onChange={(e) =>
													this.setState({ covid: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
												Please use the space below to add any further details/information
											</b></h4>
											<TextField
												fullWidth
												value={this.state.furtherDetails}
												variant='outlined'
												onChange={(e) =>
													this.setState({ furtherDetails: e.target.value })
												}
											/>
										</Box>
									</Box>
									<Box>
										<p>
											<i>
												Note: You may choose to attach supporting documents of
												maximum 15 pages/slides for a particular award category.
												These collaterals will be accepted only in the form of
												PPT, PDF or Word documents. No excel format, links
												hyperlinked in text will be accepted. Additionally,
												applicants may also submit a video of up to 5 minute to
												support their application. This video needs to talk
												specifically about the intervention/practice been
												showcased and should not be genetic one.{' '}
											</i>
										</p>
									</Box>
									<Grid container spacing={3}>
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"left"}}>
										<Button size='large' variant='contained' id='print' onClick={()=>{ window.history.back() }} style={{ backgroundColor: '#e6257e',
																color: '#fff',
																background: "white",
																marginLeft: '10px',
																marginTop: '15px',
																cursor:'pointer'
															}}> <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
										</Grid>
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4}  style={{textAlign:"center"}}>
												<Button onClick={()=>{ this.handleSaveDraftSecC() }} size='large' variant='contained' id='print' style={{
																				backgroundColor: '#e6257e',
																				color: '#fff',
																				marginRight: '10px',
																				marginTop: '15px',
																				cursor:'pointer'
																			}}> Save as Draft </Button>
										</Grid>
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"right"}}>
											<Button 
													onClick = {this.submitApplicationForm} 
													size='large'
													variant='contained' 
													id='print' 
													style={{
															backgroundColor: '#e6257e',
															color: '#fff',
															marginRight: '10px',
															marginTop: '15px',
															cursor:'pointer'
													}}> Submit </Button>
										</Grid>
									</Grid>
										
									</Box>
								</CardContent>
							</Card>
						</div>
						<CustomFooter/>
						{/* <footer
							className={styles.footer}
							style={{ textAlign: 'center', backgroundColor: 'rgb(25,16,117)' }}
						>
							<div style={{ justifyContent: 'center', alignItems: 'center' }}>
								<div
									style={{
										marginTop: '30px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											width: '40px',
											textAlign: 'center',
										}}
									>
										<img
											src={facebookIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={twitterIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={linkdlnIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={youtubeIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
								</div>
								<div>
									<p
										style={{
											color: 'white',
											marginTop: '20px',
											marginBottom: '20px',
										}}
									>
										SHRM India | email: example@email.com | Phone: (+880192923333) |
										Privacy policy
									</p>
								</div>
								<div>
									<p
										style={{
											color: 'rgb(238,237,240)',
											marginTop: '20px',
											marginBottom: '20px',
										}}
									>
										{' '}
										@2021 SHRM india. All rights reserved.
									</p>
								</div>
							</div>
						</footer> */}
					</>
				);
        
  }
}
export default withStyles(styles)(HRAnalyticSectionC);
// Customizable Area End
