// Customizable Area Start
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {Redirect} from 'react-router-dom';
import { AutoComplete, Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import {User} from './models/User.web';
import { SuccessToast } from './Toast';
const AutoCompleteOption = AutoComplete.Option;
const {Option} = Select;


class LoginSuccessfulDemoDashboard extends React.Component {
    constructor(props: any) {
        super(props);
    }
    

    render(): React.ReactNode {
        return(
            <>
          
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '150vh', width: "500px", margin:'50px auto'}}>                
                <Card>
                    <h1 style={{color: "green"}}>
                        Login successful 
                    </h1>
                    <h1 style={{color: "blue"}}>
                        Dashboard will be here. 
                    </h1>
                </Card>
            </div>
            </>
          
        );
    }
}
// Customizable Area End
// }
export default LoginSuccessfulDemoDashboard;