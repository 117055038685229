// Customizable Area Start
//@ts-nocheck
import React from 'react';
import {Redirect} from 'react-router-dom';
import { Button, Card, Col, Form, Input, Modal, Row, Select,FormInstance, Spin } from 'antd';
import EmailAccountLoginController from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import CustomFooter from '../../../components/src/Footer.web';
import AppHeaderWeb from '../../../components/src/AppHeaderWeb.web';
const {Option} = Select;
import {Link} from 'react-router-dom';
import './EmailAccountLoginBlock.web.css'


const userRole = [                                    
    "Participating Organization",    
    "Jury Member",
]


class EmailAccountLoginBlock extends EmailAccountLoginController {
    constructor(props: any) {
        super(props);
    }

    formRef = React.createRef<FormInstance>();
    buttonGroup = (
        <div id="btn-group" style = {{display: 'flex', justifyContent:'center', background: '#EEEEEE',borderRadius: '18px'}}>
                
                <Link to="/">
                <Button id="reg" style={{width: '250px',fontSize:'16px', backgroundColor: 'transparent',border: 'none',color:'black'}}
                    type = { this.state.redirectToLogin ? 'primary' : 'default' }
                    onClick = {() =>  this.setState({onRegistrationPage: true}) }>
                        Register
                </Button>
                </Link>
                {
                    this.state.loadingLogin == true ? <Spin size="large" /> :
                <Button id="login" style={{width: '250px',fontSize:'16px', backgroundColor: '#161362', border: 'transparent',borderRadius: '20px',}}
                    type = { this.state.redirectToLogin ? 'default' : 'primary' }
                    onClick = { () =>  this.setState({onRegistrationPage: false}) }
                >Login</Button>
}
            </div>
    )


    render(): React.ReactNode {



        if(this.state.redirectToDashboard){
            return(
            <Redirect to = '/organization' />
            );
        }

        if(this.state.redirectToJuryDashboard){
            return(
            <Redirect to = '/jury-member-dashboard' />
            );
        }

        if(this.state.redirectToLeaderboard){
            return(
            <Redirect to = '/consolidate_leaderboard' />
            );
        }

        return(
            <>
            <AppHeaderWeb navigation id = ''  match = '' />
            <div   
                style={{   
                display: 'flex',     
                justifyContent:'center',     
                alignItems:'center' ,
                marginTop: '30px',
                }}>
                    {this.buttonGroup}
            </div>
            <div  className='login-page-form'>                
                
                <Card>
                    <Form
                    layout="vertical"
                     initialValues={{}}
                     onFinish={this.onLogin}
                         className="form"
                    >
                     <Row gutter={16}>
                        <Col className="gutter-row" span={24}>

                        
                            
                            <Form.Item
                                        label="User id"
                                        name="user_name"                                       
                                        id="user-name"
                                        rules={[
                                        {pattern: new RegExp(/^[a-zA-Z0-9\s]*$/),message: "No special characters allowed"},
                                        {pattern: new RegExp(/^[\S]*$/),message: "No spaces allowed"},
                                        {required: true,message: "Please enter user id"}
                                    ]}
                                    >
                                    <Input />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            
                            <Form.Item
                                        label="Password"
                                        name="password"
                                                                               
                                        id="password"
                                        rules={[
                                            {pattern: new RegExp(/^[\S]*$/),message: "No spaces allowed"},
                                            {required: true, message: "Please enter password"}
                                        ]}
                                    >
                                   <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                       
                                <Button 
                                    htmlType="submit"
                                    type="primary" 
                                    style={{ 
                                    background: "#f00768", 
                                    borderColor: "#f00768", 
                                    borderRadius: '5px'}}
                                >
                                    Login
                                </Button>
                          
                        </Col>
                        </Row>
    
                </Form>
                <div style={{marginLeft: '75%'}}>
                    <Button type="text" id="forgot-password"  style={{color: '#f00768', background:'transparent'}} onClick={() => this.forgetPasswordModal()}>Forgot password ?</Button>
                </div>
               
                </Card>
            </div>
                <Modal maskClosable={false}
                    destroyOnClose={true}
                    title={<h2>Enter the registered email</h2>} 
                    visible={this.state.visibleForForgetPassword} 
                    onCancel={this.forgetPasswordFormHandleCancel}
                    footer={<p style={{textAlign: "center"}} >Please reach out to <a href="mailto:SHRMIAwards@shrm.org">SHRMIAwards@shrm.org</a> for any assistance.</p>}
                   
                    >

                    <Card>
                    <Form
                                layout="vertical"
                                initialValues={{}}
                                onFinish={this.onFinishForgetPassword}
                                className="form"
                                ref={this.formRef}
                                >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {required: true, message: "Please enter your registered email"},
                                                {
                                                    type: 'email',
                                                    message: 'Please enter a valid email address.',
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Item label="User role"     name="role" rules={[
                                                {required: true, message: "Please select your role"}
                                        ]}>

                                        <Select
                                            showSearch
                                            style={{ width: 200 }}
                                            placeholder="Select your role"
                                            optionFilterProp="children"
                                            onChange={this.handleUserRoleChange}
                                            
                                        >
                                            {
                                                userRole.map( (e, idx) => (
                                                    <Option value={e} key = {idx}>{e}</Option>
                                                ) )
                                            }
                                        </Select>
                                        </Form.Item>
                                    </Col>
                                <Col xs={24}>
                                            <Button 
                                                htmlType="submit"
                                                type="primary" 
                                                style={{ 
                                                background: "#f00768", 
                                                borderColor: "#f00768", 
                                                borderRadius: '5px'}}
                                            >
                                                Yes
                                            </Button>
                                    </Col>
                                    </Row>
                
                            </Form>
                    </Card>
                    <br/>
            
            
            </Modal>
            {/* @ts-ignore */}
            <CustomFooter />
            </>
          
        );
    }
}
export default EmailAccountLoginBlock;
// Customizable Area End