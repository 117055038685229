//@ts-ignore
//@ts-nocheck
import React from 'react';
import { 
    Card,
    Box,
    TextField,
    Button, 
    CardContent
} from '@material-ui/core';
// Customizable Area Start
import {StyleSheet} from 'react-native';

import { Link } from 'react-router-dom';
import CustomformController, {Props} from "../CustomformController";
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import CustomFooter from '../../../../components/src/Footer.web';
import {Redirect} from 'react-router-dom';
import OrgHeaderWeb from '../OrgHeader.web';

const styles = {
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
        width:'50%'
    },
    cardContent: {
        padding:'16px',
     },
    textField: {
        width: 'inherit',
        height: 'inherit',
        border: 'none'
    },
    innerTextField: {
        marginBottom: 10,
    },
    formControl: {
        margin: 5,
    },
    table: {
        minWidth: 650,
        border: '0.1px solid #D3D3D3',
        borderRadius: 3,
        marginTop: 10,
        marginBottom: 10
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',

    },

    tableHead: {
        fontWeight: 600,
        borderRight: '0.1px solid #D3D3D3',
        backgroundColor: '#DDDDDD'
    },
    buttons: {
        display: 'flex',
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
};
    // Customizable Area End
 class LearningDevelopmentSectionC extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render(){
        // Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0 ]
		if(this.state.redirectToOrganization){
            return  <Redirect to = '/organization'/>   
          }

        return (
			<div div id='content'>
				<OrgHeaderWeb/>
				<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
			{/* <div className="makeStyles-root-1">
			   <header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{background:'black', height:'200px', justifyContent: 'center'}}>
				   <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
					   <h6 className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
						   <img src={srmLogo} alt="companyName"  style={{ height:"50px", margin:"15px"}}/>
					   </h6>
				   
			   <h5>
			   <hr className="MuiDivider-root MuiDivider-flexItem MuiDivider-vertical" style={{color:"black", marginRight:"10px", marginLeft:"10px"}}/>
			   </h5>
			   
					   <h2 style={{textAlign:"center", marginTop:"10px", color:"white"}}>Application Form</h2>
					   <h1 style={{textAlign:"center",color:"white", fontWeight:"bolder", marginBottom:"15px", marginTop:"5px"}}>{this.state.customFormHeading}</h1>
					   </div>
			   </header>      
		   </div> */}



		   <div
					   style={{
						   display: 'flex',
						   justifyContent: 'center',
						   alignItems: 'center',
						   margin: '50px auto',
						   borderRadius: '20px',
						   width: '50%',
					   }}
				   >
					   <Card className={styles.container} style={{boxShadow: "none", border: '1px solid #d6d6d6'}} elevation={0}>
						   <CardContent className={styles.cardContent}>
						   <Box>
						   <Box>
										<h1 style={{ textAlign: 'center' }}>
											<u>Section C-Qualitative Measurement</u>
										</h1>
										<p><b>
										Qualitative measurements are based on initiatives that have been implemented or are ongoing in the organization. These would allow for understanding and evaluating the nature of initiatives, the issues which were addressed by these initiatives, the impact on stakeholders and efforts to sustain these initiatives.
										</b>
											
										</p>
										<p><b>
										<u>Please restrict your responses in this section to approximately 500 words per response.
										</u></b></p>
									</Box>
									<Box>
										<Box>
											<h4><b>
											What were the key issues/challenges faced for which an innovative initiative was required? 
											</b></h4>	
											<p>[Key pointers – Difficulty in long term planning, Dealing with change, senior management involvement, employee accountability, etc.]</p>											
											<TextField
												fullWidth
												value={this.state.challengeIssues}
												variant='outlined'
												onChange={(e) =>
													this.setState({ challengeIssues: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											Describe the initiatives that emerged to address the above-mentioned issues/challenges and its key objectives
											</b></h4>
											<p>
											[Key pointers – Alignment of organization values, autonomy in decision-making, employee buy-in through using consultative and inclusive processes, better communication approaches to deal with uncertainty of change, etc.]
											</p>												
											<TextField
												fullWidth
												value={this.state.emergedInitiatives}
												variant='outlined'
												onChange={(e) =>
													this.setState({ emergedInitiatives: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											How do you assess the effectiveness of your Learning & Development initiatives undertaken and what were the results? Please share relevant metrics that are tracked to evaluate effectiveness. 
											</b></h4>
											<p>
											[Key pointers – Employee satisfaction scores, client/employee feedback, manager feedback, improved skills and knowledge base/ overall performance ratings etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.effectivenessInitiatives}
												variant='outlined'
												onChange={(e) =>
													this.setState({ effectivenessInitiatives: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											What impact have your initiatives had on your key stakeholders?
											</b></h4>
											<p>
											[Key pointers – Employee engagement, employee empowerment, employee loyalty, openness to change, improved ability of to manage change, etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.keyStakeholders}
												variant='outlined'
												onChange={(e) =>
													this.setState({ keyStakeholders: e.target.value })
												}
											/>
										</Box>
										<Box>
											<h4><b>
											What are the key factors that are required for the initiatives to sustain and grow over the next few years?
											</b></h4>
											<p>
											[Key pointers – Budget allocation, team expansion, employee involvement audit, process audit and formal stakeholder feedback, formal policy on initiatives etc.]
											</p>
											<TextField
												fullWidth
												value={this.state.keyFactors}
												variant='outlined'
												onChange={(e) =>
													this.setState({ keyFactors: e.target.value })
												}
											/>
										</Box>
										
										<Box>
											<h4><b>
											Please use the space below to add any further details/information
											</b></h4>
											<TextField
												fullWidth
												value={this.state.furtherDetails}
												variant='outlined'
												onChange={(e) =>
													this.setState({ furtherDetails: e.target.value })
												}
											/>
										</Box>
									</Box>
									<Box>
										<p>
											<i>
											Note: You may choose to attach supporting documents of maximum 15 pages/slides for a particular Award category. These collaterals will be accepted only in the form of PPT, PDF or WORD documents. No excel formats, links hyperlinked in text will be accepted. Additionally, applicants may also submit a video of up to 5 min to support their application. This video needs to talk specifically about the intervention/ practice been showcased and should not be a generic one.
											</i>
										</p>
									</Box>
								   <Grid container spacing={3}>
								   <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"left"}}>
									<Button size='large' variant='contained' id='print' onClick={()=>{ window.history.back() }} style={{ backgroundColor: '#e6257e',
															color: '#fff',
															background: "white",
															marginLeft: '10px',
															marginTop: '15px',
															cursor:'pointer'
														}}> <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
								</Grid>
						   <Grid item xs={4} sm={4} md={4} lg={4} xl={4}  style={{textAlign:"center"}}>
								   <Button onClick={()=>{ this.handleSaveDraftSecC() }} size='large' variant='contained' id='print' style={{
																   backgroundColor: '#e6257e',
																   color: '#fff',
																   marginRight: '10px',
																   marginTop: '15px',
																   cursor:'pointer'
															   }}> Save as Draft </Button>
						   </Grid>
						   <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"right"}}>
							   <Button 
									onClick = {this.submitApplicationForm} 
									size='large'
									variant='contained' 
									id='print' 
									style={{
											backgroundColor: '#e6257e',
											color: '#fff',
											marginRight: '10px',
											marginTop: '15px',
											cursor:'pointer'
									}}> Submit </Button>
						   </Grid>
					   </Grid>
								   <Modal
					   open={this.state.isdownloading}
					   onClose={this.state.isdownloading}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				   >
					   <div style={{ height: '100vh', width:'100%', textAlign:'center', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
						   <Paper style={{padding:'1rem'}}>
							   <div style={{display: 'inline-flex',width:'100%', margin:'0px auto'}}>
								   <CircularProgress  style={{margin:'0px 10px'}}/>										
							   </div>
							   <p>Loading....</p>
						   </Paper>
						   </div>
				   </Modal>            
				   <Modal
					   open={this.state.isupload}
					   onClose={this.state.isupload}
					   aria-labelledby="simple-modal-title"
					   aria-describedby="simple-modal-description"
				   >
					   <div style={{ height: '100vh', width:'100%', textAlign:'center', display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',}}>
						   <Paper style={{padding:'1rem'}}>
							   <div style={{display: 'inline-flex',width:'100%', margin:'0px auto'}}>
								   <CircularProgress  style={{margin:'0px 10px'}}/>										
							   </div>
							   <p>Loading....</p>
						   </Paper>
						   </div>
				   </Modal>            

				   </Box>
						   </CardContent>
					   </Card>
		   </div>
		   <CustomFooter/>
		   
			{/* <footer className={styles.footer} style ={{textAlign: 'center', backgroundColor:'rgb(25,16,117)' }}>
			   <div style={{justifyContent: 'center', alignItems: 'center'}}>
				   <div style={{marginTop:'30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", width:"40px"}}>
						   <img src={facebookIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={twitterIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={linkdlnIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={youtubeIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
				   </div>
				   <div>
					   <p style={{color:"white", marginTop:'20px', marginBottom:'20px'}}>SHRM India | email: example@email.com | Phone: (+880192923333) | Privacy policy</p>
				   </div>
				   <div>
					   <p style={{color:"rgb(238,237,240)", marginTop:'20px', marginBottom:'20px'}}> @2021 SHRM india. All rights reserved.</p>
				   </div>
			   </div>
		   </footer> */}
	   </div>
				);
        
  }
}
export default withStyles(styles)(LearningDevelopmentSectionC);
// Customizable Area End
