//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {  Card, Box, TextField, Button, CardContent, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyleSheet} from 'react-native';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from '../CustomformController';
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import OrgHeaderWeb from '../OrgHeader.web';
import CustomFooter from '../../../../components/src/Footer.web';


const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    box: {
        width: '100%',
    },
}); 
    // Customizable Area End
	export default class ManageHybridWorkplaceSectionB extends CustomformController{
		constructor(props: Props) {
			super(props);
			// Customizable Area Start
			// Customizable Area End
		  }
	// Customizable Area Start
	
	// Customizable Area End
      render(){ 
        //Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0]
		const SectionB = this.state.applicationFormData?.payload?.SectionB;

        return (
			<div id='content'>
			<OrgHeaderWeb/>
			<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					margin: '50px auto',
					borderRadius: '20px',
					width: '50%',
				}}
			>
				<Card className={styles.container} style={{ boxShadow: "none", border: '1px solid #d6d6d6' }} elevation={0} >
					<CardContent className={styles.cardContent}>
						<Box>
							<Box>
								<h1 style={{ textAlign: 'center' }}>
									<u>Section B-Quantitative Measurement</u>
								</h1>
								<p><b>
									This section covers the quantitative aspects of the
									initiative under the concerned category. Providing all the
									information under this section will enhance your nomination
									and aid the Jury and accurate evaluation of application.
									</b>
								</p>
							</Box>
							<Box>
							<Paper style={{overflowX: "auto"}}>
								<Table className={styles.table} aria-label='simple table'>
									<TableHead className={styles.tableHead}>
										<TableRow className={styles.tableRow}>
										<TableCell style={{width: '80px'}}><b> Sr. No.</b></TableCell>
											<TableCell> <b>Metrics</b></TableCell>
											<TableCell><b> Unit</b></TableCell>
											<TableCell> <b>FY 2018-19</b></TableCell>
											<TableCell><b> FY 2019-20</b></TableCell>
											<TableCell><b> FY 2020-21</b></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow className={styles.tableRow}>
										<TableCell> 1</TableCell>
											<TableCell>
												<b>Workforce Composition</b>
											</TableCell>
											<TableCell>  </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.workforceComposition["18"]}
													onChange={(e) =>
														this.setState({
															workforceComposition: {
																...this.state.workforceComposition,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.workforceComposition["19"]}
													onChange={(e) =>
														this.setState({
															workforceComposition: {
																...this.state.workforceComposition,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.workforceComposition["20"]}
													onChange={(e) =>
														this.setState({
															workforceComposition: {
																...this.state.workforceComposition,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												1a. Total No. of employees working from home/ remotely - full time (Share as a percentage of Total FTE)
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_Remotely["18"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_Remotely: {
																...this.state.noOfEmployeesWorkingFromHome_Remotely,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_Remotely["19"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_Remotely: {
																...this.state.noOfEmployeesWorkingFromHome_Remotely,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_Remotely["20"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_Remotely: {
																...this.state.noOfEmployeesWorkingFromHome_Remotely,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>1b. Total No. of employees working from office premises - full time (Share as a percentage of Total FTE)</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromOfficePremises["18"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromOfficePremises: {
																...this.state.noOfEmployeesWorkingFromOfficePremises,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromOfficePremises["19"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromOfficePremises: {
																...this.state.noOfEmployeesWorkingFromOfficePremises,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromOfficePremises["20"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromOfficePremises: {
																...this.state.noOfEmployeesWorkingFromOfficePremises,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>1c. Total No. of employees working partly from home and partly from office premises (Share as a percentage of Total FTE)</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_OfficePremises["18"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_OfficePremises: {
																...this.state.noOfEmployeesWorkingFromHome_OfficePremises,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_OfficePremises["19"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_OfficePremises: {
																...this.state.noOfEmployeesWorkingFromHome_OfficePremises,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmployeesWorkingFromHome_OfficePremises["20"]}
													onChange={(e) =>
														this.setState({
															noOfEmployeesWorkingFromHome_OfficePremises: {
																...this.state.noOfEmployeesWorkingFromHome_OfficePremises,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> 2</TableCell>
											<TableCell>
												<b>Average working hours per week / per employee</b>
											</TableCell>
											<TableCell>Hrs.</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.avgWorkingHoursPerWeek["18"]}
													onChange={(e) =>
														this.setState({
															avgWorkingHoursPerWeek: {
																...this.state.avgWorkingHoursPerWeek,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.avgWorkingHoursPerWeek["19"]}
													onChange={(e) =>
														this.setState({
															avgWorkingHoursPerWeek: {
																...this.state.avgWorkingHoursPerWeek,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.avgWorkingHoursPerWeek["20"]}
													onChange={(e) =>
														this.setState({
															avgWorkingHoursPerWeek: {
																...this.state.avgWorkingHoursPerWeek,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												2a. Share how working hours are tracked for remote employees
											</TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.trackWorkingHours["18"]}
													onChange={(e) =>
														this.setState({
															trackWorkingHours: {
																...this.state.trackWorkingHours,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.trackWorkingHours["19"]}
													onChange={(e) =>
														this.setState({
															trackWorkingHours: {
																...this.state.trackWorkingHours,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.trackWorkingHours["20"]}
													onChange={(e) =>
														this.setState({
															trackWorkingHours: {
																...this.state.trackWorkingHours,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> 3</TableCell>
											<TableCell><b>Budget Utilization/ Expenditure:</b></TableCell>
											<TableCell> </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["18"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["19"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.budgetUtiliZation["20"]}
													onChange={(e) =>
														this.setState({
															budgetUtiliZation: {
																...this.state.budgetUtiliZation,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell> 3a. Amount spent on Technology Upgrade -Software requirements</TableCell>
											<TableCell>in INR</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeSoftware["18"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeSoftware: {
																...this.state.amountUpgradeSoftware,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeSoftware["19"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeSoftware: {
																...this.state.amountUpgradeSoftware,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeSoftware["20"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeSoftware: {
																...this.state.amountUpgradeSoftware,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> </TableCell>
											<TableCell>
												3b. Amount spent on Technology Upgrade – Hardware requirements
											</TableCell>
											<TableCell>in INR</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeHardware["18"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeHardware: {
																...this.state.amountUpgradeHardware,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeHardware["19"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeHardware: {
																...this.state.amountUpgradeHardware,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountUpgradeHardware["20"]}
													onChange={(e) =>
														this.setState({
															amountUpgradeHardware: {
																...this.state.amountUpgradeHardware,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>

										<TableRow>
										<TableCell> </TableCell>
											<TableCell>3c. Amount spent on Skills Upgradation</TableCell>
											<TableCell> in INR </TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountSkillsUpgradation["18"]}
													onChange={(e) =>
														this.setState({
															amountSkillsUpgradation: {
																...this.state.amountSkillsUpgradation,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountSkillsUpgradation["19"]}
													onChange={(e) =>
														this.setState({
															amountSkillsUpgradation: {
																...this.state.amountSkillsUpgradation,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.amountSkillsUpgradation["20"]}
													onChange={(e) =>
														this.setState({
															amountSkillsUpgradation: {
																...this.state.amountSkillsUpgradation,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> 4</TableCell>
											<TableCell><b>Total No. of skills / developmental training man-days (specific to managing virtual/ hybrid transitions)</b></TableCell>
											<TableCell>No. of Man days</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["18"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["19"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.noOfEmp["20"]}
													onChange={(e) =>
														this.setState({
															noOfEmp: {
																...this.state.noOfEmp,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
										<TableCell> 5</TableCell>
											<TableCell>
												<b>Engagement metrics (please specify)</b>
											</TableCell>
											<TableCell>Specify measure being used</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.engagementMetrics["18"]}
													onChange={(e) =>
														this.setState({
															engagementMetrics: {
																...this.state.engagementMetrics,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.engagementMetrics["19"]}
													onChange={(e) =>
														this.setState({
															engagementMetrics: {
																...this.state.engagementMetrics,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField type="number"
													fullWidth
													value={this.state.engagementMetrics["20"]}
													onChange={(e) =>
														this.setState({
															engagementMetrics: {
																...this.state.engagementMetrics,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>


									</TableBody>
								</Table>
								</Paper>
							</Box>
						</Box>
						<Grid container spacing={3}>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
								<Button size='large' variant='contained' id='print' onClick={() => { window.history.back() }} style={{
									backgroundColor: 'white',
									color: '#fff',
									marginLeft: '10px',
									marginTop: '15px',
									cursor: 'pointer'
								}}> <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "center" }}>
							<Button
								size='large'
								variant='contained'
								size='large' variant='contained' id='print' style={{
									backgroundColor: '#e6257e',
									color: '#fff',
									marginRight: '10px',
									marginTop: '15px',
									cursor: 'pointer',
								}}
								onClick={this.handleSaveDraftSecB}
								>SAVE AS DRAFT
								</Button>
								</Grid>
								<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
									<Link to='/hybrid_workplace_c'>
										<Button
											onClick={this.handleSaveDraftSecB}
											size='large'
											variant='contained'
											style={{
												backgroundColor: '#e6257e',
												color: '#fff',
												marginLeft: '10px',
												marginTop: '15px',
											}}
										>
											{' '}
										NEXT
								        </Button>
									</Link>
									</Grid>
						</Grid>

					</CardContent>
				</Card>
			</div>


			<CustomFooter/>
		</div>
				);
        //Customizable Area End
      }
}
