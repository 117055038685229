import React from "react";
import { View, Button, StyleSheet } from "react-native";
import { withRouter } from "react-router-dom";

const TopNav = ({ history }) => {
  history.listen(() => {
    window.scrollTo(0, 0);
  });
  return (
    <View style={styles.main}>
    </View>
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default withRouter(TopNav);
