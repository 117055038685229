// Customizable Area Start
// @ts-nocheck
import React from 'react';
import { Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, Spin, Table, DatePicker } from 'antd';
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import CustomFooter from '../../../components/src/Footer.web';
import zoom from '../assets/zoom.png';
import  uploadIcon1  from '../assets/uploadIcon.png';
import { Link} from 'react-router-dom';
import moment from 'moment';
import OrgHeaderWeb from '../../customform/src/OrgHeader.web';
import FilePreviewModal from '../../../components/src/FilePreviewModal';
import black_edit from '../assets/black_edit.jpg';

class JuryMemberDashboard extends EmailAccountRegistrationController {
    constructor(props: any) {
        super(props);
    }
    getStatusL2(e: any){
      return e.score_status == 'Evaluated' ? <h3 style={{color: "0cc75d"}}>Evaluated</h3> : <h3 style={{fontSize: "16px"}}>Pending</h3>;
    }
    getStatusL3(e: any){
      return e.score_status == 'Evaluated' ? <h3 style={{color: "0cc75d"}}>Evaluated</h3> : <h3 style={{fontSize: "16px"}}>Pending</h3>;
    }
    async componentDidMount(){
      const role = localStorage.getItem("role");
      if (role == 'organization') {
        this.props.navigation.navigate("Org");
        return;
      }
      this.getAllCategories();
      this.getAllCategoryPrices(this.state.selectedCategories.length);
      this.getTermsAndConditions();
      this.getNDAForm();
      await this.getAllCategories();    
      try{
        if(this.props?.navigation?.getParam('id')){
            await this.getUserCategory()
          }
      }catch(e){
      }
    }

    renderSetScore(e: any) {
      return (e.type == 'organization' && e.attributes.score == null)
        ? <Button type="text" disabled={!this.state.enable_set_score && e.type == 'level_organization'} style={{color: '#f00768',fontSize:"16px"}} onClick={() => this.showSetScoreModal(e, false)}>Set score</Button> : 
        (
        <div style={{display: 'flex',alignItems:'center',padding:'0px 16px'}}>
          <span style={{color: "#f00768", margin:"0px"}}>{e.type == 'organization' ? e.attributes.score : e.attributes.score_l3 }</span> &nbsp;
          {!e.attributes.score_freezed && e.attributes.score != "N/A" && <button style={{border: 'none', background: 'white'}} disabled={e.attributes.score_freezed || e.attributes.score == "N/A"}  id="score-btn" onClick={(event: any) => {
            this.setScoreUpdating(true);
            if(e.type == 'organization'){
              this.setScoreData({
                id: e.id,
               initiative_score: e.attributes.initiative_score,
               stakeholders_score: e.attributes.stakeholders_score,
               scalibility_score: e.attributes.scalibility_score,
               organiztion_id: e.id,
               user_category_id: e.attributes.user_category.data.id,
               feedback: e.attributes.feedback
              })
            }else{
              this.setScoreData({
                id: e.id,
               initiative_score: e.attributes.initiative_score_l3,
               stakeholders_score: e.attributes.stakeholders_score_l3,
               scalibility_score: e.attributes.scalibility_score_l3,
               organiztion_id: e.id,
               user_category_id: e.attributes.user_category.data.id,
               feedback: e.attributes.feedback_l3
              })
            }
            this.showSetScoreModal(e, true)
          }}><img src={black_edit} style={{height: '22px'}} alt = "edit" /> 
          </button>
      }
        </div>
        )
    }
   

    render(): React.ReactNode {
      const columns = [{
        title: "Organization name",
        width: 300,
        render: (e: any) =>e.type == 'level_organization' ? e.attributes.organization.organization_name : e.attributes.organization_name
      },
      
       {
        title: 'Application form',
        render:(e: any) => <a style={{color: '#f00768'}} onClick={() => this.showModal(e.attributes.user_category.data.attributes.id)}>Review</a>,
         width: 100
      }, 
      {
        title: 'Supporting documents',
        render:(e: any) => <a style={{color: '#f00768'}} onClick={() => this.showDocumentModal(e.attributes.user_category.data.attributes.id)}>Review</a>,
         width: 100
      },
      {
        title: 'Set score',
        render:(e: any) =>  this.renderSetScore(e) ,
         width: 100 
      },
      {
        title: 'Status',
         width: 100,
        render: (e: any) => e.type == 'level_organization' ? this.getStatusL3(e.attributes) : this.getStatusL2(e.attributes)
      },
      ];
     
    
        return(
            <>
            {/* @ts-ignore */}
            <OrgHeaderWeb/>
            <div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px',display:'flex', justifyContent: 'center', alignItems:'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h1 style={{ textAlign: "center",   color: "white", fontWeight: "bolder",  }}>Jury Member Dashboard</h1>
						</div>
					</header>
				</div> 
           
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', width: "100%", margin:'50px auto', flex:1, flexDirection: "column"}}>                
              {!this.state.categoryLoading && !this.state.allCategories.length &&
                <div style={{ color: 'rgba(0, 0, 0, 0.85)', textAlign: "center", alignItems: "center", marginBottom: "1rem"}}><span >Panelist <span style={{ fontWeight: 600 }}>{`${localStorage.getItem('display_name')}`}</span> is not associated with any Category. Please contact <a href="mailto:SHRMIAwards@shrm.org" >SHRMIAwards@shrm.org</a> for further assistance.</span>
                </div>
              }       
                <Card>
                  
                  <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative' }}>
                    <Col xs={8} style={{ paddingTop: '20px' }}>
                      <p>Select categories</p>
                      <Form.Item>

                        <Select
                          showSearch
                          style={{ width: 350 }}
                          placeholder="Select a type"
                          optionFilterProp="children"
                          onChange={(e) => this.getOrganizationList(e)}
                          value={this.state?.defaultCatName}

                        >     {this.state.allCategories.map((e) => <Select.Option key={e.attributes.id} value={e.attributes.id}>{e.attributes.name}</Select.Option>)}


                        </Select>
                      </Form.Item>
                    </Col>
                    <div>

                      <Button
                        id="eval-param"
                        type="primary"
                        size='middle'
                        onClick={(ev) => this.handleOk(ev)}
                        style={{
                          background: "#ffb340",
                          borderColor: "#ffb340",
                          borderRadius: '7px',
                          width: '200px',
                          height: '35px',
                        }}><Link to='/evaluation-parameter'>
                          <b>Evaluation parameters</b></Link>
                      </Button>

                      &nbsp;&nbsp;
                      <Button
                        id="leaderboardBtn"
                        type="primary"
                        size='middle'
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleOk(e);
                          this.handelLeaderboard(false)
                        }}
                        style={{
                          background: "#ffb340",
                          borderColor: "#ffb340",
                          borderRadius: '7px',
                          width: '150px',
                          height: '35px',
                        }}>
                        <b>Leaderboard</b>
                      </Button>
                      {/* </Link> */}
                      &nbsp;&nbsp;


                    </div>

                  </div>
                 
                    <Table id="table"
                      columns={columns}
                      dataSource={this.state.organizationList}
                      bordered
                      loading={this.state.organizationLoading}

                    />
                 

                  {/* <div style={{ position: 'absolute', right: "-75px", bottom: '15px' }}>
                    {
                      this.state.organizationList && this.state.organizationList.length > 0 &&
                      <a id="zoom" style={{ color: '#f00768' }} onClick={() => this.showZoomFormModal()}>
                        <img src={zoom} style={{ height: '60px', width: "60px" }} />
                      </a>
                    }

                  </div> */}
                </Card>
              
            </div>
            
          <Modal
          id="app-modal"
             destroyOnClose={true}
             visible={this.state.visible} 
             onCancel={this.handleCancel}
             footer={null}
             title={<h2>Application form</h2>}
             className="feedback_modal"
            >
 
            <Card>
              {this.state.media ?  <iframe src={this.state.media} 
              style={{width: '100%',height: '500px',border: 'none'}}></iframe> : 
              <h4>Application has not uploaded yet.</h4>
              }
            </Card>
            <br/>
            <br/>
            <Form layout="vertical"  initialValues={{}} onFinish={this.feedBackForm} >  
              <Col xs={24}>                       
                <Form.Item className='feedback-label'
                      label={<h1>Feedback</h1>}
                      name="feedback"
                  >
                    <Input.TextArea defaultValue={this.state.applicationFormFeedBack} name={this.state.applicationFormFeedBack} onChange = { e => this.changeFeedback(e, 'feedback')}
                                showCount maxLength={200} placeholder= "Write your feedback"/>
                  </Form.Item>
              </Col>
              <Col xs={24}>
                  <Button 
                      htmlType="submit"
                      type="primary" 
                      style={{ 
                      background: "#f00768", 
                      borderColor: "#f00768", 
                      borderRadius: '5px',
                    marginRight:"5px"}}
                  >SUBMIT
                  </Button>
                  <Button 
                      onClick={this.handleCancel}
                      style={{ 
                      color: "#f00768", 
                      borderColor: "#f00768", 
                      borderRadius: '5px'}}
                  >CANCEL
                  </Button>
              </Col>
            </Form>
          </Modal>
              
        <Modal
        id="support-modal"
          visible={this.state.visibleTwo}
          onOk={this.handleOkDocument}
          onCancel={this.handleCancelDocument}
          footer={[
            <Button 
              key="submit" 
              type="primary" 
              size="large" 
              onClick={this.handleOkDocument} 
              style={{
                background: "#f00768", 
                borderColor: "#f00768", 
                borderRadius: '5px'
              }}>
              OK
            </Button>,
          ]}
        >
           <h1 className='modal-heading'>Supporting documents</h1>
          
            <div >
            <ul className='upload-doc-list'>
              {this.state.document_urls && this.state.document_urls.length > 0 && this.state.document_urls.map((e: any) => e.file_url ?
                      <> 
                        <li style={{display:'flex',justifyContent:'start', alignItems:'center'}}>  
                          <a id="support-view" onClick={() => this.handleViewDocument(e)} style={{ textDecoration: 'none', }}> 
                            <div style={{display:'flex' ,padding:'10px 0px',}}>
                              <img src={uploadIcon1} className="modal-icon-size" alt="icon" />
                            </div>
                          </a>
                          <span style={{width:'150px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{e.filename}   
                          </span>  
                        </li> 
                      </>: <h4>No documents uploaded yet.</h4>
                  )}
              </ul>
          </div>
            
                 
         <br/>
        </Modal>
        <Modal id="nodoc-modal"
            onCancel={this.handleCancelThree}
            visible={this.state.juryDocNotUploaded}            
            title={null}
            footer={null}
            className="jury_no_doc_modal"
        >
              <div className="">               
                  <h2 style={{textAlign:'center', fontWeight:'bolder'}}>No Documents!!</h2>
                  <p style={{textAlign:'center'}}>There are no supporting documents uploaded.</p>
              </div>
        </Modal>
        <Modal
        id="score-modal"
          title={<h1>Set the score</h1>}
          visible={this.state.visibleThree}
          onOk={this.handleOkThree}
          onCancel={this.handleCancelThree}
          footer={null}
        >

        <Card>
            <Form 
                  layout="vertical"
                  onFinish={this.onSubmitSetScore}
                  initialValues={this.state.scoreData}
                        className="form"
                  >
                     <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            
                      <Form.Item
                       
                        label={<div><h3>Innovative in the initiative score (+40 Max) <span style={{ color: "red", fontSize: "14px" }}>*</span></h3>
                          <p>
                             &#x2022; This parameter evaluates the originality in the said HR initiative/process. An innovative practice or
                            system is one, which is highly unusual, creative and unique to the company's culture and overall
                            strategy.
                          </p>
                          <p>
                             &#x2022; This encompasses innovation in the design, delivery mechanisms, implementation and tracking of
                              the system/process. It also includes original communication campaigns and design of metrics
                              around it.
                          </p> 
                        </div>}
                        rules={[
                          { required: true, message: "Please enter initiative score" }
                        
                        ]}
                      >

                      <Input  type="number" min={1} max={40} value={this.state.scoreData.initiative_score } required  onChange = { e => this.changeScoreData(e, 'initiative_score')}/>
                      </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            
                      <Form.Item
                        
                        label={
                          <div>
                            <h3>Impact on stakeholders score (+40 Max)<span style={{ color: "red", fontSize: "14px" }}>*</span></h3> 
                            <p>
                             &#x2022; This parameter evaluates the business and strategic impact of the said HR initiative. An impactful
                              system/ process is one, which involves a variety of stakeholders in its design and implementation
                              and is of immense satisfaction, usage and value to the target audience.

                          </p>
                          <p>
                             &#x2022; Recognition of the system/process in external and internal forums and tracking of metrics
                            showcasing its success are also encompassed in this parameter.

                          </p>
                          </div>
                        }
                        rules={[
                          { required: true, message: "Please enter stakeholders score" }
                        ]}
                      >

                      <Input type="number" min={1} max={40} required value={this.state.scoreData.stakeholders_score } onChange = { e => this.changeScoreData(e, 'stakeholders_score')}/>
                      </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            
                      <Form.Item 
                       
                        label={<div><h3>Sustainability &amp; scalibility score (+20 Max)<span style={{ color: "red", fontSize: "14px" }}>*</span></h3>
                         <p>
                             &#x2022; This parameter talks of the routine and consistency in the said HR initiative. A sustainable and
                            scalable system/process is one, which has detailed norms and guidelines attached to it and
                            improves itself basis feedback and audits.

                          </p>
                          <p>
                             &#x2022; Financial, people, organization culture or infrastructure related investments and changes as well
                            as communication and branding of the system/process contribute in making it sustainable and
                            scalable.

                          </p>
                        </div>}
                        rules={[
                          { required: true, message: "Please enter sustainability & scalibility score" }
                        ]}
                      >

                      <Input type="number" min={1} max='20' required value={ this.state.scoreData.scalibility_score } onChange = { e => this.changeScoreData(e, 'scalibility_score')}/>
                      </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={24}>
                          <br/>
                          <Form.Item rules={[{ required: true, message: 'Please enter feedback' }]}
                                label={<h1>Feedback<span style={{ color: "red"}}>*</span></h1>}
                              
                            >
                                <Input.TextArea 
                                value={this.state.scoreData.feedback} onChange = { e => this.changeScoreData(e, 'feedback')}
                                showCount maxLength={200} required placeholder= "Write your feedback" />
                          </Form.Item>
                              </Col>
                        
                              <Col xs={24}>
                                <Row>
                                <Button 
                                    htmlType="submit"
                                    type="primary" 
                                    style={{ 
                                    background: "#f00768", 
                                    borderColor: "#f00768", 
                                    borderRadius: '5px'}}
                                >
                                    SUBMIT
                                </Button> &nbsp;&nbsp;
                                <Button 
                                  key="back" 
                                  onClick={this.handleCancelThree}
                                  style={{
                                    color: "#f00768", 
                                    borderColor: "#f00768", 
                                    borderRadius: '5px'
                                  }}
                                  >
                                  CANCEL</Button>,
                                </Row>
                              </Col>
        
                        </Row>
    
                </Form>
          </Card>
          <br/>
         
         
        </Modal>

        <Modal
          id="zoomModal"
          title={<h2 style={{fontWeight:700}}>Zoom meeting</h2>}
          visible={this.state.visibleForZoomForm}
          onCancel={this.zoomMeetingFormHandleCancel}
          footer={null}
        >

        <Card>
        <Form
                    layout="vertical"
                     initialValues={{}}
                         className="form"
                    >
                     <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            <h3>Do you want to join the meeting ?</h3>
                           
                        </Col>
                      
                       <Col xs={24}>
                         {this.state.zoomMeetingLoading == true ? <Spin size="large" /> : 
                            <>
                            <Button 
                            htmlType="submit"
                            type="primary" 
                            style={{ 
                            background: "#10c706", 
                            borderColor: "#10c706", 
                            borderRadius: '5px'}}
                            onClick={this.onFinishForZoom}
                        >
                            Yes
                        </Button>
                        <Button 
                         htmlType="submit"
                         type="primary" 
                         style={{ 
                         background: "#10c706", 
                         borderColor: "#10c706", 
                         borderRadius: '5px',
                        marginLeft:'10px'}}
                         onClick={this.zoomMeetingFormHandleCancel}
                     >
                         Cancel
                     </Button>
                         
                      </>
                         }
                              
                        </Col>
                        </Row>
    
                </Form>
                
          </Card>
          <br/>
         
         
        </Modal>

        <Modal
          id="zoomUrlModal"
          visible={this.state.visibleForZoomMeeting}
          onCancel={this.zoomMeetingHandleCancel}
          footer={null}
        >
          <h1 className='modal-heading'>Zoom Meeting Details</h1>
            <h2 style={{fontWeight:600}}>{this.state.topic}</h2>
            <h3>{moment(this.state.start_date).format('dddd, MMMM D - h:mm:ssa')}</h3>
            <div style={{backgroundColor: "785fe8",minHeight: "100px", padding: "10px", borderRadius: "10px"}}>
              <a style={{color: "white", fontWeight:600}} href = {this.state.zoom_id} target="_blank"><span>Meeting URL: </span> {this.state.zoom_id}</a>              
              {this.state.note ? <h4 style={{color: "white",fontWeight:600}}>Note: {this.state.note}</h4> : <></>}

            </div>
            
            <br/>
        
         <br/>
        </Modal>
                    {/* @ts-ignore */}
            <CustomFooter />

            <Modal
            id="ndaModal"
                    title={<h2>NDA form</h2>}
                    visible = {this.state.showNDAForm}
                    closable={false}

                    footer={[
                    <Button 
                        key="submit" 
                        type="primary" 
                        size="large" 
                        onClick = {()=>this.submitNDAForm()}
                        style={{
                        background: "#f00768", 
                        borderColor: "#f00768", 
                        borderRadius: '5px'
                        }}>
                        SUBMIT
                    </Button>,
                    ]}
                    >

                    <Card>
                     <iframe src={this.state.document}
                    style={{width: '100%',height: '350px',border: 'none'}}></iframe>
                    {/* <h4>{this.state.document}</h4> */}
                    </Card>
                    <br/>
                    <br/>
                    <div style={{display: 'flex'}}>
                    <Checkbox 
                    value = {this.state.acceptTermsOfNDAForm}
                    onChange = { e => this.setState({acceptTermsOfNDAForm: e.target.checked})}
                    />
                    &nbsp;&nbsp;
                    <p>I hereby accept the&nbsp;
                      <a id="tc-anchor" style={{color: '#f00768'}} onClick={() => this.showTnCModal()}><b style={{color: "#f00768"}}>Terms and Conditions</b></a>&nbsp;of SHRM Award
                      
                    </p>
                    </div>
                </Modal>
                <Modal
                    title={<h1 style={{ fontWeight:700 ,padding:'10px', textAlign: "center"}}>Terms and Conditions</h1>}
                    visible={this.state.visibleForTnC}
                    onCancel={this.tncHandleCancel}
                    footer={null}
                    width={1000}
                >
                    <div style={{width: "100%", padding: '20px'}}>
                        <div  dangerouslySetInnerHTML={{__html: this.state.terms_and_conditions}}></div>
                    </div>
                </Modal>

            <FilePreviewModal viewDocument={this.state.viewDocument}
              handleCloseViewDocument={this.handleCloseViewDocument}
              viewDocumentType={this.state.viewDocumentType}
              viewDocumentUrl={this.state.viewDocumentUrl}
              viewDocumentName={this.state.viewDocumentName}
            />
            

            <Modal
              id="zoom-form-modal"
              visible={this.state.visibleZoomForm}
              footer={null}
              onCancel={() => this.handleFormClose()}
              title={<h2>Setup zoom meeting</h2>}
            >
              <Form layout="vertical" onFinish={this.onFinishForZoom} requiredMark={false}
              >
                <Form.Item
                  label={<label>Start Time<span style={{ color: "red", fontSize: "14px" }}>*</span></label>}
                  name="start_date"
                  rules={[{ required: true, message: 'Please input start time!' }]}
                >
                  <DatePicker disabledDate={(current) => {
                    return current && current < moment().startOf('day');
                  }}
                style={{width: "100%"}} showTime placeholder="Start Time" />
                </Form.Item>
                <Form.Item
                  label={<label>Topic<span style={{ color: "red", fontSize: "14px" }}>*</span></label>}
                  name="topic"
                  rules={[{ required: true, message: 'Please input topic!' }]}
                >
                  <Input placeholder="Topic" />
                </Form.Item>
                <Form.Item
                  
                  label={<label>Zoom URL<span style={{ color: "red", fontSize: "14px" }}>*</span></label>}
                  name="zoom_id"
                  rules={[
                    {
                    pattern: /https:\/\/(?:[\w-]+\.)?zoom(?:\.us|\.com)\/(?:j|my|s)\/\d{9,}(?:\?pwd=[\w-]+)?$/,
                    message: 'Please enter a valid URL',
                  }, 
                  { required: true, message: 'Please input Zoom URL!' }]}
                >
                  <Input placeholder="Zoom URL" />
                </Form.Item>
                <Form.Item
                  label={<label>Note</label>}
                  name="note"
                  >
                  <Input placeholder="Note" />
                </Form.Item>
                <Row>

                <Button
                  htmlType="submit"
                  type="primary"
                  style={{
                    background: "#f00768",
                    borderColor: "#f00768",
                    borderRadius: '5px'
                  }}
                >
                  SUBMIT
                </Button> &nbsp;&nbsp;
                <Button
                  key="back"
                  onClick={() => this.handleFormClose()}
                  style={{
                    color: "#f00768",
                    borderColor: "#f00768",
                    borderRadius: '5px'
                  }}
                >
                  CANCEL</Button>,
                  </Row>
              </Form>
            </Modal>

            </>
          
        );
    }
}
export default JuryMemberDashboard;
// Customizable Area End