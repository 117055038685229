//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {  Card, Box, TextField, Button, CardContent, Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyleSheet} from 'react-native';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from '../CustomformController';
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import CustomFooter from '../../../../components/src/Footer.web';
import OrgHeaderWeb from '../OrgHeader.web';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    box: {
        width: '100%',
    },
}); 
    // Customizable Area End
 export default class LearningDevelopmentSectionB extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
      render(){ 
        //Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0]
        return (
			<div div id='content'>
				<OrgHeaderWeb/>
				<div className="makeStyles-root-1">
					<header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
						<div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

							<h2 style={{ textAlign: "center",  color: "white" }}>Application Form</h2>
							<h1 style={{ textAlign: "center", color: "white", fontWeight: "bolder", marginBottom: "15px", marginTop: "5px" }}>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
			{/* <div className="makeStyles-root-1">
			   <header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{background:'black', height:'200px', justifyContent: 'center'}}>
				   <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
					   <h6 className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
						   <img src={srmLogo} alt="companyName"  style={{ height:"50px", margin:"15px"}}/>
					   </h6>
				   
			   <h5>
			   <hr className="MuiDivider-root MuiDivider-flexItem MuiDivider-vertical" style={{color:"black", marginRight:"10px", marginLeft:"10px"}}/>
			   </h5>
			   
					   <h2 style={{textAlign:"center", marginTop:"10px", color:"white"}}>Application Form</h2>
					   <h1 style={{textAlign:"center",color:"white", fontWeight:"bolder", marginBottom:"15px", marginTop:"5px"}}>{this.state.customFormHeading}</h1>
					   </div>
			   </header>      
		   </div> */}


		   <div
					   style={{
						   display: 'flex',
						   justifyContent: 'center',
						   alignItems: 'center',
						   margin: '50px auto',
						   borderRadius: '20px',
						   width: '50%',
					   }}
				   >
					   <Card className={styles.container} style={{boxShadow: "none", border: '1px solid #d6d6d6'}} elevation={0}>
						   <CardContent className={styles.cardContent}>
						   <Box>
											<h1 style={{ textAlign: 'center' }}>
												<u>Section B-Quantitative Measurement</u>
											</h1>
											<p><b>
												This section covers the quantitative aspects of the
												initiative under the concerned category. Providing all the
												information under this section will enhance your nomination
												and aid	the Jury and accurate evaluation of application.</b>
											</p>
										</Box>
										<Box>
										<Paper style={{overflowX: "auto"}}>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
													<TableCell style={{width: '80px'}}><b> Sr. No.</b></TableCell>
													<TableCell> <b>Metrics</b></TableCell>
													<TableCell><b> Unit</b></TableCell>
													<TableCell> <b>FY 2018-19</b></TableCell>
													<TableCell><b> FY 2019-20</b></TableCell>
													<TableCell><b> FY 2020-21</b></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow className={styles.tableRow}>
													<TableCell><b>1</b></TableCell>
														<TableCell>
														<b>Training and development budget</b>
														</TableCell>
														<TableCell> in INR </TableCell>
														<TableCell>
															<TextField
															type="number"
																fullWidth
																value= {this.state.trainingDev['18']}
																onChange={(e) =>
																	this.setState({
																		trainingDev: {
																			...this.state.trainingDev,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
														<TextField type="number"
																fullWidth
																value= {this.state.trainingDev['19']}
																onChange={(e) =>
																	this.setState({
																		trainingDev: {
																			...this.state.trainingDev,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
														<TextField type="number"
																fullWidth
																value= {this.state.trainingDev['20']}
																onChange={(e) =>
																	this.setState({
																		trainingDev: {
																			...this.state.trainingDev,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>2</b></TableCell>
														<TableCell><b>Training & development Budget utilization </b></TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.budgetUtiliZation['18']}
																onChange={(e) =>
																	this.setState({
																		budgetUtiliZation: {
																			...this.state.budgetUtiliZation,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.budgetUtiliZation['19']}
																onChange={(e) =>
																	this.setState({
																		budgetUtiliZation: {
																			...this.state.budgetUtiliZation,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.budgetUtiliZation['20']}
																onChange={(e) =>
																	this.setState({
																		budgetUtiliZation: {
																			...this.state.budgetUtiliZation,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>3</b></TableCell>
														<TableCell>
															<b>Total No. of training man-days</b>
														</TableCell>
														<TableCell>Man-days</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.totalLearningManDays['18']}
																onChange={(e) =>
																	this.setState({
																		totalLearningManDays: {
																			...this.state.totalLearningManDays,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.totalLearningManDays['19']}
																onChange={(e) =>
																	this.setState({
																		totalLearningManDays: {
																			...this.state.totalLearningManDays,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.totalLearningManDays['20']}
																onChange={(e) =>
																	this.setState({
																		totalLearningManDays: {
																			...this.state.totalLearningManDays,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>4</b></TableCell>
														<TableCell><b>Average Training Spend</b> <br/>Training spend ÷ Number of employees participating in training</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.avarageTrainingSpend['18']}
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.avarageTrainingSpend['19']}
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.avarageTrainingSpend['20']}
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>5</b></TableCell>
														<TableCell><b>Required Training Completion rate2</b><br/>(Total number of employees who have completed a specific required training ÷ total number of employees who are required to take that training) x 100</TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.requiredTraining['18']}
																onChange={(e) =>
																	this.setState({
																		requiredTraining: {
																			...this.state.requiredTraining,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.requiredTraining['19']}
																onChange={(e) =>
																	this.setState({
																		requiredTraining: {
																			...this.state.requiredTraining,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.requiredTraining['20']}
																onChange={(e) =>
																	this.setState({
																		requiredTraining: {
																			...this.state.requiredTraining,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>6</b></TableCell>
														<TableCell>
														<b>Employee on-demand Learning availability</b> <br/>% of required learning/ training available to employees through on-demand sources
														</TableCell>
														<TableCell>%</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.onDemand['18']}
																onChange={(e) =>
																	this.setState({
																		onDemand: {
																			...this.state.onDemand,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.onDemand['19']}
																onChange={(e) =>
																	this.setState({
																		onDemand: {
																			...this.state.onDemand,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.onDemand['20']}
																onChange={(e) =>
																	this.setState({
																		onDemand: {
																			...this.state.onDemand,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>7</b></TableCell>
														<TableCell><b>
														Overall Learning Experience Score(Share measure / methodology used)			
														</b>
														</TableCell>
														<TableCell>Specify measure used</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.learningExperience['18']}
																onChange={(e) =>
																	this.setState({
																		learningExperience: {
																			...this.state.learningExperience,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.learningExperience['19']}
																onChange={(e) =>
																	this.setState({
																		learningExperience: {
																			...this.state.learningExperience,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.learningExperience['20']}
																onChange={(e) =>
																	this.setState({
																		learningExperience: {
																			...this.state.learningExperience,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>8</b></TableCell>
														<TableCell>
														<b>Incorporating new trends and best practices in Learning</b> <br/>
														<i>Please specify the relevant trend/ best practice incorporated with its corresponding numbers.
(Eg. Use of simulation, games, social media, web / mobile enabled apps etc.)
</i>

														</TableCell>
														<TableCell></TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.incorporating['18']}
																onChange={(e) =>
																	this.setState({
																		incorporating: {
																			...this.state.incorporating,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.incorporating['19']}
																onChange={(e) =>
																	this.setState({
																		incorporating: {
																			...this.state.incorporating,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.incorporating['20']}
																onChange={(e) =>
																	this.setState({
																		incorporating: {
																			...this.state.incorporating,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>8 a.</b></TableCell>
														<TableCell>
														Trend/ Best Practice

														</TableCell>
														<TableCell>Nos.</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.trendPractice['18']}
																onChange={(e) =>
																	this.setState({
																		trendPractice: {
																			...this.state.trendPractice,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.trendPractice['19']}
																onChange={(e) =>
																	this.setState({
																		trendPractice: {
																			...this.state.trendPractice,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.trendPractice['20']}
																onChange={(e) =>
																	this.setState({
																		trendPractice: {
																			...this.state.trendPractice,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>8 b.</b></TableCell>
														<TableCell>
														Trend/ Best Practice

														</TableCell>
														<TableCell>Nos.</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.bestPractice['18']}
																onChange={(e) =>
																	this.setState({
																		bestPractice: {
																			...this.state.bestPractice,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.bestPractice['19']}
																onChange={(e) =>
																	this.setState({
																		bestPractice: {
																			...this.state.bestPractice,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.bestPractice['20']}
																onChange={(e) =>
																	this.setState({
																		bestPractice: {
																			...this.state.bestPractice,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>9</b></TableCell>
														<TableCell>
														<b>Effectiveness of L&D interventions</b>

														</TableCell>
														<TableCell></TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.effectivenessInitiatives['18']}
																onChange={(e) =>
																	this.setState({
																		effectivenessInitiatives: {
																			...this.state.effectivenessInitiatives,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.effectivenessInitiatives['19']}
																onChange={(e) =>
																	this.setState({
																		effectivenessInitiatives: {
																			...this.state.effectivenessInitiatives,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.effectivenessInitiatives['20']}
																onChange={(e) =>
																	this.setState({
																		effectivenessInitiatives: {
																			...this.state.effectivenessInitiatives,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>9 a.</b></TableCell>
														<TableCell>
														<b>Measuring effectiveness though quantifiable parameters</b>

														</TableCell>
														<TableCell></TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.measuringEffectiveness['18']}
																onChange={(e) =>
																	this.setState({
																		measuringEffectiveness: {
																			...this.state.measuringEffectiveness,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.measuringEffectiveness['19']}
																onChange={(e) =>
																	this.setState({
																		measuringEffectiveness: {
																			...this.state.measuringEffectiveness,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.measuringEffectiveness['20']}
																onChange={(e) =>
																	this.setState({
																		measuringEffectiveness: {
																			...this.state.measuringEffectiveness,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell></TableCell>
														<TableCell>
														Increased retention

														</TableCell>
														<TableCell>Retention rate (%)</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.increaseRetention['18']}
																onChange={(e) =>
																	this.setState({
																		increaseRetention: {
																			...this.state.increaseRetention,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.increaseRetention['19']}
																onChange={(e) =>
																	this.setState({
																		increaseRetention: {
																			...this.state.increaseRetention,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.increaseRetention['20']}
																onChange={(e) =>
																	this.setState({
																		increaseRetention: {
																			...this.state.increaseRetention,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell></TableCell>
														<TableCell>
														Increase in business turnover directly attributable to L&D

														</TableCell>
														<TableCell>In INR</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.turnover['18']}
																onChange={(e) =>
																	this.setState({
																		turnover: {
																			...this.state.turnover,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.turnover['19']}
																onChange={(e) =>
																	this.setState({
																		turnover: {
																			...this.state.turnover,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.turnover['20']}
																onChange={(e) =>
																	this.setState({
																		turnover: {
																			...this.state.turnover,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell></TableCell>
														<TableCell>
														Improvement in performance rating attributable to L&D		

														</TableCell>
														<TableCell>Average rating</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.averageRating['18']}
																onChange={(e) =>
																	this.setState({
																		averageRating: {
																			...this.state.averageRating,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.averageRating['19']}
																onChange={(e) =>
																	this.setState({
																		averageRating: {
																			...this.state.averageRating,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.averageRating['20']}
																onChange={(e) =>
																	this.setState({
																		averageRating: {
																			...this.state.averageRating,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>9 b.</b></TableCell>
														<TableCell>
														<b>Measuring effectiveness through subjective parameters</b>

														</TableCell>
														<TableCell></TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.subjectiveParameters['18']}
																onChange={(e) =>
																	this.setState({
																		subjectiveParameters: {
																			...this.state.subjectiveParameters,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.subjectiveParameters['19']}
																onChange={(e) =>
																	this.setState({
																		subjectiveParameters: {
																			...this.state.subjectiveParameters,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.subjectiveParameters['20']}
																onChange={(e) =>
																	this.setState({
																		subjectiveParameters: {
																			...this.state.subjectiveParameters,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell></TableCell>
														<TableCell>
														Improved employee engagement/ satisfaction

														</TableCell>
														<TableCell>Scores</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.satisfactionRate['18']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.satisfactionRate['19']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.satisfactionRate['20']}
																onChange={(e) =>
																	this.setState({
																		satisfactionRate: {
																			...this.state.satisfactionRate,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell><b>9 c.</b></TableCell>
														<TableCell>
														<b>Any other measure (please specify)</b>

														</TableCell>
														<TableCell>Specify measure used</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.anyOther['18']}
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
														<TextField type="number"
																fullWidth
																value= {this.state.anyOther['19']}
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.anyOther['20']}
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											</Paper>
											<Box>
												<p>
													<b>Please add any other Learning and Development metrics/ measurements that you track and feel relevant to support your application. 
</b>
												</p>
											</Box>
											<Paper style={{overflowX: "auto"}}>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.table}>
													    <TableCell style={{width: '80px'}}><b> Sr. No.</b></TableCell>
														<TableCell><b>Metrics</b></TableCell>
														<TableCell><b>Unit</b></TableCell>
														<TableCell><b>FY 2018-19</b></TableCell>
														<TableCell><b>FY 2019-20</b></TableCell>
														<TableCell className={styles.tableHead}>
															<b>FY 2020-21</b>
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
													<TableCell>1</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.metrics['0']}
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.unit['0']}
																onChange={(e) =>
																	this.setState({
																		unit: {
																			...this.state.unit,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year18['0']}
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year19['0']}
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year20['0']}
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
													<TableCell>2</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.metrics['1']}
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.unit['1']}
																onChange={(e) =>
																	this.setState({
																		unit: {
																			...this.state.unit,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year18['1']}
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year19['1']}
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField type="number"
																fullWidth
																value= {this.state.year20['1']}
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											</Paper>
											</Box>
							   <Grid container spacing={3}>
						   <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"left"}}>
						   <Button size='large' variant='contained' id='print' onClick={()=>{ window.history.back() }} style={{ backgroundColor: '#e6257e',
												   color: '#fff',
												   background: "white",
												   marginLeft: '10px',
												   marginTop: '15px',
												   cursor:'pointer'
											   }}>  <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' />  </Button>
						   </Grid>
						   <Grid item xs={4} sm={4} md={4} lg={4} xl={4}  style={{textAlign:"center"}}>
								   <Button onClick={this.handleSaveDraftSecB} size='large' variant='contained' id='print' style={{
																   backgroundColor: '#e6257e',
																   color: '#fff',
																   marginRight: '10px',
																   marginTop: '15px',
																   cursor:'pointer'
															   }}> Save as Draft </Button>
						   </Grid>
						   <Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"right"}}>
							   <Link to="/learn_develop_c">
								   <Button 
									onClick={this.handleSaveDraftSecB}
								    size='large' variant='contained' id='print' style={{
																   backgroundColor: '#e6257e',
																   color: '#fff',
																   marginRight: '10px',
																   marginTop: '15px',
																   cursor:'pointer'
															   }}> Next </Button>
							   </Link>									
						   </Grid>
					   </Grid>
						   </CardContent>
					   </Card>
		   </div>

		   <CustomFooter/>
			{/* <footer className={styles.footer} style ={{textAlign: 'center', backgroundColor:'rgb(25,16,117)' }}>
			   <div style={{justifyContent: 'center', alignItems: 'center'}}>
				   <div style={{marginTop:'30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", width:"40px"}}>
						   <img src={facebookIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={twitterIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={linkdlnIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
					   <a style={{borderRadius:'50%', backgroundColor:'white', height:"40px", marginLeft:'10px',width:"40px"}}>
						   <img src={youtubeIcon} alt="icon" style={{ height:"20px", width:"20px", margin:"10px"}} />
					   </a>
				   </div>
				   <div>
					   <p style={{color:"white", marginTop:'20px', marginBottom:'20px'}}>SHRM India | email: example@email.com | Phone: (+880192923333) | Privacy policy</p>
				   </div>
				   <div>
					   <p style={{color:"rgb(238,237,240)", marginTop:'20px', marginBottom:'20px'}}> @2021 SHRM india. All rights reserved.</p>
				   </div>
			   </div>
		   </footer> */}
	   </div>
				);
        //Customizable Area End
      }
}
