//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import {  Card, Box, TextField, Button, CardContent } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyleSheet} from 'react-native';
import { Link } from 'react-router-dom';
import CustomformController, {Props} from './CustomformController';
import {srmLogo} from './assets';
import {facebookIcon} from './assets';
import {twitterIcon} from './assets';
import {linkdlnIcon} from './assets';
import {youtubeIcon} from './assets';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
        height: '80px'
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    box: {
        width: '100%',
    },
}); 
    // Customizable Area End
 export default class SecBform extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
      render(){ 
        //Customizable Area Start
        const rows = [1, 159, 6.0, 24, 4.0]
        return (
					<>
						<div className='makeStyles-root-1'>
							<header
								className='MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4'
								style={{
									background: 'black',
									height: '200px',
									justifyContent: 'center',
								}}
							>
								<div className='MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters'>
									<h6 className='MuiTypography-root makeStyles-title-3 MuiTypography-h6'>
										<img
											src={srmLogo}
											alt='companyName'
											style={{ height: '50px', margin: '15px' }}
										/>
									</h6>
		
									<h5>
										<hr
											className='MuiDivider-root MuiDivider-flexItem MuiDivider-vertical'
											style={{
												color: 'black',
												marginRight: '10px',
												marginLeft: '10px',
											}}
										/>
									</h5>
		
									<h2
										style={{
											textAlign: 'center',
											marginTop: '10px',
											color: 'white',
										}}
									>
										Application Form
									</h2>
									<h1
										style={{
											textAlign: 'center',
											color: 'white',
											fontWeight: 'bolder',
											marginBottom: '15px',
											marginTop: '5px',
										}}
									>
										Excellence In HR Analytics
									</h1>
								</div>
							</header>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '50px auto',
								borderRadius: '20px',
								width: '50%',
							}}
						>
							<Card className={styles.container}>
								<CardContent className={styles.cardContent}>
									<Box>
										<Box>
											<h1 style={{ textAlign: 'center' }}>
												<u>Section B-Quantitative Measurement</u>
											</h1>
											<p>
												<b>
												This section covers the quantitative aspects of the
												initiative under the concerned category. Providing all the
												information under this section will enhance your nomination
												and aid the Jury and accurate evaluation of application</b>
											</p>
										</Box>
										<Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell><b>Metrics</b></TableCell>
														<TableCell><b> Unit</b></TableCell>
														<TableCell><b> FY 2018-19</b></TableCell>
														<TableCell> <b>FY 2019-20</b></TableCell>
														<TableCell> <b>FY 2020-21</b></TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow className={styles.tableRow}>
														<TableCell>
															Cost of Human Capital to the total operating cost
															(Direct and Indirect spend) <br />
															<i>
																Cost of Human Capital :All direct and indirect spend
																on Human Capital in your organization
															</i>
														</TableCell>
														<TableCell> % </TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		costOfHumanCapital: {
																			...this.state.costOfHumanCapital,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Please specify the components you have taken into
															consideration to account for total human capital cost-
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Net profit per employee(PAT) <br />
															<i>
																Net profit per employee: Net profit after
																taxes/total number of FTE.
															</i>
														</TableCell>
														<TableCell>Ratio/ in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		netProfit: {
																			...this.state.netProfit,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Average cost per hire</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageCostPerHire: {
																			...this.state.avarageCostPerHire,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Average benifit cost per employee</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageBenifitCostPerHire: {
																			...this.state.avarageBenifitCostPerHire,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Average training spend per employee
														</TableCell>
														<TableCell>in INR</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			18: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			19: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		avarageTrainingSpend: {
																			...this.state.avarageTrainingSpend,
																			20: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box>
												<p>
													Please share any other HR analytics measurement that you
													track and feel relevant to support your application
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.table}>
														<TableCell>Metrics</TableCell>
														<TableCell>Unit</TableCell>
														<TableCell>FY 2018-19</TableCell>
														<TableCell>FY 2019-20</TableCell>
														<TableCell className={styles.tableHead}>
															FY 2020-21
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		unti: {
																			...this.state.unti,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			0: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		metrics: {
																			...this.state.metrics,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		unti: {
																			...this.state.unti,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year18: {
																			...this.state.year18,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year19: {
																			...this.state.year19,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		year20: {
																			...this.state.year20,
																			1: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box>
												<p>
													Please specify in percentage(%) terms, what type of HR
													analytics is currently practiced in the organisation. Also
													share the details of which HR processes it is used across
													the below listed types.
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell>Type of Analytics</TableCell>
														<TableCell>Percentage (%)</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>
															Descriptive analytics(using past trends for reporting){' '}
															<br /> <i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		descriptivAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Diagnostic analytics (Testing casual relationships){' '}
															<br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diagnosticAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Predictive Analytics (Using data for future descision
															making) <br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		predictiveAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Prescriptive Analytics (Data used for descision making
															and recommending changes to HR processes/ practices){' '}
															<br />
															<i>Used across which HR processes:</i>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		prescriptiveAnalytics: e.target.value,
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box item xs={12}>
												<p>
													On which of the following do you track and preserve
													metrics as part of your HR dashboard to the management?
												</p>
											</Box>
											<Table className={styles.table} aria-label='simple table'>
												<TableHead className={styles.tableHead}>
													<TableRow className={styles.tableRow}>
														<TableCell>HR Process</TableCell>
														<TableCell>Do not have specific metrics</TableCell>
														<TableCell>
															Have specific metrics and track them
														</TableCell>
														<TableCell>
															List of specific tracks that are tracked
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													<TableRow>
														<TableCell>Talent Acquisition</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		talentAcquisition: {
																			...this.state.talentAcquisition,
																			listOfMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Transfer and Job Rotation</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		transferandJobRotation: {
																			...this.state.transferandJobRotation,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Performance Management</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		performanceManagement: {
																			...this.state.performanceManagement,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Rewards and Recognition</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		rewardsandRecognition: {
																			...this.state.rewardsandRecognition,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Learning and Development</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		learningandDevelopment: {
																			...this.state.learningandDevelopment,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Career/Succession Planning</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		careerSuccessionPlanning: {
																			...this.state.careerSuccessionPlanning,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Employee Grievances</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeGrievances: {
																			...this.state.employeeGrievances,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															Employee Retention/Hi-potential retention
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		employeeRetention: {
																			...this.state.employeeRetention,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Diversity and Inclusion</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		diversityAndInclusion: {
																			...this.state.diversityAndInclusion,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>Any others (please specify)</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			doNotHaveMetrics: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			haveMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
														<TableCell>
															<TextField
																fullWidth
																onChange={(e) =>
																	this.setState({
																		anyOther: {
																			...this.state.anyOther,
																			listMetricsAndTrack: e.target.value,
																		},
																	})
																}
															/>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
											<Box styles={styles.buttons}>
												<Link to='/section-a'>
													<Button
														size='large'
														variant='outlined'
														style={{ color: '#e6257e', marginTop: '15px' }}
													>
														BACK
													</Button>
												</Link>
												<Button
													size='large'
													variant='contained'
													style={{
														backgroundColor: '#e6257e',
														color: '#fff',
														marginLeft: '40%',
														marginTop: '15px',
													}}
													onClick={this.handleSaveDraftSecB}
												>
													SAVE AS DRAFT
												</Button>
												<Link to='/section-c'>
													<Button
														size='large'
														variant='contained'
														style={{
															backgroundColor: '#e6257e',
															color: '#fff',
															marginLeft: '10px',
															marginTop: '15px',
														}}
													>
														{' '}
														NEXT
													</Button>
												</Link>
											</Box>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</div>
						<footer
							className={styles.footer}
							style={{ textAlign: 'center', backgroundColor: 'rgb(25,16,117)' }}
						>
							<div style={{ justifyContent: 'center', alignItems: 'center' }}>
								<div
									style={{
										marginTop: '30px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											width: '40px',
										}}
									>
										<img
											src={facebookIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={twitterIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={linkdlnIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
									<a
										style={{
											borderRadius: '50%',
											backgroundColor: 'white',
											height: '40px',
											marginLeft: '10px',
											width: '40px',
										}}
									>
										<img
											src={youtubeIcon}
											alt='icon'
											style={{ height: '20px', width: '20px', margin: '10px' }}
										/>
									</a>
								</div>
								<div>
									<p
										style={{
											color: 'white',
											marginTop: '20px',
											marginBottom: '20px',
										}}
									>
										SHRM India | email: example@email.com | Phone: (+880192923333) |
										Privacy policy
									</p>
								</div>
								<div>
									<p
										style={{
											color: 'rgb(238,237,240)',
											marginTop: '20px',
											marginBottom: '20px',
										}}
									>
										{' '}
										@2021 SHRM india. All rights reserved.
									</p>
								</div>
							</div>
						</footer>
					</>
				);
        //Customizable Area End
      }
}
