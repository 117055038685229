// Customizable Area Start
import React from 'react';
import {  Card } from 'antd';
import EmailAccountLoginController from "../../email-account-login/src/EmailAccountLoginController.web";
import CustomFooter from '../../../components/src/Footer.web';
import OrgHeaderWeb from '../../customform/src/OrgHeader.web';



class EvaluationParameterScreen extends EmailAccountLoginController {
    constructor(props: any) {
        super(props);
    }
    render(): React.ReactNode {

        return(
            <>

            {/* @ts-ignore */}
            <OrgHeaderWeb/>                

                <div>
                    <header className="header-custom">
                        <div>
                            <h1 >Evaluation parameters</h1>
                        </div> 
                    </header> 
				</div>
            
            <div  style={{   
                display: 'flex',     
                justifyContent:'center',     
                alignItems:'center' ,
              
                }}>   
                   
                <Card  style={{justifyContent:'center', alignItems:'center', height: '120vh', width: "80%", margin:'20px auto'}}>
                    <iframe src={`${this.state.media}/1}`}
                        style={{width: '100%',height: '110vh', border: 'none', margin:'20px auto'}}>

                    </iframe>
                </Card>    
            </div>          
            {/* @ts-ignore */}
            <CustomFooter />
            </>
          
        );
    }
}
export default EvaluationParameterScreen;
// Customizable Area End