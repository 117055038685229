//@ts-ignore
//@ts-nocheck
import React from 'react';
// Customizable Area Start
import CustomformController from './CustomformController';
import './org.css';

// Customizable Area End
export default class UploadDocModal extends CustomformController{
    constructor(props: any) {
        super(props);
      }

    render(){
        // Customizable Area Start
        
        
        return (            
            <>
            {this.props.viewDocumentType.includes("video")&&
                <>
                <video style={{maxWidth:'-webkit-fill-available',height: '100%'}} controls>  
                    <source src={this.props.viewDocumentUrl} type="video/mp4"/>  
                </video>                 
                </>}
            
            {this.props.viewDocumentType.includes("image") &&
                <>
                <img src={this.props.viewDocumentUrl} className="uploadede-img-size" />
                </> }                  
             
            {this.props.viewDocumentType.includes("pdf") &&
                <>
                <iframe src={this.props.viewDocumentUrl} 
                style={{width: '100%',height: '100%',border: 'none'}}></iframe>                        
                </>}

            {this.props.viewDocumentType.includes("audio") &&
                <>
                <audio controls style={{height: '100%'}}>
                <source src={this.props.viewDocumentUrl} type="audio/ogg"/>
                <source src={this.props.viewDocumentUrl} type="audio/mpeg"/>                        
                </audio>
                </>}
            
                {(this.props.viewDocumentType.includes("document") || this.props.viewDocumentType.includes("application/x-ole-storage") || this.props.viewDocumentType.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                || this.props.viewDocumentType.includes("application/vnd.ms-excel") || this.props.viewDocumentType.includes("application/vnd.ms-powerpoint")
                || this.props.viewDocumentType.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") || this.props.viewDocumentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) &&
                <>
                <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${this.props.viewDocumentUrl}`}
                style={{width: '100%',height: '100%',border: 'none'}}></iframe> 
                </>}
                
            </>                
            
        );
        //Customizable Area End
    }
}
