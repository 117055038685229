import {notification} from "antd";
// Customizable Area Start
import {Modal} from "antd";
export const SuccessToast = (title: string, desc: any) => {

    Modal.success({
        title: title,
        content: desc,
      });
};

export const FailedToast = (title: string, desc: any) => {

    Modal.error({
        title: title,
        content: desc,
      });
};
export const WarningToast = (title: string, desc: any) => {

  Modal.warning({
      title: title,
      content: desc,
    });
};
// Customizable Area End