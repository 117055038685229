export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const fileIcon = require("../assets/file.png");
// Customizable Area Start
export const facebookIcon = require("../assets/facebook.png");
export const twitterIcon = require("../assets/twitter.png");
export const linkdlnIcon = require("../assets/linkdln.png");
export const youtubeIcon= require("../assets/youtube.png");
export const srmLogo= require("../assets/srmlogo.png");
 export const uploadIcon = require("../assets/uploadIcon.png");
export const alertIcon =require("../assets/alert.png");
export const applicationForm =require("../assets/applicationForm.png");
export const congragulations=require("../assets/congragulations.png");
export const reminder=require("../assets/reminder.png");
export const applicationFormImage=require("../assets/application.jpeg");
//Customizable Area End
