//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react';
// Customizable Area Start
import './org.css';
import EmailAccountRegistrationController from '../../email-account-registration/src/EmailAccountRegistrationController.web';
import { withStyles } from "@material-ui/core/styles";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import SHRM_awardLogo from '../assets/image_logo@3x.png'

const styles = {
    root: {
        flexGrow: 1,
        height: '80px'
    }
};
// Customizable Area End
class Org extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const display = localStorage.getItem('display_name') ?? 'NA';
        const role = localStorage.getItem('role') ?? 'NA';
        return (
            <>
                <div className="makeStyles-root-1" id="header-div">
                    <header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '100px', justifyContent: 'center' }}>
                        <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
                            <div className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
                                <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                                    {role == "jury_penalist"&& <a id="jury_href" href="/jury-member-dashboard"><img src={SHRM_awardLogo} alt="companyName" style={{ height: "50px", margin: "15px" }} /></a>}
                                    {role =="organization" && <a id="org_href" href="/organization"><img src={SHRM_awardLogo} alt="companyName" style={{ height: "50px", margin: "15px" }} /></a>}

                                    <div style={{marginRight:"30px"}} id="dropdown-div">
                                    <Dropdown id="dropdown" overlay={<Menu>
                                        <Menu.Item key="0" id="logout-btn" onClick={()=>{
                                            localStorage.clear();
                                            location = "/login"
                                        }}>
                                            Logout
                                        </Menu.Item>
                                        </Menu>}  trigger={['click']}>
                                            <a style={{color:'#fff'}}>
                                            
                                                <p style={{fontSize:'14px', color: "white"}} ><span className="display_name">{display}</span>
                                                <DownOutlined style={{marginLeft:'8px'}}/></p>
                                            
                                            </a>
                                        </Dropdown>
                                    </div>  
                                </div>

                            </div>

                            <h5 id="heading_5">                                
                                <hr className="MuiDivider-root MuiDivider-flexItem MuiDivider-vertical" style={{ border: 'none', height: '1px', background: 'gray', marginRight: "10px", marginLeft: "10px" }} />
                            </h5>
                        </div>
                    </header>
                </div>            
            </>
        );
    }
}
export default withStyles(styles)(Org);
//Customizable Area End