// Customizable Area Start
//  @ts-nocheck
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from "./assets/image_logo@3x.png";
import EmailAccountRegistrationController, { Props, configJSON } from "../../blocks/email-account-registration/src/EmailAccountRegistrationController.web";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

class AppHeaderWeb extends EmailAccountRegistrationController {
  constructor(props: any) {
      super(props);
  }
  render(): React.ReactNode {
    return (
      <div>
        <div style={{height: "330px", width: "100%"}}>
            <div style={{position: "absolute"}} >
              <img src={logo} alt="companyName" style={{ height: "50px", margin: "15px" }} />
            </div>
        
            <div style={{height: "330px", width: "100%"}}>
              <img alt='Advertising banner' src={this.state.url} style={{ height: '330px', width: "100%", backgroundSize: "cover" }}/>
            </div>
         
        </div>
      </div>
    );
  }

}


export default AppHeaderWeb;
 // Customizable Area End