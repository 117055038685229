import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react';
import { FailedToast, SuccessToast,WarningToast } from "../../email-account-registration/src/Toast";
export const Colors = {
	inputLabel: "rgba(31, 31, 34, 0.6)",
	inputTextColor: "rgb(31, 31, 34)",
	borderGrey: "rgba(28, 28, 30, 0.3)",
	borderYellow: "rgb(205, 149, 12)",
	white: "#FFFFFF",
	modalBg: "rgb(243, 243, 243)",
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	classes?: any;
	UploadFile?: any;
	files?: any
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	personWithDisability: any;
	getSellerDetailsMessageId: any;
	postSellerDetailsMessageId: any;
	sellerID: any;
	shopName: string;
	address: string;
	gstin: string;
	selectedServices: number[];
	showSuccessModal: boolean;
	token: string;
	lat: any;
	long: any;
	company: any;
	digitization: any;
	name: any;
	designation: any;
	contact: any;
	mail: any;
	bussiness: any;
	entity: any;
	ownership: any;
	revenue: any;
	team: any;
	revenueGrowth: any;
	profitGrowth: any;
	employeeHeadcount: any;
	contractualEmployeeHeadcount: any;
	hrEmployeeStrength: any;
	hrEmployeeRatio: any;
	genderDiversityRatio: any;
	userCategoryId: any,
	deleteDocumentModal: any,
	deleteDocumentFileId: any;
	deleteSupportingDocument: any,

	applicationFormData: any;

	// communicty impact section-B starts here
	numberOfAreas: any;
	environment: any;
	livelihoodPrograms: any;
	communityHealth: any;
	communityEducation: any;
	others: any;
	otherHr: any;
	budgetAllocation: any;
	budgetUtiliZation: any;
	externalFund: any;
	noOfPeopleImpacted: any;
	noOfemp_totalDays: any;
	noOfEmp: any;
	manDays: any;
	empPaidNoOfEmp: any;
	empPaidManDays: any;
	challengeIssues: any;
	emergedInitiatives: any;
	effectivenessInitiatives: any;
	keyStakeholders: any;
	keyFactors: any;
	communityInitiative: any;
	information: any;
	evp: any;

	leadershipDevelopmentBudget: any;
	totalL_DBudget: any;
	NoOfLeadershipManDays: any;
	identifiedLeadershipPositions: any;
	leadersPromoted: any;
	benchStrength2yearsSuccessors: any;
	benchStrength5yearsSuccessors: any;
	identifiedEmergingLeadership: any;
	PercentageOfEmergingLeaders: any;
	benchStrength2years: any;
	benchStrength5years: any;
	percentageOfvoluntary: any;
	totalLearningManDays: any;
	projectRoles: any;
	secondment: any;
	internalJobRotation: any;
	promotions: any;
	totalFileSize: number;
	uploadFileSize: number;
	numOfFile: number;
	implementationScale: any;
	noOfofficesCovered: any;
	digitizedHRprocess: any;
	fullyDigitizedHRprocess: any;
	partialDigitizedHRprocess: any;
	partialDigitizedHRNameprocess: any;
	manualHRprocess: any;
	manualHRNameprocess: any;
	totalHeadCountImpacted: any;
	noOfmanSavedHRprocess: any;
	recruitmentTechniques: any;
	empInduction_orieo: any;
	compensation_benifits: any;
	performMgmtSystm: any;
	administrativeSystems: any;
	learn_develop: any;
	communication: any;
	satidifactionSurveys: any;
	specifyMeasure: any;
	successMeasureInitiatives: any;
	satisfactionScore: any;
	productiveIncrease: any;
	inquiresToHR: any;
	hrProcessesImprovedKeyOrganization: any;
	interventionsCostEffectiveness: any;
	plannedDigitizationInitiatives: any;
	workforceComposition: any;
	noOfEmployeesWorkingFromHome_Remotely: any;
	noOfEmployeesWorkingFromOfficePremises: any;
	noOfEmployeesWorkingFromHome_OfficePremises: any;
	avgWorkingHoursPerWeek: any;
	trackWorkingHours: any;
	amountUpgradeSoftware: any;
	amountUpgradeHardware: any;
	amountSkillsUpgradation: any;
	engagementMetrics: any;
	transitionProcess: any;
	impactedEmployeeProductivity: any;
	costOfHumanCapital: any;
	extendedWomen: any;
	netProfit: any;
	initiatives: any;
	total: any;
	avarageCostPerHire: any;
	avarageBenifitCostPerHire: any;
	avarageTrainingSpend: any;
	avgEmployeeAge: any;
	metrics: any;
	unit: any;
	year18: any;
	year19: any;
	year20: any;
	descriptivAnalytics: any;
	diagnosticAnalytics: any;
	predictiveAnalytics: any;
	prescriptiveAnalytics: any;
	attritionRate: any;
	employeeEngagementScores: any;

	supportiveFiles: any;
	ifSubmitedBlockFileUploadModal: any;
	supportiveImageFile: any;
	talentAcquisition: any;
	transferandJobRotation: any;
	performanceManagement: any;
	rewardsandRecognition: any;
	learningandDevelopment: any;
	careerSuccessionPlanning: any;
	employeeGrievances: any;
	employeeRetention: any;
	diversityAndInclusion: any;
	anyOther: any;
	isupload: any;
	fileSize: any;
	isdownloading: any;
	trainingSpend: any;
	returnOnInv: any;
	variableCom: any;
	representative: any;
	reducedCost: any;
	improved: any;
	grievance: any;
	improvedPublic: any;
	increasedOrganizational: any;
	employeeSatisfaction: any;
	supplier: any;
	helperText: any,
	error: boolean
	impact: any;
	furtherDetails: any;
	covid: any;
	separateTeam: any;
	data: any;
	fileUploadModal: boolean;
	selectedCategoryID: any;
	fileViewModal: boolean;
	ConfirmationModal: boolean;
	formLoader:any;
	pdfNotReady:boolean;
	docNotUploaded:boolean;
	viewDocument: any;
	viewDocumentUrl: any;
	viewDocumentType: any;
	viewDocumentName: any;
	fileViewModalImage: boolean;
	selectedData: any;
	categoryIdFormSubmit: any;
	categoryListApi: any;
	categoryLoading: any;
	DynamicFormCategoryId: any;
	DynamicFormSectionId: any;
	DynamicFormApplicationFormId: any;
	DynamicFormNextPageSectionId: any;
	DynamicFormPreviousPageSectionId: any;
	DynamicFormData: any;
	uploadedDocuments: any;
	uploadButton: boolean;
	uploadedDocumentPercentage: any;
	uploadedDocumentLoading: boolean;
	categoryDocuments: any;
	files: any;
	successfullySubmitDocModal: boolean,
	submittedCategory: any,
	finalSubmission: boolean,
	customFormHeading: any,
	documentType: any,
	submitFormMessage: any,
	triggerType: any;
	nextPage: any;
	manpower: any;
	executive: any;
	seniorManager: any;
	manager: any;
	headcount: any;
	averageCost: any;
	averageTime: any;
	applicants: any;
	satisfactionRate: any;
	interviewProcess: any;
	onBoardingProcess: any;
	hiringProcess: any;
	quality: any;
	newHire: any;
	averagePerformance: any;
	vacancies: any;
	referrals: any;
	recruiting: any;
	careerGrowth: any;
	avaragePerformance: any;
	redirectToOrganization: boolean;
	redirectToSectionB: boolean;
	deleteVendorCallId: any;
	uploadPercentage: any;
	details: any,
	trainingDev: any,
	requiredTraining: any,
	onDemand: any,
	learningExperience: any,
	incorporating: any,
	trendPractice: any,
	bestPractice: any;
	measuringEffectiveness: any,
	increaseRetention: any,
	turnover: any,
	averageRating: any,
	subjectiveParameters: any,
	organizationName: any,
	is_special: boolean,
	progressBarPercentage: number,
	reminderModal: boolean,
	finalStatus:any,
	max_number_of_categories: Number,
	finalSubmitModal:boolean;
	finalSubmittedFormStatus:boolean;
	sectionName: any,
	sectionSubtitle: any;
	sectionDefinition: any;
	formSectionDetails:any;
	multiSelectOption: any;
	radioValue: any;
	dynamicFile: any
	singleSelect: any;
	tableData: any;
	saveAsDraftLoading: boolean;
	requiredClassName: string;
	// Customizable Area End
}

interface SS {
	id: any;
}

export default class CustomformController extends BlockComponent<Props, S, SS> {
	//Customizable Area Start
	getUserCategoryDataApiCallId: any = "";
	intervalId: any;
	getUserUploadedDocuments: any = "";
	getUserCategoryDocuments: any = "";
	onDrop: any = "";
	getCategoryFinalSubmit: any = ""
	getDocumentsKey: any = ''
	deleteApplicationKey: any = ''
	communityImpactSaveDraftkey: any = ''
	developerLeadersSaveDraftkey: any = ''
	deleteVendorCallId: any = ''
	apiGetOrgInfoId: String = "";
	apiGetDynamicFormDataId: string = "";
	getUserUploadedDocumentsFiles: any = ""
	apiResetApplicationSubmitPostCallId: string = "";
	apiResetCurrentTimePostCallId: string = "";
	apiResetDynamicFileUploadCallId: string = "";
	apiResetcheckFileUploadCallId: boolean = false;
	// Customizable Area End
	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start

		this.subScribedMessages = [
			getName(MessageEnum.RestAPIRequestMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.NavigationPayLoadMessage),
			getName(MessageEnum.SessionResponseMessage),
		];
		this.state = {
			requiredClassName: '',
			tableData: [],
			saveAsDraftLoading: false,

			personWithDisability: {
				executive: { 20: { men: '', women: '', total: '' }, 21: { men: '', women: '', total: '' }, 22: { men: '', women: '', total: '' } },
				seniorManager: { 20: { men: '', women: '', total: '' }, 21: { men: '', women: '', total: '' }, 22: { men: '', women: '', total: '' } },
				manager: { 20: { men: '', women: '', total: '' }, 21: { men: '', women: '', total: '' }, 22: { men: '', women: '', total: '' } },
				others: { 20: { men: '', women: '', total: '' }, 21: { men: '', women: '', total: '' }, 22: { men: '', women: '', total: '' } },
				total: { 20: { men: '', women: '', total: '' }, 21: { men: '', women: '', total: '' }, 22: { men: '', women: '', total: '' } },
			},
			submitFormMessage: '',
			is_special: false,
			getSellerDetailsMessageId: '',
			postSellerDetailsMessageId: '',
			sellerID: '',
			shopName: '',
			address: '',
			gstin: '',
			selectedServices: [],
			token: '',
			showSuccessModal: false,
			lat: 0,
			long: 0,
			company: '',
			digitization: '',
			name: '',
			designation: '',
			contact: '',
			mail: '',
			covid: '',
			separateTeam: '',
			bussiness: '',
			selectedCategoryID: '',
			entity: '',
			ownership: '',
			revenue: '',
			team: '',
			revenueGrowth: {},
			profitGrowth: {},
			employeeHeadcount: {},
			contractualEmployeeHeadcount: {},
			hrEmployeeStrength: {},
			averagePerformance: {},
			hrEmployeeRatio: {},
			genderDiversityRatio: {},
			userCategoryId: '',
			deleteDocumentModal: false,
			deleteDocumentFileId: '',
			deleteSupportingDocument: false,
			avgEmployeeAge: {},
			attritionRate: {},
			employeeEngagementScores: '',
			isupload: false,
			fileSize: false,
			isdownloading: false,
			documentType: "",
			applicationFormData: {},
			details: '',
			trainingDev: {},
			requiredTraining: {},
			onDemand: {},
			learningExperience: {},
			incorporating: {},
			trendPractice: {},
			bestPractice: {},
			measuringEffectiveness: {},
			increaseRetention: {},
			turnover: {},
			averageRating: {},
			subjectiveParameters: {},
			organizationName: '',
			progressBarPercentage: 0,
			// community impact section B starts here
			numberOfAreas: {},
			environment: {},
			livelihoodPrograms: {},
			communityHealth: {},
			communityEducation: {},
			others: {},
			otherHr: {},
			budgetAllocation: {},
			budgetUtiliZation: {},
			externalFund: {},
			noOfPeopleImpacted: {},
			noOfemp_totalDays: {},
			noOfEmp: {},
			extendedWomen: {},
			manDays: {},
			empPaidNoOfEmp: {},
			empPaidManDays: {},
			reminderModal: false,
			finalStatus:"",
			max_number_of_categories: 1,
			finalSubmitModal:false,
			finalSubmittedFormStatus:false,
			// community impact section B Ends here
			redirectToOrganization: false,
			redirectToSectionB: false,
			// community impact section C Starts here
			challengeIssues: '',
			evp: '',
			emergedInitiatives: '',
			effectivenessInitiatives: '',
			keyStakeholders: '',
			keyFactors: '',
			communityInitiative: '',
			information: '',
			// community impact section C Ends here

			// Developing the Leaders of Tomorrow Section B Starts here
			leadershipDevelopmentBudget: {},
			totalL_DBudget: {},
			NoOfLeadershipManDays: {},
			identifiedLeadershipPositions: {},
			leadersPromoted: {},
			benchStrength2yearsSuccessors: {},
			benchStrength5yearsSuccessors: {},
			identifiedEmergingLeadership: {},
			PercentageOfEmergingLeaders: {},
			benchStrength2years: {},
			benchStrength5years: {},
			percentageOfvoluntary: {},
			totalLearningManDays: {},
			projectRoles: {},
			secondment: {},
			internalJobRotation: {},
			promotions: {},
			totalFileSize: 0,
			uploadFileSize: 0,
			numOfFile: 0,
			// Developing the Leaders of Tomorrow Section B Ends here

			// HR Technology Section B starts here
			implementationScale: {},
			noOfofficesCovered: {},
			digitizedHRprocess: {},
			fullyDigitizedHRprocess: {},
			partialDigitizedHRprocess: {},
			partialDigitizedHRNameprocess: {},
			manualHRprocess: {},
			manualHRNameprocess: {},
			totalHeadCountImpacted: {},
			noOfmanSavedHRprocess: {},
			recruitmentTechniques: {},
			empInduction_orieo: {},
			compensation_benifits: {},
			performMgmtSystm: {},
			administrativeSystems: {},
			learn_develop: {},
			communication: {},
			satidifactionSurveys: {},
			specifyMeasure: {},
			successMeasureInitiatives: {},
			satisfactionScore: {},
			productiveIncrease: {},
			inquiresToHR: {},
			// HR Technology Section B ends here

			// HR Technology Section C starts here
			hrProcessesImprovedKeyOrganization: '',
			interventionsCostEffectiveness: '',
			plannedDigitizationInitiatives: '',
			// HR Technology Section C ends here

			// Hybrid Workplace Section B starts here
			workforceComposition: {},
			noOfEmployeesWorkingFromHome_Remotely: {},
			noOfEmployeesWorkingFromOfficePremises: {},
			noOfEmployeesWorkingFromHome_OfficePremises: {},
			avgWorkingHoursPerWeek: {},
			trackWorkingHours: {},
			amountUpgradeSoftware: {},
			amountUpgradeHardware: {},
			amountSkillsUpgradation: {},
			engagementMetrics: {},
			// Hybrid Workplace Section B ends here


			// Hybrid Workplace Section C starts here
			transitionProcess: '',
			impactedEmployeeProductivity: '',
			// Hybrid Workplace Section C ends here

			costOfHumanCapital: {},
			netProfit: {},
			initiatives: {},
			total: {},
			careerGrowth: {},
			avaragePerformance: {},
			avarageCostPerHire: {},
			avarageBenifitCostPerHire: {},
			avarageTrainingSpend: {},
			metrics: {},
			unit: {},
			year18: {},
			year19: {},
			year20: {},
			manpower: {},
			executive: {},
			seniorManager: {},
			manager: {},
			headcount: {},
			averageCost: {},
			averageTime: {},
			applicants: {},
			satisfactionRate: {},
			interviewProcess: {},
			onBoardingProcess: {},
			hiringProcess: {},
			quality: {},
			newHire: {},
			vacancies: {},
			referrals: {},
			recruiting: {},
			descriptivAnalytics: '',
			diagnosticAnalytics: '',
			predictiveAnalytics: '',
			prescriptiveAnalytics: '',

			talentAcquisition: {},
			transferandJobRotation: {},
			performanceManagement: {},
			rewardsandRecognition: {},
			learningandDevelopment: {},
			careerSuccessionPlanning: {},
			employeeGrievances: {},
			employeeRetention: {},
			diversityAndInclusion: {},
			anyOther: {},
			trainingSpend: {},
			returnOnInv: {},
			variableCom: {},
			representative: {},
			reducedCost: {},
			improved: {},
			grievance: {},
			improvedPublic: {},
			increasedOrganizational: {},
			employeeSatisfaction: {},
			supplier: {},
			customFormHeading: '',
			uploadPercentage: 0,
			sectionName: '',
			sectionSubtitle: '',
			sectionDefinition: '',
			formSectionDetails:{},
			impact: '',
			furtherDetails: '',
			fileUploadModal: false,
			fileViewModal: false,
			ConfirmationModal: false,
			formLoader:{},
			pdfNotReady:false,
			docNotUploaded:false,
			viewDocument: false,
			viewDocumentUrl: '',
			viewDocumentType: '',
			viewDocumentName: '',
			fileViewModalImage: false,
			successfullySubmitDocModal: false,
			submittedCategory: '',
			finalSubmission: false,
			selectedData: '',
			categoryIdFormSubmit: '',
			categoryListApi: [],
			categoryLoading: false,
			DynamicFormCategoryId: '',
			DynamicFormApplicationFormId: '',
			// initial:{Email:'sfjks@gmail.com'},
			DynamicFormSectionId: '',
			DynamicFormNextPageSectionId: '',
			DynamicFormPreviousPageSectionId: '',
			DynamicFormData: [],
			uploadedDocuments: [],
			uploadButton: true,
			uploadedDocumentPercentage: 0,
			uploadedDocumentLoading: false,
			categoryDocuments: [],
			triggerType: '',
			nextPage: '',
			deleteVendorCallId: '',
			supportiveFiles: [],
			ifSubmitedBlockFileUploadModal: '',
			supportiveImageFile: "",
			files: [],
			error: false,
			multiSelectOption: '',
			radioValue: '',
			singleSelect: '',
			helperText: '',
			dynamicFile: "",
			data: [
				{
					categoryName: 'Excellence in HR Analytics',
					files: [],
					companyName: '',
					name: '',
					desgination: '',
					contact: '',
					email: '',
					bussiness: '',
					type: '',
					ownership: '',
					revenues: '',
					HR: '',
					mertric: [
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
					],
				},
				{
					categoryName: 'Excellence in Diversity & Inclusion',
					files: [],
					companyName: '',
					name: '',
					desgination: '',
					contact: '',
					email: '',
					bussiness: '',
					type: '',
					ownership: '',
					revenues: '',
					HR: '',
					mertric: [
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
					],
				},
				{
					categoryName: 'Excellence in Community Impact',
					files: [],
					companyName: '',
					name: '',
					desgination: '',
					contact: '',
					email: '',
					bussiness: '',
					type: '',
					ownership: '',
					revenues: '',
					HR: '',
					mertric: [
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
					],
				},
				{
					categoryName: 'Excellence in Developing Leaders of Tomorrow',
					files: [],
					companyName: '',
					name: '',
					desgination: '',
					contact: '',
					email: '',
					bussiness: '',
					type: '',
					ownership: '',
					revenues: '',
					HR: '',
					mertric: [
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
					],
				},
				{
					categoryName: 'Excellence in Talent Acquisition',
					files: [],
					companyName: '',
					name: '',
					desgination: '',
					contact: '',
					email: '',
					bussiness: '',
					type: '',
					ownership: '',
					revenues: '',
					HR: '',
					mertric: [
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
						{
							rGrowth: '',
							pGrowth: '',
							totalE: '',
							totalC: '',
							totalHR: '',
							HRtoEmpRatio: '',
							GenderRatio: '',
							averageEmpAge: '',
							volumeRate: '',
							empScores: '',
						},
					],
				},
			],
		};
		// Customizable Area End
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		runEngine.debugLog("Message Recived", message);

		

	//submit application form
// this.receiveSubmitApplicationForm(message);
	

	//dynamic file upload
	this.receiveDynamicFile(message);
	

this.receiveGetCategoryFinal(message);	

this.receiveSessionResponse(message);

this.recieveUploadedDocuments(message);

		// Customizable Area End
	}

	// Customizable Area Start

	// receiveSubmitApplicationForm(message: any) {
	// 	if (
	// 		getName(MessageEnum.RestAPIResponceMessage) === message.id &&
	// 		this.apiResetApplicationSubmitPostCallId &&
	// 		this.apiResetApplicationSubmitPostCallId ===
	// 		message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
	// 	) {
	// 		let responseJson = message.getData(
	// 			getName(MessageEnum.RestAPIResponceSuccessMessage)
	// 		);
	// 		if (
	// 			responseJson &&
	// 			responseJson.errors &&
	// 			responseJson.errors.length > 0
	// 		) {
	// 			FailedToast("Error", responseJson.errors[0]);
	// 			return;
	// 		}
	// 		if (
	// 			responseJson &&
	// 			responseJson.error
	// 		) {
	// 			FailedToast(responseJson.error, responseJson.exception ?? "");
	// 			return;
	// 		}
	// 		SuccessToast("Success", "Application has been submitted successfully");
	// 		this.setState({ redirectToOrganization: true })
	// 	}
	// }

	receiveDynamicFile(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiResetDynamicFileUploadCallId &&
			this.apiResetDynamicFileUploadCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			this.setState({saveAsDraftLoading: false});
			if (
				responseJson &&
				responseJson.errors &&
				responseJson.errors.length > 0
			) {
				FailedToast("Error", responseJson.errors[0]?.message);
				return;
			}
			if (
				responseJson &&
				responseJson.error
			) {
				FailedToast(responseJson.error, responseJson.exception ?? "");

				return;
			}
			
			this.setState({DynamicFormData: responseJson?.data.attributes.questions.data,  saveAsDraftLoading: false});
			
			SuccessToast("Success","Application form saved successfully.");
			setTimeout(() => {
			if(this.state?.DynamicFormNextPageSectionId == null && this.apiResetcheckFileUploadCallId){
				this.props.navigation.navigate('Org')
			}
			this.apiResetcheckFileUploadCallId = false;	
			}, 3000);	
		
		}
	}

	receiveGetCategoryFinal(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getCategoryFinalSubmit &&
			this.getCategoryFinalSubmit ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson === undefined) {
				return;
			} else if (
				responseJson &&
				responseJson.errors
			) {
				FailedToast("Failed", responseJson.errors[0].message);
				return;
			}else if (
				responseJson &&
				responseJson.error
			) {
				FailedToast("Failed", responseJson.error);
				return;
			}
			if(responseJson?.success){
				let catId : any = localStorage.getItem('categoryId');
				let a = +catId
				this.setState((prevState) => ({
					formLoader: {
					  ...prevState.formLoader,
					  [a]: false,
					},
				  }));
			this.setState({
				successfullySubmitDocModal: true,
				submittedCategory: responseJson?.data?.attributes?.category?.data?.attributes?.name,
			})
			this.getAllCategory();
		}
	}
	}

	receiveSessionResponse(message: any) {
		if (getName(MessageEnum.SessionResponseMessage) === message.id) {
			let token = message.getData(getName(MessageEnum.SessionResponseToken));
			this.setState({ token: token });
			this.getSellerDetail(token)
		}
	}

	recieveUploadedDocuments(message: any) {
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			message.getData(
				getName(MessageEnum.RestAPIResponceErrorMessage)
			);

			if (responseJson !== null && apiRequestCallId === this.getUserUploadedDocumentsFiles && this.state.documentType === "addApplicationForm") {
				this.setState({
					isupload: false,
				})
				window.location.href = '/organization'
			}


			if (apiRequestCallId && responseJson) {
				this.receiveGetUserCategoryData(apiRequestCallId, responseJson);
				this.receiveHandleData(apiRequestCallId, responseJson);
				// get user info
				this.receiveGetUser(message)

				//  Get DynamicFormDataId

				this.receiveGetDynamicFormData(message);

				// Delete Supporting doc


				this.receiveDeleteVenderCallId(message);
				

				// community impact save draft
				this.receiveCommunityImpactSaveDraft(apiRequestCallId, responseJson);

				// community impact save draft
				this.receiveSaveAsDraft(apiRequestCallId);

				this.receivePostSellerDetails(apiRequestCallId);
				
				this.receiveGetSellerDetailMessageId(apiRequestCallId, responseJson);
				this.receiveHandleError(responseJson);
			}

		}
	}

	receiveHandleData(apiRequestCallId: any, responseJson: any) {
		if (apiRequestCallId === this.getDocumentsKey) {
			if (responseJson?.document_urls) {
				this.setState({
					supportiveFiles: responseJson.document_urls,
					fileViewModal: true,
					ifSubmitedBlockFileUploadModal: responseJson.status

				})
				if (responseJson.document_urls.length == 0) {
					this.setState({ fileViewModal: false })
				}

			} else {
				this.setState({
					fileUploadModal: true
				})
			}
		}
		if (apiRequestCallId === this.getUserUploadedDocuments) {
			this.setState({ uploadedDocuments: responseJson.data })
		}
		if (apiRequestCallId === this.getUserCategoryDocuments) {
			this.setState({ categoryDocuments: responseJson.data })
		}
	}

	receiveGetUser(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.getUserUploadedDocumentsFiles &&
			this.getUserUploadedDocumentsFiles ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			this.setState({
				uploadedDocumentLoading: false, progressBarPercentage: 100, uploadFileSize: this.state.totalFileSize,
				uploadButton: true
			})
			clearInterval(this.intervalId)
			this.getAllCategory();
			setTimeout(() => {
				this.handleClose(null);
			}, 500);

			if (responseJson.error) {
				this.setState({ uploadedDocumentLoading: false, progressBarPercentage: 0, uploadFileSize: 0 })
				clearInterval(this.intervalId)
				this.getAllCategory();
				this.handleClose(null);
			}


		}
	
		// get user info
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetOrgInfoId &&
			this.apiGetOrgInfoId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (responseJson.data) {
				this.setState({ is_special: responseJson.data.attributes.is_special })
			}

		}
	}

	receiveGetUserCategoryData(apiRequestCallId: any, responseJson: any) {
		if (apiRequestCallId === this.getUserCategoryDataApiCallId) {

			let _data = responseJson.data;

			for (let i = 0; i < _data.length; i++) {
				let e = _data[i];
				if (e.attributes.status == 'pending' && e.attributes.show_popup_in && e.attributes.show_popup_in?.includes(" ")) {
					let dismissed: any = localStorage.getItem(`${e.attributes.id}_dismissed`);
					let newlyLogin = localStorage.getItem('newly_login');
					dismissed = dismissed == 'true' && newlyLogin == 'false';
					let deadline = new Date(`${e.attributes.category.data.attributes.deadline_date} ${e.attributes.category.data.attributes.deadline_time}`);
					let popupShowTime = new Date(deadline);

					let temp = e.attributes.show_popup_in?.split(" ");
					let durationType = temp[temp.length - 1];
					let duration = parseInt(e.attributes.show_popup_in?.split(" ")[0] ?? '0');
					if (durationType.includes("min")) {
						popupShowTime.setMinutes(popupShowTime.getMinutes() - duration)
					} else if (durationType.includes("sec")) {
						popupShowTime.setSeconds(popupShowTime.getSeconds() - duration)
					}
					else if (durationType.includes("hour")) {
						popupShowTime.setHours(popupShowTime.getHours() - duration)
					}

					
					if (!dismissed && new Date() > popupShowTime && new Date() < deadline) {
						e.showMessage = true;
						localStorage.setItem(`${e.attributes.id}_dismissed`, 'false');
					}
				} else {
					e.showMessage = false;
				}

				_data[i] = e;
			}

			localStorage.setItem('newly_login', 'false');


			this.setState({
				categoryListApi: _data,
				reminderModal: true,
				finalStatus:responseJson?.final_status,
				max_number_of_categories: responseJson?.max_number_of_categories,
				categoryLoading: false
			})

		}
	}

	receiveSaveAsDraft(apiRequestCallId: any) {
		if (apiRequestCallId === this.developerLeadersSaveDraftkey) {
			setTimeout(() => {
				alert('saved successfully')
				location.href = "/organization";
			}, 3000);
		}
	}

	receivePostSellerDetails(apiRequestCallId: any) {
		if (apiRequestCallId === this.state.postSellerDetailsMessageId) {
			this.showModal();
		}
	}

	receiveGetSellerDetailMessageId(apiRequestCallId: any, responseJson:any) {
		if (apiRequestCallId === this.state.getSellerDetailsMessageId) {
			if (responseJson.data && responseJson.data.attributes) {
				let isWholesalerSelected =
					responseJson.data.attributes.wholesaler != undefined
						? responseJson.data.attributes.wholesaler
						: false;
				let isRetailerSelected =
					responseJson.data.attributes.retailer != undefined
						? responseJson.data.attributes.retailer
						: false;
				let isManufacturerSelected =
					responseJson.data.attributes.manufacturer != undefined
						? responseJson.data.attributes.manufacturer
						: false;
				let isHallmarking_centerSelected =
					responseJson.data.attributes.hallmarking_center != undefined
						? responseJson.data.attributes.hallmarking_center
						: false;

				let selectedServices = [];

				if (isWholesalerSelected) {
					selectedServices.push(1);
				}
				if (isManufacturerSelected) {
					selectedServices.push(2);
				}
				if (isHallmarking_centerSelected) {
					selectedServices.push(3);
				}
				if (isRetailerSelected) {
					selectedServices.push(4);
				}

				this.setState({
					sellerID: responseJson.data.id,
					shopName: responseJson.data.attributes.firm_name
						? responseJson.data.attributes.firm_name
						: "",
					address: responseJson.data.attributes.location
						? responseJson.data.attributes.location
						: "",
					gstin: responseJson.data.attributes.gstin_number
						? responseJson.data.attributes.gstin_number
						: "",
					selectedServices: selectedServices,
				});
			}
		}

	}

	receiveHandleError(responseJson: any) {
		if (responseJson.errors && responseJson.errors[0]?.token) {
			if (this.props?.navigation?.navigate && (typeof this.props?.navigation?.navigate === "function")) {
				localStorage.clear()
				this.props?.navigation?.navigate("EmailAccountLoginBlock")
			}
		}
	}

	receiveDeleteVenderCallId(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.deleteVendorCallId &&
			this.deleteVendorCallId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson === undefined) {
				return;
			} else if (
				responseJson &&
				responseJson.errors &&
				responseJson.errors[0].token
			) {
				FailedToast("Failed", responseJson.errors);
			}
			let newData = this.state.supportiveFiles.filter((x: any) => x.file_id !== this.state.deleteDocumentFileId);
			SuccessToast("Deleted", "Document has been deleted successfully.")
			this.getAllCategory();
			this.setState({
				supportiveFiles: newData,
				fileViewModal: newData.length ? true : false
			})

		}
	}

	receiveGetDynamicFormData(message: any) {
		if (
			getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			this.apiGetDynamicFormDataId &&
			this.apiGetDynamicFormDataId ===
			message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
		) {
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.data) {
				localStorage.setItem('section_id', responseJson.data.id);
				let resData = responseJson?.data.attributes
				this.setState({
					DynamicFormData: responseJson.data.attributes.questions.data,
					formSectionDetails:responseJson.data.attributes,
					sectionName: responseJson?.data.attributes.section_name,
					sectionSubtitle: responseJson?.data.attributes.section_subtitle,
					sectionDefinition: responseJson?.data.attributes.application_form_definition || this.state.sectionDefinition,
					DynamicFormNextPageSectionId: resData?.next_section.length > 0 ? resData?.next_section[0].section_id : null,
					DynamicFormPreviousPageSectionId: resData?.prev_section.length > 0 ? resData?.prev_section[0].section_id : null,
				})



			}


		}
	}

	receiveCommunityImpactSaveDraft(apiRequestCallId: any, responseJson: any) {
		if (apiRequestCallId === this.communityImpactSaveDraftkey) {
			localStorage.setItem("payload", JSON.stringify(responseJson?.data?.attributes?.payload))

			if (this.state.triggerType === "next") {
				setTimeout(() => {
					location.href = `${this.state.nextPage}`;
				}, 1000);
			}
			setTimeout(() => {
				alert('saved successfully')
				location.href = "/organization";
			}, 3000);
		}

	}

	hideAlertMessage(id: any) {
		let _data = this.state.categoryListApi;
		for (let i = 0; i < _data.length; i++) {
			if (_data[i].id == id) {
				_data[i].showMessage = false;
				localStorage.setItem(`${_data[i].id}_dismissed`, 'true');
			}
		}

		this.setState({ categoryListApi: _data });
	}

	async componentWillMount() {
		super.componentDidMount();
		this.getUserInfo()
		this.getToken();
		const role = localStorage.getItem("role")?.toLowerCase()
		const user_id = localStorage.getItem("user_id")?.toLowerCase()
		const orgtoken = localStorage.getItem("authToken")?.toLowerCase()

		if (!(role && user_id && orgtoken)) {
			this.props?.navigation?.navigate('EmailAccountLoginBlock')
		}



		if (this.isPlatformWeb() === false) {
			this.props.navigation.addListener("willFocus", () => {
				this.getToken();
			});
		}
		this.getAllCategory();
		const sectionA = JSON.parse(localStorage.getItem('Section A' + localStorage.getItem('categoryId')) ?? "{}");
		const sectionB = JSON.parse(localStorage.getItem('Section B' + localStorage.getItem('categoryId')) ?? "{}");
		const sectionC = JSON.parse(localStorage.getItem('Section C' + localStorage.getItem('categoryId')) ?? "{}");

	this.setState({...this.state, ...sectionA, ...sectionB, ...sectionC});
	this.setState({
		customFormHeading:localStorage.getItem("form_name")
	})

		
	}

	//    category submit 
	handleCategorySubmit = async (catDetails:any) => {
		let catId = catDetails.category?.data?.attributes?.id
		let catName = catDetails.category?.data?.attributes?.name
		localStorage.setItem('categoryId', catId);
		localStorage.setItem('submitted_cat', catName);
		if(catDetails.category?.data?.attributes?.deadline_passed){
			WarningToast("Alert", <>The Deadline time for this application form is over. You will not be able to Edit, Upload or Submit this form any longer. Please reach out to <a href="mailto:SHRMIAwards@shrm.org">SHRMIAwards@shrm.org</a> for any assistance..</>)
		}else{
		this.setState({ categoryIdFormSubmit: catDetails?.id, ConfirmationModal: true })}
	}
	handleSuccessfullySubmitDocOpen = async () => {
		await this.finalSubmission(this.state.categoryIdFormSubmit)
		let catId : any = localStorage.getItem('categoryId');
		let a = +catId;
		this.setState((prevState) => ({
			formLoader: {
			  ...prevState.formLoader,
			  [a]: true,
			},
		  })); 
		
		this.setState({ ConfirmationModal: false })
	}

	handleSuccessfullySubmitDoc = () => {
		this.setState({ successfullySubmitDocModal: !this.state.successfullySubmitDocModal }, () => console.log(this.state.successfullySubmitDocModal))
	}
	handleReminderCancel = () => {
		this.setState({ reminderModal: false })
	}
	count = () => {
		const statuses = this.state.categoryListApi.filter(
			(status: any) => status?.attributes?.clearance_status === "Pending");
		return statuses.length;
	}

	// section A
	handleSaveDraft = () => {
		const {
			name,
			company,
			digitization,
			designation,
			contact,
			mail,
			bussiness,
			entity,
			ownership,
			revenue,
			team,
			revenueGrowth,
			profitGrowth,
			employeeHeadcount,
			contractualEmployeeHeadcount,
			hrEmployeeStrength,
			hrEmployeeRatio,
			genderDiversityRatio,
			avgEmployeeAge,
			attritionRate,
			employeeEngagementScores,
		} = this.state;
		const data: any = {
			name,
			company,
			digitization,
			designation,
			contact,
			mail,
			bussiness,
			entity,
			ownership,
			revenue,
			team,
			revenueGrowth,
			profitGrowth,
			employeeHeadcount,
			contractualEmployeeHeadcount,
			hrEmployeeStrength,
			hrEmployeeRatio,
			genderDiversityRatio,
			avgEmployeeAge,
			attritionRate,
			employeeEngagementScores,
		};
		const categoryId = localStorage.getItem('categoryId');
		localStorage.setItem("Section A" + categoryId, JSON.stringify(data));
		this.setState({})
	};

	handleLearningAndDevelopmentSecB = () => {
		const {
			trainingDev,
			budgetUtiliZation,
			totalLearningManDays,
			avarageTrainingSpend,
			requiredTraining,
			onDemand,
			learningExperience,
			incorporating,
			trendPractice,
			bestPractice,
			effectivenessInitiatives,
			measuringEffectiveness,
			increaseRetention,
			turnover,
			averageRating,
			subjectiveParameters,
			anyOther,
			satisfactionRate,
			metrics,
			unit,
			year18,
			year19,
			year20,
		} = this.state;
		const data: any = {
			trainingDev,
			budgetUtiliZation,
			totalLearningManDays,
			avarageTrainingSpend,
			requiredTraining,
			onDemand,
			learningExperience,
			incorporating,
			trendPractice,
			bestPractice,
			effectivenessInitiatives,
			measuringEffectiveness,
			increaseRetention,
			turnover,
			averageRating,
			subjectiveParameters,
			anyOther,
			satisfactionRate,
			metrics,
			unit,
			year18,
			year19,
			year20,
		};
		const categoryId = localStorage.getItem('categoryId');
		localStorage.setItem("Section B Learn" + categoryId, JSON.stringify(data));
	};
	
	// community impact section B
	handleSaveDraftSecB = async () => {
		const {
			numberOfAreas,
			environment,
			livelihoodPrograms,
			communityHealth,
			communityEducation,
			others,
			budgetAllocation,
			budgetUtiliZation,
			externalFund,
			noOfPeopleImpacted,
			noOfemp_totalDays,
			noOfEmp,
			manDays,
			empPaidNoOfEmp,
			empPaidManDays,
			costOfHumanCapital,
			extendedWomen,
			netProfit,
			initiatives,
			engagementMetrics,
			amountSkillsUpgradation,
			amountUpgradeHardware,
			amountUpgradeSoftware,
			trackWorkingHours,
			avgWorkingHoursPerWeek,
			noOfEmployeesWorkingFromHome_Remotely,
			noOfEmployeesWorkingFromOfficePremises,
			workforceComposition,
			otherHr,
			avarageCostPerHire,
			avarageBenifitCostPerHire,
			avarageTrainingSpend,
			metrics,
			unit,
			year18,
			year19,
			year20,
			descriptivAnalytics,
			diagnosticAnalytics,
			predictiveAnalytics,
			prescriptiveAnalytics,
			learningandDevelopment,
			talentAcquisition,
			transferandJobRotation,
			performanceManagement,
			rewardsandRecognition,
			careerSuccessionPlanning,
			employeeGrievances,
			employeeRetention,
			diversityAndInclusion,
			anyOther,
			trainingSpend,
			returnOnInv,
			variableCom,
			representative,
			reducedCost,
			improved,
			grievance,
			improvedPublic,
			increasedOrganizational,
			noOfEmployeesWorkingFromHome_OfficePremises,
			employeeSatisfaction,
			supplier,
			manpower,
			headcount,
			averageCost,
			averageTime,
			applicants,
			satisfactionRate,
			interviewProcess,
			onBoardingProcess,
			hiringProcess,
			quality,
			newHire,
			vacancies,
			referrals,
			recruiting,
			careerGrowth,
			avaragePerformance,
			executive,
			personWithDisability,
			seniorManager,
			manager,
			total,
			averagePerformance,
			leadershipDevelopmentBudget,
			totalL_DBudget,
			NoOfLeadershipManDays,
			identifiedLeadershipPositions,
			leadersPromoted,
			benchStrength2yearsSuccessors,
			benchStrength5yearsSuccessors,
			identifiedEmergingLeadership,
			PercentageOfEmergingLeaders,
			benchStrength2years,
			benchStrength5years,
			percentageOfvoluntary,
			totalLearningManDays,
			projectRoles,
			secondment,
			internalJobRotation,
			promotions,
			//levring 

			implementationScale,
			noOfofficesCovered,
			digitizedHRprocess,
			fullyDigitizedHRprocess,
			partialDigitizedHRprocess,
			partialDigitizedHRNameprocess,
			manualHRprocess,
			manualHRNameprocess,

			totalHeadCountImpacted,
			noOfmanSavedHRprocess,
			recruitmentTechniques,
			empInduction_orieo,
			compensation_benifits,
			performMgmtSystm,
			administrativeSystems,
			learn_develop,
			communication,
			satidifactionSurveys,
			specifyMeasure,
			successMeasureInitiatives,
			satisfactionScore,
			productiveIncrease,
			inquiresToHR,
			//learning
			trainingDev,

			requiredTraining,
			onDemand,
			learningExperience,
			incorporating,
			trendPractice,
			bestPractice,
			effectivenessInitiatives,
			measuringEffectiveness,
			increaseRetention,
			turnover,
			averageRating,
			subjectiveParameters,

		} = this.state;

		const data: any = {
			numberOfAreas,
			environment,
			livelihoodPrograms,
			communityHealth,
			communityEducation,
			others,
			budgetAllocation,
			budgetUtiliZation,
			externalFund,
			noOfPeopleImpacted,
			noOfemp_totalDays,
			noOfEmp,
			manDays,
			empPaidNoOfEmp,
			empPaidManDays,
			costOfHumanCapital,
			extendedWomen,
			netProfit,
			initiatives,
			engagementMetrics,
			amountSkillsUpgradation,
			amountUpgradeHardware,
			amountUpgradeSoftware,
			trackWorkingHours,
			avgWorkingHoursPerWeek,
			noOfEmployeesWorkingFromHome_Remotely,
			noOfEmployeesWorkingFromOfficePremises,
			noOfEmployeesWorkingFromHome_OfficePremises,
			workforceComposition,
			otherHr,
			avarageCostPerHire,
			avarageBenifitCostPerHire,
			avarageTrainingSpend,
			metrics,
			unit,
			year18,
			year19,
			year20,
			descriptivAnalytics,
			diagnosticAnalytics,
			predictiveAnalytics,
			prescriptiveAnalytics,
			learningandDevelopment,
			talentAcquisition,
			transferandJobRotation,
			performanceManagement,
			rewardsandRecognition,
			careerSuccessionPlanning,
			employeeGrievances,
			employeeRetention,
			diversityAndInclusion,
			anyOther,
			trainingSpend,
			returnOnInv,
			variableCom,
			representative,
			reducedCost,
			improved,
			grievance,
			improvedPublic,
			increasedOrganizational,
			employeeSatisfaction,
			supplier,
			manpower,
			executive,
			seniorManager,
			manager,
			headcount,
			averageCost,
			averageTime,
			applicants,
			satisfactionRate,
			interviewProcess,
			onBoardingProcess,
			hiringProcess,
			quality,
			newHire,
			vacancies,
			referrals,
			recruiting,
			careerGrowth,
			avaragePerformance,
			personWithDisability,
			total,
			averagePerformance,
			leadershipDevelopmentBudget,
			totalL_DBudget,
			NoOfLeadershipManDays,
			identifiedLeadershipPositions,
			leadersPromoted,
			benchStrength2yearsSuccessors,
			benchStrength5yearsSuccessors,
			identifiedEmergingLeadership,
			PercentageOfEmergingLeaders,
			benchStrength2years,
			benchStrength5years,
			percentageOfvoluntary,
			totalLearningManDays,
			projectRoles,
			secondment,
			internalJobRotation,
			promotions,
			implementationScale,
			noOfofficesCovered,
			digitizedHRprocess,
			fullyDigitizedHRprocess,
			partialDigitizedHRprocess,
			partialDigitizedHRNameprocess,
			manualHRprocess,
			manualHRNameprocess,

			totalHeadCountImpacted,
			noOfmanSavedHRprocess,
			recruitmentTechniques,
			empInduction_orieo,
			compensation_benifits,
			performMgmtSystm,
			administrativeSystems,
			learn_develop,
			communication,
			satidifactionSurveys,
			specifyMeasure,
			successMeasureInitiatives,
			satisfactionScore,
			productiveIncrease,
			inquiresToHR,
			trainingDev,
			requiredTraining,
			onDemand,
			learningExperience,
			incorporating,
			trendPractice,
			bestPractice,
			effectivenessInitiatives,
			measuringEffectiveness,
			increaseRetention,
			turnover,
			averageRating,
			subjectiveParameters,
		};
		const categoryId = localStorage.getItem('categoryId');
		localStorage.setItem("Section B" + categoryId, JSON.stringify(data));
	};

	// community impact section C
	handleSaveDraftSecC = async () => {
		const {
			challengeIssues,
			emergedInitiatives,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			communityInitiative,
			information,
			transitionProcess,
			details,
			evp,
			covid,
			separateTeam,
			furtherDetails,
			plannedDigitizationInitiatives,
			hrProcessesImprovedKeyOrganization,
			interventionsCostEffectiveness,
			impactedEmployeeProductivity

		} = this.state;

		const data: any = {
			challengeIssues,
			emergedInitiatives,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			communityInitiative,
			information,
			transitionProcess,
			details,
			evp,
			covid,
			furtherDetails,
			separateTeam,
			plannedDigitizationInitiatives,
			hrProcessesImprovedKeyOrganization,
			interventionsCostEffectiveness,
			impactedEmployeeProductivity
		};
		const categoryId = localStorage.getItem('categoryId');
		localStorage.setItem("Section C" + categoryId, JSON.stringify(data));
	};

	// community impact save draft 
	handleCommunityImpactSaveDraft = async () => {
		const token = await localStorage.getItem("authToken");
		const feedback = 'feedback test';
		const id = localStorage.getItem("categoryId");
		const SectionA = JSON.parse(await localStorage.getItem('Section A' + id) || '{}');
		const SectionB = JSON.parse(await localStorage.getItem('Section B' + id) || '{}');
		const SectionC = JSON.parse(await localStorage.getItem('Section C' + id) || '{}');
		const data = { SectionA, SectionB, SectionC };
		var payload = data;
		try {
			const header = {
				token: localStorage.getItem("authToken")
			};


			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.communityImpactSaveDraftkey = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.saveDraftEndPoint}?application_form[feedback]=${feedback}&application_form[payload]={"payload":${JSON.stringify(payload)}}&token=${token}&id=${id}`
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.saveDraftApiMethod
			);
			runEngine.sendMessage(requestMessage.id, requestMessage);
		} catch (e) {
			console.log(e);
		}


	}



	handleDevelopLeadersSaveDraftSecB = async () => {
		const {
			leadershipDevelopmentBudget,
			totalL_DBudget,
			NoOfLeadershipManDays,
			identifiedLeadershipPositions,
			leadersPromoted,
			benchStrength2yearsSuccessors,
			benchStrength5yearsSuccessors,
			identifiedEmergingLeadership,
			PercentageOfEmergingLeaders,
			benchStrength2years,
			benchStrength5years,
			percentageOfvoluntary,
			totalLearningManDays,
			projectRoles,
			secondment,
			internalJobRotation,
			promotions,
		} = this.state;

		const data: any = {
			leadershipDevelopmentBudget,
			totalL_DBudget,
			NoOfLeadershipManDays,
			identifiedLeadershipPositions,
			leadersPromoted,
			benchStrength2yearsSuccessors,
			benchStrength5yearsSuccessors,
			identifiedEmergingLeadership,
			PercentageOfEmergingLeaders,
			benchStrength2years,
			benchStrength5years,
			percentageOfvoluntary,
			totalLearningManDays,
			projectRoles,
			secondment,
			internalJobRotation,
			promotions,
		}
		const categoryId = localStorage.getItem('categoryId');
		localStorage.setItem("Section B Dev" + categoryId, JSON.stringify(data));
	}

	// developer leaders section C
	handleDevloperLeadersSaveDraftSecC = async () => {
		const {
			challengeIssues,
			emergedInitiatives,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			information,
			transitionProcess
		} = this.state;

		const data: any = {
			challengeIssues,
			emergedInitiatives,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			information,
			transitionProcess
		};
		localStorage.setItem("Section C", JSON.stringify(data));
	};


	// HR technology section B
	handleHRTechnologySecB = async () => {
		const {
			// HR Technology Section B starts here
			implementationScale,
			noOfofficesCovered,
			digitizedHRprocess,
			fullyDigitizedHRprocess,
			partialDigitizedHRprocess,
			partialDigitizedHRNameprocess,
			manualHRprocess,
			manualHRNameprocess,
			budgetAllocation,
			budgetUtiliZation,
			noOfPeopleImpacted,
			noOfEmp,
			totalHeadCountImpacted,
			noOfmanSavedHRprocess,
			recruitmentTechniques,
			empInduction_orieo,
			compensation_benifits,
			performMgmtSystm,
			administrativeSystems,
			learn_develop,
			communication,
			satidifactionSurveys,
			specifyMeasure,
			successMeasureInitiatives,
			satisfactionScore,
			productiveIncrease,
			others,
			inquiresToHR,
			// HR Technology Section B ends here

		} = this.state;

		const data: any = {
			// HR Technology Section B starts here
			implementationScale,
			noOfofficesCovered,
			digitizedHRprocess,
			fullyDigitizedHRprocess,
			partialDigitizedHRprocess,
			partialDigitizedHRNameprocess,
			manualHRprocess,
			manualHRNameprocess,
			budgetAllocation,
			budgetUtiliZation,
			noOfPeopleImpacted,
			noOfEmp,
			totalHeadCountImpacted,
			noOfmanSavedHRprocess,
			recruitmentTechniques,
			empInduction_orieo,
			compensation_benifits,
			performMgmtSystm,
			administrativeSystems,
			learn_develop,
			communication,
			satidifactionSurveys,
			specifyMeasure,
			successMeasureInitiatives,
			satisfactionScore,
			productiveIncrease,
			others,
			inquiresToHR,
			// HR Technology Section B ends here

		}
		localStorage.setItem("Section B", JSON.stringify(data));
	}

	// Hr Technology section C
	handleHRTechnologySecC = async () => {
		const {
			challengeIssues,
			emergedInitiatives,
			hrProcessesImprovedKeyOrganization,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			interventionsCostEffectiveness,
			plannedDigitizationInitiatives,
			information,
			transitionProcess
		} = this.state;

		const data: any = {
			challengeIssues,
			emergedInitiatives,
			hrProcessesImprovedKeyOrganization,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			interventionsCostEffectiveness,
			plannedDigitizationInitiatives,
			information,
			transitionProcess
		};
		localStorage.setItem("Section C", JSON.stringify(data));
	};

	// Hybrid Workplace Section B
	handleHybridWorkplaceB = async () => {
		const {
			workforceComposition,
			noOfEmployeesWorkingFromHome_Remotely,
			noOfEmployeesWorkingFromOfficePremises,
			noOfEmployeesWorkingFromHome_OfficePremises,
			avgWorkingHoursPerWeek,
			trackWorkingHours,
			budgetUtiliZation,
			amountUpgradeSoftware,
			amountUpgradeHardware,
			amountSkillsUpgradation,
			noOfEmp,
			engagementMetrics,
		} = this.state;

		const data: any = {
			workforceComposition,
			noOfEmployeesWorkingFromHome_Remotely,
			noOfEmployeesWorkingFromOfficePremises,
			noOfEmployeesWorkingFromHome_OfficePremises,
			avgWorkingHoursPerWeek,
			trackWorkingHours,
			budgetUtiliZation,
			amountUpgradeSoftware,
			amountUpgradeHardware,
			amountSkillsUpgradation,
			noOfEmp,
			engagementMetrics,
		}
		localStorage.setItem("Section B", JSON.stringify(data));
	}


	// Hybrid Workplace Secion C
	handleHybridWorkplaceC = async () => {
		const {
			challengeIssues,
			emergedInitiatives,
			transitionProcess,
			impactedEmployeeProductivity,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			information,
		} = this.state;

		const data: any = {
			challengeIssues,
			emergedInitiatives,
			transitionProcess,
			impactedEmployeeProductivity,
			effectivenessInitiatives,
			keyStakeholders,
			keyFactors,
			information
		};
		localStorage.setItem("Section C", JSON.stringify(data));
	};

	getUserInfo() {
		const authToken = localStorage.getItem('authToken');

		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			// token: userToken,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetOrgInfoId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getOrganizationInfoApiEndPoints + `organization_details?token=${authToken}`

		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getOrgInfoApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);

	}

	dynamicFileUpload(e: any, que: any): void {
        this.setState({
            DynamicFormData: this.state.DynamicFormData.map((_que: any) => {
                if(_que.id == que.id){
                    _que.attributes.media = e.target.files[0];
                }
                return _que;
            })
        })
    }

	dynamicFormSubmit(): boolean {
		this.apiResetcheckFileUploadCallId = true;
		this.setState({
			saveAsDraftLoading: true,
			requiredClassName: 'required_field_style'
		});
		const userToken = localStorage.getItem("authToken");
		const header = {
			"token": userToken,
		};
		
		let form = new FormData();
		form.append('submission[application_form_id]',`${localStorage.getItem("applicationFormId")}` );
		form.append('submission[section_id]', `${localStorage.getItem('sectionId')}`);
		
		for(let item of this.state.DynamicFormData){
			let qus = item;
			if(item.attributes.answer_type == "Email" && qus.attributes.value && (!qus.attributes.value.includes(".") || !qus.attributes.value.includes("@") || qus.attributes.value.length < 8)){
				FailedToast("Please enter a valid email.", "");
				this.setState({saveAsDraftLoading: false})
				return false;
			}

			form.append("submission[answers_attributes][][question_id]", qus.id);
			if(item.attributes.answer_type == "Number" || 
			item.attributes.answer_type == "Text" || 
			item.attributes.answer_type == "Email" || 
			item.attributes.answer_type == "Textarea"){
				form.append("submission[answers_attributes][][value]", qus.attributes.value || "");
			}
			else if(item.attributes.answer_type == "File upload"){
				form.append("submission[answers_attributes][][media]", qus.attributes.media);
			}
			else if( item.attributes.answer_type == "Multiselect"|| item.attributes.answer_type == "Radio select" || item.attributes.answer_type == "Single select"){
				for(let i of qus.attributes.selected_options){
					form.append("submission[answers_attributes][][selected_options][]", i);
				}
			}
			else if(item.attributes.answer_type == "Table"){
				for(let i of this.state.tableData){
					form.append("submission[answers_attributes][][table_data][][cell_id]", i.cell_id);
					form.append("submission[answers_attributes][][table_data][][value]", i.value);
				}
			}
		}

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		this.apiResetDynamicFileUploadCallId = requestMessage.messageId;
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.dynamicFileUploadApi
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			form
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.apiMethodTypeAddDetail
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return true;
	}

	getDynamicFormData() {
		const category_id = localStorage.getItem('categoryId');
		const section_id = localStorage.getItem('sectionId');


		const authToken = localStorage.getItem('authToken');

		const header = {
			"Content-Type": configJSON.contentTypeApiAddDetail,
			token: authToken,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiGetDynamicFormDataId = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.getDynamicFormDataApiEndPoints + `?category_id=${category_id}&section_id=${section_id}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getDynamicFormDataApiMethod
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);

	}
	// Developer Leaders save draft 
	handleDeveloperLeaderSaveDraft = async () => {
		const token = await localStorage.getItem("authToken");
		const feedback = 'feedback test';
		const id = localStorage.getItem("categoryId");
		const SectionA = JSON.parse(await localStorage.getItem('Section A' + id) || '{}');
		const SectionB = JSON.parse(await localStorage.getItem('Section B' + id) || '{}');
		const SectionC = JSON.parse(await localStorage.getItem('Section C' + id) || '{}');
		const data = { SectionA, SectionB, SectionC };
		var payload = data;

		try {
			const header = {
				token: localStorage.getItem("authToken")
			};

			// var form = new FormData();
			// form.append("user_category_id",id);

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.developerLeadersSaveDraftkey = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.saveDraftEndPoint}?application_form[feedback]=${feedback}&application_form[payload]={"payload":${JSON.stringify(payload)}}&token=${token}&id=${id}`
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.saveDraftApiMethod
			);
			runEngine.sendMessage(requestMessage.id, requestMessage);
		} catch (e) {
			console.log(e);
		}


	}

	handleMultiSelectChange = (value: any , que: any, data: any) => {
		
		this.setState({
			DynamicFormData: this.state.DynamicFormData.map((_que: any) => {
				if(_que.id == que.id){
					if(value){
						_que.attributes.selected_options.push(data.id)
						_que.attributes.value = data.attributes.value;
					}else{
						_que.attributes.selected_options = _que.attributes.selected_options.filter((e: any) => e != data.id);
						_que.attributes.value = data.attributes.value;
					}
				}
				return _que;
			})
		});

		
	}

	onChangeRadio = (value: any , que: any) => { const data = que.attributes.options.data.find((x: any) => x.id === value);
		this.setState({
			DynamicFormData: this.state.DynamicFormData.map((_que: any) => {
				if(_que.id == que.id){
					if(value){
						_que.attributes.selected_options = [data.id]
						_que.attributes.value = data.attributes.value;
						_que.attributes.options.data = _que.attributes.options.data.map((opt: any) => {
							return { ...opt, ...{ attributes: { ...opt.attributes, is_selected: opt.id === value } } }})
					}else{
						_que.attributes.selected_options = _que.attributes.selected_options.filter((e: any) => e != data.id);
						_que.attributes.value = data.attributes.value;
					}
				}
				return _que;
			})
		});

		
	}

	handleSingleSelectChange = (e: any, que: any) => {
		this.setState({ 
			DynamicFormData: this.state.DynamicFormData.map((_que: any) => {
			if(_que.id == que.id){
				_que.attributes.selected_options = [e]
			}
			return _que;
		}) });
	}

	onChangeTableInput = (value: any, id: string, que: any, tableId: any) => {
		

		this.setState({
            DynamicFormData: this.state.DynamicFormData.map((_que: any) => {
                if(_que.id == que.id){
                    let ques_table = _que.attributes.question_table.data;
					ques_table = ques_table.map( (table_data: any) => {
						if(table_data.id == tableId){
							let cells = table_data.attributes.cells.data;
							cells = cells.map( (e: any) => {
								if(e.id == id){
									e.attributes.value = value;
								}
								return e;
							} )
							table_data.attributes.cells.data = cells;
						}

						return table_data;
					} )


					_que.attributes.question_table.data = ques_table;
                }
                return _que;
            })
        })

		let tableData = this.state.tableData;
		for(let item of tableData){
			if(item.cell_id == id){
				item.value = value;
				this.setState({tableData})
				return;
			}
		}
		this.setState({tableData: [...tableData, {cell_id: id, value}]})

		
	}
	onUpload = (e: any) => {
		let file = e.target.files
		this.setState({
			files: file
		}
		)
	}

	handleClick = (value: any) => {
		this.setState({
			fileUploadModal: !this.state.fileUploadModal, selectedData: value

		}, () => {})
	}
	handleClose = (value: any) => {
		this.setState({ files: [], uploadedDocumentPercentage: 0, totalFileSize: 0, progressBarPercentage: 0, uploadFileSize: 0, numOfFile: 0, uploadButton: true });
		this.setState({ fileUploadModal: false }, () => {})
	}
	handleClose2 = (value: any) => {
		this.setState({ files: [] });
		this.setState({ fileSize: false }, () => {})
	}
	handleViewClick = (value: any) => {
		this.setState({ fileViewModal: !this.state.fileViewModal ,finalSubmittedFormStatus:false}, () => {})
	}
	closeConfirmationPopup = () => {
		this.setState({ ConfirmationModal: !this.state.ConfirmationModal })
	}

	handleEditClick = (name :any,deadline_passed:any, formData:any,application_form:any, categoryId:any, sectionId:any, formId:any) => {
		if(deadline_passed){
			WarningToast("Alert", <>The Deadline time for this application form is over. You will not be able to Edit, Upload or Submit this form any longer. Please reach out to <a href="mailto:SHRMIAwards@shrm.org">SHRMIAwards@shrm.org</a> for any assistance..</>)			
		}else if(application_form == null){
			WarningToast("Alert", `There is no associated application form for '${name}'. Please contact SHRM Admin for further assistance.`)
		}else{	
			localStorage.setItem("payload", JSON.stringify(formData.payload));
		 localStorage.setItem("clearance_status", JSON.stringify(formData.clearance_status));		
		this.handleViewClickFileViewModalImage(name, formData, categoryId, sectionId, formId);}
	  }

	handleViewClickFileViewModalImage = (value: any, application_form: any, categoryId: any, sectionId: any, applicationFormId: any) => {
		
		if(application_form == undefined ){
			WarningToast("Alert", `There is no associated application form for '${value}'. Please contact SHRM Admin for further assistance.`)
		}else{
		if(application_form.form_status == "Active"){
		localStorage.setItem('categoryId', categoryId);
		localStorage.setItem('sectionId', sectionId);
		localStorage.setItem('applicationFormId', applicationFormId);
		
		this.setState({ DynamicFormCategoryId: categoryId, DynamicFormSectionId: sectionId, DynamicFormApplicationFormId: applicationFormId })

		if (!categoryId) {
			WarningToast("Alert", `There is no Category associated for '${value}'. Please contact SHRM Admin.`)
		} else if (!sectionId) {
			WarningToast("Alert", `There is no Section associated for '${value}'. Please contact SHRM Admin.`)
		} else {
			this.props.navigation.navigate('DynamicForm')
		}

		
		localStorage.setItem('category_name', value)
		localStorage.setItem('form_name', application_form?.form_name)
		this.setState({
			customFormHeading: value,
			// fileViewModalImage: !this.state.fileViewModalImage,
		}, () => {})
		}else{
			WarningToast("Alert", `There is no Application form active for '${value}'. Please contact SHRM Admin.`)
		}}

	}

	updateData = (data: any) => {
		this.setState({ data: data }, () => {})
	}

	getAllCategory = async () => {
		this.setState({categoryLoading: true});
		try {
			const header = {
				token: localStorage.getItem("authToken"),
				"Content-Type": configJSON.sellerDetailsApiContentType
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.getUserCategoryDataApiCallId = requestMessage.messageId;
			this.getUserUploadedDocuments = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.userCategoryApiEndPoint}`
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.userCategoryApiMethod
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);
			return true;
		} catch (error) {
			console.log(error);
		}
	}

	finalSubmission = async (id: any) => {
		const categoryId = localStorage.getItem("categoryId")		

		try {
			const header = {
				token: localStorage.getItem("authToken"),
				"Content-Type": configJSON.sellerDetailsApiContentType
			};
			const httpBody = {
				id: categoryId,
				// "user_category[clearance_status]":'completed'
			};

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.getCategoryFinalSubmit = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				JSON.stringify(httpBody)
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.categoryFinalSubmissionEndPoint}/final_submission?category_id=${categoryId}`
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.categoryFinalSubmissionMethod
			);



			runEngine.sendMessage(requestMessage.id, requestMessage);
			return true;
		} catch (error) {
			console.log(error);
		}

	}

	getSupportingDoc = async (id: any,deadlineTime:any,data:any) => {
		this.setState({ userCategoryId: id })
		if(data?.status== "completed"){this.setState({finalSubmittedFormStatus:true})}		

if(deadlineTime){
	WarningToast("Alert", <>The Deadline time for this application form is over. You will not be able to Edit, Upload or Submit this form any longer. Please reach out to <a href="mailto:SHRMIAwards@shrm.org">SHRMIAwards@shrm.org</a> for any assistance..</>);
}else{
		if (id) {
			try {
				const header = {
					token: localStorage.getItem("authToken"),
					"Content-Type": configJSON.sellerDetailsApiContentType
				};

				const requestMessage = new Message(
					getName(MessageEnum.RestAPIRequestMessage)
				);

				this.getDocumentsKey = requestMessage.messageId;

				requestMessage.addData(
					getName(MessageEnum.RestAPIResponceEndPointMessage),
					`${configJSON.getDocumentsEndPoints}/${id}/documents`
				);

				requestMessage.addData(
					getName(MessageEnum.RestAPIRequestHeaderMessage),
					JSON.stringify(header)
				);

				requestMessage.addData(
					getName(MessageEnum.RestAPIRequestMethodMessage),
					configJSON.getDocumentsMethod
				);
				this.setState({
					selectedCategoryID: id
				})
				runEngine.sendMessage(requestMessage.id, requestMessage);
			} catch (e) {
				console.log(e);
			}
		}
	}
	}

	handleDeleteDocument = (file: any) => {

		const data = this.state.supportiveFiles.filter((i: any) => i.filename !== file.filename)
		this.setState({ supportiveFiles: data })
	}

	uploadedDocumentSupport = async (files: any) => {
		this.setState({ uploadedDocumentLoading: true })

		this.intervalId = setInterval(() => {
			this.setState({
				progressBarPercentage: this.state.progressBarPercentage + 10,
				uploadFileSize: parseFloat((this.state.uploadFileSize + (this.state.totalFileSize / 10)).toFixed(2))
			});
			if (this.state.progressBarPercentage >= 80) {
				clearInterval(this.intervalId);
			}
		}, 1000);

		try {
			const header = {
				token: localStorage.getItem("authToken"),
			};

			var form = new FormData();
			for (let i = 0; i < files.length; i++) {
				form.append(`user_category[documents][]`, files[i])
			}

			const options = {
				onUploadProgress: (progressEvent: any) => {
					const { loaded, total } = progressEvent;
					let percent = Math.floor((loaded * 100) / total)

					if (percent < 100) {
						this.setState({ uploadPercentage: percent })
					}
				}
			}


			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.getUserUploadedDocumentsFiles = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.userUploadedDocumentsEndPoint}/${this.state.selectedCategoryID}/upload_document`

			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				form
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.userUploadedDocumentsMethod
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);
			return true;
		} catch (error) {
			console.log(error);
		}
	}

	uploadedDocumentstbl = async (files: any) => {
		this.setState({
			isupload: true,
			documentType: "addApplicationForm"
		})

		try {
			const header = {
				token: localStorage.getItem("authToken"),
			};

			var form = new FormData();
			form.append('application_form[media]', files[0]);

			form.append('user_category_id', `${localStorage.getItem('categoryId')}`);
			form.append('application_form[feedback]', 'feedback test')


			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.getUserUploadedDocumentsFiles = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				configJSON.addApplicationFormApiMethod

			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				form
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.userUploadedDocumentsMethod
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);
			return true;
		} catch (error) {
			console.log(error);
		}
	}

	// Delete Application Form
	handleDeleteApplication = async () => {
		alert('Deleting........')
		const id = await localStorage.getItem("categoryId");
		if (id) {
			try {
				const header = {
					token: localStorage.getItem("authToken")
				};

				var form = new FormData();
				form.append("user_category_id", id);

				const requestMessage = new Message(
					getName(MessageEnum.RestAPIRequestMessage)
				);

				this.deleteApplicationKey = requestMessage.messageId;

				requestMessage.addData(
					getName(MessageEnum.RestAPIResponceEndPointMessage),
					`${configJSON.deleteSupporingDocumentApiEndPoint}`
				);

				requestMessage.addData(
					getName(MessageEnum.RestAPIRequestHeaderMessage),
					JSON.stringify(header)
				);
				requestMessage.addData(
					getName(MessageEnum.RestAPIRequestBodyMessage),
					form
				);

				requestMessage.addData(
					getName(MessageEnum.RestAPIRequestMethodMessage),
					configJSON.deleteApplicationFormApiMethod
				);
				runEngine.sendMessage(requestMessage.id, requestMessage);
			} catch (e) {
				console.log(e);
			}
		}
	}

	// Delete Supporting Document

	handleDeleteSupportingDocument = (fileId: any) => {
		this.setState({ deleteDocumentModal: true, deleteDocumentFileId: fileId })
		if (this.state.deleteSupportingDocument == true) {

			const category_id = localStorage.getItem('categoryId');
			const userToken = localStorage.getItem("authToken");

			const headers = {
				"Content-Type": configJSON.validationApiContentType,
				"token": localStorage.getItem("authToken")
			};
			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);
			this.deleteVendorCallId = requestMessage.messageId;
			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.deleteSupporingDocumentApiEndPoint}?user_category_id=${this.state.userCategoryId}&file_id=${fileId}&token=${userToken}`
			);


			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(headers)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.deleteSupporingDocumentApiMethod
			);
			runEngine.sendMessage(requestMessage.id, requestMessage);
			return this.setState({ deleteDocumentModal: false, deleteSupportingDocument: false })
		}

		this.setState({ deleteSupportingDocument: true })
	}

	handleViewDocument = (viewFile: any) => {
		this.setState({
			viewDocumentUrl: viewFile.file_url,
			viewDocumentType: viewFile.file_type,
			viewDocumentName: viewFile.filename,
			viewDocument: true
		})
	}
	handleCloseViewDocument = () => {
		this.setState({
			viewDocument: false
		})
	}


	getToken = () => {
		const msg: Message = new Message(
			getName(MessageEnum.SessionRequestMessage)
		);
		this.send(msg);
	};

	//on close
	handleCancel = (e: any) => {
		this.setState({
			successfullySubmitDocModal: false,
		});
	};

	getCategoryDocuments = async () => {

		try {
			const header = {
				token: localStorage.getItem("authToken"),
				"Content-Type": configJSON.sellerDetailsApiContentType
			};
			

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.getUserCategoryDocuments = requestMessage.messageId;

			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				`${configJSON.userCategoryDocumentEndPoint}/${this.state.categoryDocuments}/documents`
			);

			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.userCategoryDocumentMethod
			);

			runEngine.sendMessage(requestMessage.id, requestMessage);
			return true;
		} catch (error) {
			console.log(error);
		}
	}


	labelShopName: string = "Name of Shop";
	labelAddress: string = "Address";
	labelGstin: string = "GSTIN";
	labelAbout: string = "About Us";
	labelServiceProvided: string = "Services Provided";
	labelDealsIn: string = "Deals in";
	labelShopPhotos: string = "Shop Photos(Min 1 - Max 8)";
	labelVisitingCard: string = "Upload Visiting Card";
	btnLabel: string = "Submit";
	btnContinueLabel: string = "Continue";

	services = [
		{ label: "Wholesaler", value: 1 },
		{ label: "Manufacturer", value: 2 },
		{ label: "Hallmarker", value: 3 },
		{ label: "Retailer", value: 4 },
	];


	// submit application form

	// submitApplicationForm = async (id: any) => {

	// 	this.handleSaveDraftSecC();

	// 	const userToken = localStorage.getItem("authToken");
	// 	const category_id = localStorage.getItem('categoryId');
	// 	const sectionA = JSON.parse(localStorage.getItem('Section A' + localStorage.getItem('categoryId')) ?? "{}");
	// 	const sectionB = JSON.parse(localStorage.getItem('Section B' + localStorage.getItem('categoryId')) ?? "{}");
	// 	const sectionC = JSON.parse(localStorage.getItem('Section C' + localStorage.getItem('categoryId')) ?? "{}");
	// 	const header = {
	// 		"Content-Type": 'application/json',
	// 		token: userToken,
	// 	};
	// 	const httpBody = {
	// 		user_category_id: category_id,
	// 		SectionA: {
	// 			...sectionA
	// 		},
	// 		SectionB: {
	// 			...sectionB
	// 		},
	// 		SectionC: {
	// 			...sectionC
	// 		},
	// 	};

	// 	const requestMessage = new Message(
	// 		getName(MessageEnum.RestAPIRequestMessage)
	// 	);

	// 	this.apiResetApplicationSubmitPostCallId = requestMessage.messageId;

	// 	requestMessage.addData(
	// 		getName(MessageEnum.RestAPIRequestBodyMessage),
	// 		JSON.stringify(httpBody)
	// 	);

	// 	requestMessage.addData(
	// 		getName(MessageEnum.RestAPIResponceEndPointMessage),
	// 		`${configJSON.submitApplicationForm}`
	// 	);

	// 	requestMessage.addData(
	// 		getName(MessageEnum.RestAPIRequestHeaderMessage),
	// 		JSON.stringify(header)
	// 	);
	// 	requestMessage.addData(
	// 		getName(MessageEnum.RestAPIRequestMethodMessage),
	// 		configJSON.apiMethodTypeAddDetail
	// 	);

	// 	runEngine.sendMessage(requestMessage.id, requestMessage);
	// 	return true;
	// }


	getSellerDetail = async (token: string) => {

		const header = {
			"Content-Type": configJSON.sellerDetailsApiContentType,
			token: localStorage.getItem("authToken")
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.setState({
			getSellerDetailsMessageId: requestMessage.messageId,
		});

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			configJSON.sellersAPIEndPoint + "/1"
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.getSellersAPIMethod
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	submitSellerDetails = async () => {

		let token = this.state.token;

		if (!token || token.length === 0) {
			this.showAlert("Error", "Invaild Token. Plese log in.")
			return;
		}
		if (
			this.state.shopName.trim() == ""
		) {
			this.showAlert("Error", configJSON.errorMessageShopName);
			return;
		}

		const header = {
			token: localStorage.getItem("authToken"),
			"Content-Type": configJSON.sellerDetailsApiContentType,
		};

		let isWholesalerSelected = false;
		let isRetailerSelected = false;
		let isManufacturerSelected = false;
		let isHallmarking_centerSelected = false;

		this.state.selectedServices.forEach((value) => {
			switch (value) {
				case 1:
					isWholesalerSelected = true;
					break;
				case 2:
					isManufacturerSelected = true;
					break;
				case 3:
					isHallmarking_centerSelected = true;
					break;
				case 4:
					isRetailerSelected = true;
					break;
			}
		});

		const httpBody = {
			token: token,
			seller_account: {
				firm_name: this.state.shopName,
				location: this.state.address,
				gstin_number: this.state.gstin,
				wholesaler: isWholesalerSelected,
				retailer: isRetailerSelected,
				manufacturer: isManufacturerSelected,
				hallmarking_center: isHallmarking_centerSelected,
				lat: this.state.lat,
				long: this.state.long,
			},
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.setState({
			postSellerDetailsMessageId: requestMessage.messageId,
		});


		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			this.state.sellerID.length > 1 ? configJSON.sellersAPIEndPoint + "/" + this.state.sellerID : configJSON.sellersAPIEndPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			this.state.sellerID.length > 1 ? configJSON.sellerDetailsAPIMethodPUT : configJSON.sellerDetailsAPIMethodPOST
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// validation
	onChange(event: any) {
		if (event.target.value.length < 1) {
			this.setState({ helperText: 'This field is required', error: true });
		} else {
			this.setState({ helperText: '', error: false });
		}
	}

	onServiceSelected = (serviceId: number) => {
		if (!this.state.selectedServices.includes(serviceId)) {
			this.setState({
				selectedServices: this.state.selectedServices.concat(serviceId),
			});
		}
	};

	showModal = () => {
		this.setState({ showSuccessModal: true });
	};

	hideModal = () => {
		this.setState({ showSuccessModal: false }, () => {
		});
	};
	// Customizable Area End
}
