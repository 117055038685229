import React, { useState } from 'react';
// Customizable Area Start
// @ts-nocheck
import AppHeaderWeb from '../../../components/src/AppHeaderWeb.web';
{/* @ts-ignore */}
import logo from '../assets/image_logo@3x.png'
import { Button, Checkbox, List, Layout, Menu, notification, Modal, Form, Input, Card, Divider, Spin } from 'antd';
import CustomFooter from '../../../components/src/Footer.web';
const { Header, Content, Footer } = Layout;
import {Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import EmailAccountRegistrationController, { Props, configJSON } from "./EmailAccountRegistrationController.web";
import { WarningToast } from './Toast';
class CategorySelection extends EmailAccountRegistrationController {
  constructor(props: any) {
    super(props);
  }

  isExists = (item: any) => {
    let exists = this.state.selectedCategories.find(e => e.attributes.id == item.attributes.id);
    if(exists) return true;
    return false;
  }

  defaultExists = (item: any) => {
    return this.state.defaultselectedCategories.some((e:any) => e.id == item.attributes.id);
  }

  render(): React.ReactNode {
    const {selectedCategories} = this.state;

    if(this.state.redirectToLogin){
      return this.state.defaultselectedCategories.length > 0
        // @ts-ignore
        ? <Redirect to='/organization' />
        // @ts-ignore  
        : <Redirect to='/login' />
    }

    return(
      <>
        {/* <AppHeaderWeb /> */}
        <div className="makeStyles-root-1">
          <header className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '205px', justifyContent: 'center' }}>
            <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">
              <h6 className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
                {/* @ts-ignore */}
                <img src={logo} alt="" style={{ height: "50px", margin: "15px" }} />
              </h6>
              <div style={{ height: '255px', display: 'flex', justifyContent: 'center', justifyItems: "center" }}>
                <h1 style={{ fontSize: '35px', fontWeight: 600, color: "#FAAE13", paddingTop: '50px' }}>Choose categories</h1>
              </div>
            </div>
          </header>
        </div>
          <div style={{width: '700px', margin: '50px auto'}}>
                  <List id='list'
header={<div style={{display:'flex' , justifyContent:'space-between', width:'100%'}}><h3>Select categories ( Maximum- {this.state.max_number_of_categories} categories | {this.state.selectedCategories.length ? this.state.selectedCategories.length + ' selected' : 'No category selected'} )</h3><h3> Price (INR)</h3></div>}
              bordered
              dataSource={this.state.allCategories}
              renderItem={item => (
              <List.Item>
                
                 <div style={{display:'flex' , justifyContent:'space-between', width:'100%'}}>
                 <div style = {{
                        color:
                              !this.isExists(item)  && this.state.selectedCategories.length == this.state.max_number_of_categories
                                ? '#bababa' 
                                : 'black' 
                             }}>
                              <Checkbox
                  checked = {this.isExists(item)}
                  onChange={async event => {await this.onSelectCategory(item, event.target.checked)}}
                  disabled={this.defaultExists(item)}
                /> &nbsp;
                    {item?.attributes?.name}
                    </div>
                    <div>{item?.attributes?.price}</div>
                 </div>
              </List.Item>
        )}
      />
       <Card style={{width: '700px', margin: '50px auto',}}>
          <div className='registration-page-invoice'>
              <Form.Item label="Apply Promo code">
                <Input placeholder="Enter promo code here" name="code" onChange={(e)=>{this.getDiscountValue(e.target.value)}}/>
                {/* <div style={{margin:'10px auto', textAlign:'center'}}>
                  <CircularProgress style={{color:'#f00768', height:'15px', width:'15px' }}/>
                </div> */}                
                <small style={{paddingTop:'7px' , color:'#ff4d4f'}}> <b> {this.state.applyPromocodeStatusMsg} </b> </small>
              </Form.Item>
              <Divider />

              <Card style={{background: "#fafafa"}} type="inner" title="Total Price:" extra={<h3>₹ {this.state.subTotalAmount}</h3>}/>
              <Card style={{background: "#fafafa"}} type="inner" title="Total Gst:" extra={<h3>₹ {this.state.gstAmount}</h3>}/>
              <Card style={{background: "#fafafa"}} type="inner" title="Total payment:" extra={<h3>₹ {this.state.totalAmount}</h3>}/>
          </div>
      </Card>
      <br/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <Checkbox 
            value = {this.state.acceptTerms}
            onChange = { e => this.setState({acceptTerms: e.target.checked})}
          />
          &nbsp;&nbsp;
          <p>I hereby accept the <a style={{color: "#f00768"}} href="/terms-and-condition" target='_blank'> Terms and Conditions</a> of SHRM Award</p>
        </div>
        {/* @ts-ignore */}
        {this.state.registrationLoading == true ? <Spin size="large" /> : 
        
         <div >
              <Link to="/">
             <Button 
          type="primary" 
          style={{ 
            marginRight: "20px",
            background:   "#f00768" , 
            borderColor: "#f00768", 
            borderRadius: '5px'}}
          >
           Back
          </Button>
          </Link>
             <Button 
             id="make-payment-btn"
          type="primary" 
          disabled = {this.state.makePaymentButton || this.state.selectedCategories.length === 0 || (this.state.applyPromocodeStatusMsg === 'Not found.')||(this.state.applyPromocodeStatusMsg === 'This promo code is expired.')}
          style={{ 
            background: this.state.makePaymentButton || this.state.selectedCategories.length === 0 || this.state.applyPromocodeStatusMsg === 'Not found.' ||(this.state.applyPromocodeStatusMsg === 'This promo code is expired.') ? "#faa8ca" : "#f00768" , 
            borderColor: "#f00768", 
            borderRadius: '5px'}}
            onClick={() => {
                WarningToast("Warning", "Please complete the registration first.")
              }}
          >
            Make payment
          </Button>
         
         </div>
          
         }
        </div>
      </div>
      {/* @ts-ignore */}
      <CustomFooter />
      </>
     );
  }
}

export default CategorySelection;
// Customizable Area End