// @ts-nocheck
import React, { Component } from 'react';
import { Modal as MModal, Button as BButton, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import cancelIcon from '../src/assets/cancelIcon.png'
import UploadDocModal from '../../blocks/customform/src/UploadDocModal.web';
import { FailedToast } from '../../blocks/email-account-registration/src/Toast';

type MyProps = {
    viewDocument: boolean,
    viewDocumentType: string,
    viewDocumentUrl: string,
    viewDocumentName: string,
    handleCloseViewDocument: Function
};
type MyState = { successfullySubmitModal: boolean };

export default class FilePreviewModal extends Component<MyProps, MyState> {

    constructor(props: any) {
        super(props);
    }

    doFetchDownload = () => {
        fetch(
          `${this.props.viewDocumentUrl}`
        )
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            // the filename you want
            a.download = `${this.props.viewDocumentName}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url); // or you know, something with better UX...
          })
          .catch(() => FailedToast("Error", "something went wrong!"));
      };

    render() {
        return (
            <MModal
                id="view-modal"
                open={this.props.viewDocument}
                onClose={this.props.handleCloseViewDocument}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={{ height: 'auto', margin: '50px auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '25rem' }}>
                    <Paper style={{ padding: '1rem', width: '900px', height: 'calc(100vh - 225px)' }}>
                        <div className="modal-close-button">
                            <img src={cancelIcon} className="modal-close-icon" onClick={this.props.handleCloseViewDocument} />
                        </div>
                        <Typography variant="h5" style={{ fontWeight: 600, margin: '5px 0px' }}>Uploaded File</Typography>
                        <div className="view-uploaded-doc">
                            <UploadDocModal viewDocumentType={this.props.viewDocumentType} viewDocumentUrl={this.props.viewDocumentUrl} />
                        </div>
                        <div style={{ color: '#e6257e', paddingTop: '15px', textAlign: 'center' }}>
                            <BButton id="download" className='btn-primary' onClick={this.doFetchDownload}>download file</BButton>
                        </div>
                    </Paper>
                </div>
            </MModal>
        )
    }
}