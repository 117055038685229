import React, { useState } from 'react';
// Customizable Area Start
// @ts-ignore
import logo from '../assets/image_logo@3x.png'
import { Button, Checkbox, List, Layout, Menu, notification, Modal, Form, Input, Card, Divider, Spin,Row ,Col} from 'antd';
import CustomFooter from '../../../components/src/Footer.web';
import {Redirect} from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import EmailAccountRegistrationController, { Props, configJSON } from "./EmailAccountRegistrationController.web";
class RegistrationCategories extends EmailAccountRegistrationController {
  constructor(props: any) {
    super(props);
  }

  isExists = (item: any) => {
    let exists = this.state.selectedCategories.find(e => e.attributes.id == item.attributes.id);
    if(exists) return true;
    return false;
  }

  defaultExists = (item: any) => {
    return this.state.defaultselectedCategories.some((e:any) => e.id == item.attributes.id);
  }

  async componentDidMount(){
    this.getAllCategories();
    this.getTermsAndConditions();
    this.getAllCategoryPrices(this.state.selectedCategories.length);
    this.getNDAForm();
    if(localStorage.getItem('special')){
      const value = localStorage.getItem('special')
      this.setState({is_special: eval(value || "false") });
    }
    await this.getAllCategories();    
    try{
      if(this.props?.navigation?.getParam('id')){
          await this.getUserCategory()
        }
    }catch(e){
    }
  }
  render(): React.ReactNode {

    if(this.state.redirectToLogin){
      return this.state.defaultselectedCategories.length > 0 
        ?<>{/* @ts-ignore */}
         <Redirect to = '/organization'/>  
         </>
        : <>{/* @ts-ignore */}
        <Redirect to = '/login'/></>
    }
    const display = localStorage.getItem('display_name') 
    return(
      <>
        {/* <AppHeaderWeb /> */}
        <div className="makeStyles-root-1">
          <header id="org-header" className="MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4" style={{ background: 'black', height: '205px', justifyContent: 'center' }}>
            <div className="MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters">

            <div className="MuiTypography-root makeStyles-title-3 MuiTypography-h6">
                                <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                                   {display? <a href="/organization">
                                    {/* @ts-ignore */}
                                    <img src={logo} alt="companyName" style={{ height: "50px", margin: "15px" }} /></a>:
                                    // @ts-ignore
                                    <img src={logo} alt="" style={{ height: "50px", margin: "15px" }} />}

                                    <div style={{marginRight:"30px"}}>
                                      {/* @ts-ignore */}
                                    {display?<Dropdown overlay={<Menu>
                                      {/* @ts-ignore */}
                                        <Menu.Item key="0" onClick={()=>{
                                            localStorage.clear();
                                            window.location.href='/'
                                        }}>
                                            Logout
                                        </Menu.Item>
                                        </Menu>}  trigger={['click']}>
                                            <a style={{color:'#fff'}}>
                                            
                                                <p style={{fontSize:'14px', color: "white"}}>{display}
                                                <DownOutlined  translate={''} style={{marginLeft:'8px'}}/></p>
                                            
                                            </a>
                                        </Dropdown>:null} 
                                    </div>  
                                </div>

                            </div>

              <div style={{ height: '255px', display: 'flex', justifyContent: 'center', justifyItems: "center" }}>
                <h1 style={{ fontSize: '35px', fontWeight: 600, color: "#FAAE13", paddingTop: '50px' }}>Choose Categories</h1>
              </div>
            </div>
          </header>
        </div>
          <div style={{width: '700px', margin: '50px auto'}}>
          {this.state.allCategories.length ?<List id="list"
              header={<div style={{display:'flex' , justifyContent:'space-between', width:'100%'}}><h3>Select categories {!this.state.is_special ? <span>( Maximum- {this.state.max_number_of_categories} categories | {this.state.selectedCategories.length ? this.state.selectedCategories.length + ' selected ' : 'No category selected '})</span> : <span>| {this.state.selectedCategories.length ? this.state.selectedCategories.length + ' selected' : 'No category selected'}</span>}</h3>
              </div>}
              bordered
              dataSource={this.state.allCategories}
              renderItem={item => (
              <List.Item>
                
                 <div style={{display:'flex' , justifyContent:'space-between', width:'100%'}}>
                 <div style = {{
                        color:
                              !this.isExists(item) && !this.state.is_special  && this.state.selectedCategories.length == this.state.max_number_of_categories
                                ? '#bababa'
                                : 'black' 
                             }}>
                              <Checkbox
                  checked = {this.isExists(item)}
                  onChange={async event => {await this.onSelectCategory(item, event.target.checked)}}
                  disabled={this.defaultExists(item)}
                /> &nbsp;
                    {item?.attributes?.name}
                    </div>
                    {/* <div>{item?.attributes?.price}</div> */}
                 </div>
              </List.Item>
        )}
      />:
      // @ts-ignore 
      <Spin size="large" style={{display:'block'}}/> }
       <Card style={{width: '700px', margin: '50px auto',}}>
          <div className='registration-page-invoice'>
              <Form.Item label="Apply Promo code">
                <Input placeholder="Enter promo code here" name="code" value={this.state.promoCode} onChange={(e)=>this.getDiscountValue(e)} disabled={this.state?.selectedCategories?.length- this.state?.selectCat == 0 ?true:false}/>
                
                <small style={{paddingTop:'7px' , color:'#ff4d4f'}}> <b> {this.state.applyPromocodeStatusMsg} </b> </small>
              </Form.Item>
              <Form.Item label="Enter GST number">
              <Input value={this.state.gstNumber}  placeholder="Enter GST number here"  
              onChange={(e)=>this.gstNumber(e)}
              // onChange={(e)=>{this.setState({gstNumber:e.target.value})}}
              />
                <small style={{paddingTop:'7px' , color:'#ff4d4f'}}> <b> {this.state.gstNumberErrorMsg} </b> </small>

              </Form.Item>             
              <Divider />              

              <Card style={{background: "#fafafa"}} type="inner" title="Sub-Total:" extra={<h3>₹ {this.state.subTotalAmount}</h3>}/>
              <Card style={{background: "#fafafa"}} type="inner" title="GST (18%):" extra={<h3>₹ {this.state.gstAmount}</h3>}/>
              <Card style={{background: "#fafafa"}} type="inner" title="Total Amount:" extra={<h3>₹ {this.state.totalAmount}
              </h3>}/>
              <small style={{paddingTop:'7px' , color:'#ff4d4f'}}> <b>{this.state.addMoreCatPriceErrorMsg} </b> </small>
             
          </div>
          
      </Card>
      <br/>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <Checkbox 
            id="tc"
            value = {this.state.acceptTerms}
            onChange = { e => this.setState({acceptTerms: e.target.checked})}
          />
          &nbsp;&nbsp;
          <p>I hereby accept the&nbsp;
          <a id="tc-anchor" style={{color: '#f00768'}} onClick={() => this.showTnCModal()}><b style={{color: "#f00768"}}>Terms and Conditions</b></a>&nbsp;
         
            of SHRM Award</p>
        </div>
        {/* @ts-ignore */}
        {this.state.registrationLoading == true ? <Spin size="large" /> : 
        
        <Button 
          type="primary" 
          disabled = {((this.state.makePaymentButton) || (this.state.selectedCategories.length-this.state?.selectCat === 0 || this.state.promoCodeApplied))}
          style={{ 
            background: ((this.state.makePaymentButton) || (this.state.selectedCategories.length-this.state?.selectCat === 0 || this.state.promoCodeApplied)) ? "#faa8ca" : "#f00768" , 
            borderColor: "#f00768", 
            borderRadius: '5px'}}
            onClick ={this.onMakePayment}
          >
            Make payment 
          </Button>
         }
        </div>
        <Modal
          title={<h1 style={{ fontWeight:700 ,padding:'10px', textAlign: "center"}}>Terms and Conditions</h1>}
          visible={this.state.visibleForTnC}
          onCancel={this.tncHandleCancel}
          footer={<Button 
            style={{
              background:  "#f00768" , 
            borderColor: "#f00768", 
            borderRadius: '5px',
            color: "white"
            }}
            onClick={this.tncHandleCancel} >Close</Button>}
          width={1000}
        >
         <div style={{width: "100%", padding: '20px'}}>
            <div  dangerouslySetInnerHTML={{__html: this.state.terms_and_conditions}}></div>
         </div>
        </Modal>
      </div>
      {/* @ts-ignore */}
      <CustomFooter />
      </>
     );
  }
}
// Customizable Area End
export default RegistrationCategories;