Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.userCategoryApiEndPoint="bx_block_categories/categories/list_user_categories";
exports.userCategoryApiMethod="GET";
exports.userUploadedDocumentsEndPoint="bx_block_categories/categories";
exports.userUploadedDocumentsMethod="POST";
exports.userCategoryDocumentEndPoint="bx_block_categories/categories";
exports.userCategoryDocumentMethod="GET";

exports.getDocumentsEndPoints = 'bx_block_categories/categories'
exports.getDocumentsMethod = 'GET'


exports.categoryFinalSubmissionEndPoint="submissions"
exports.categoryFinalSubmissionMethod="PUT"
exports.addApplicationFormApiMethod="bx_block_categories/categories/add_application_form"
exports.deleteApplicationFormApiEndPoint="bx_block_categories/categories/delete_application_form"
exports.deleteApplicationFormApiMethod="DELETE"

exports.deleteSupporingDocumentApiEndPoint="bx_block_categories/categories/delete_supporting_document"
exports.deleteSupporingDocumentApiMethod="DELETE"

exports.saveDraftEndPoint = "bx_block_categories/categories/save_and_draft";
exports.saveDraftApiMethod = "POST";

exports.getOrgInfoApiMethod="GET";
exports.getOrganizationInfoApiEndPoints = "accounts/";  

exports.getDynamicFormDataApiMethod="GET";
exports.getDynamicFormDataApiEndPoints = "bx_block_categories/questions";  

//submit application form
exports.submitApplicationForm = "bx_block_categories/categories/create_pdf";
exports.apiMethodTypeAddDetail = "POST";

//current time
exports.currentTimeApi = "fetch_current_time";

//file upload
exports.dynamicFileUploadApi = "submissions";

// Customizable Area End
