// @ts-nocheck
import React from 'react';

import {
	Container,
	Box,
	Input,
	Button,
	InputLabel,
	Typography,
	InputAdornment,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Theme,
	// Customizable Area Start
	// Customizable Area End
} from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

// Customizable Area Start
import CustomFooter from '../../../components/src/Footer.web';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import OrgHeaderWeb from '../../customform/src/OrgHeader.web';

createTheme({
	palette: {
		primary: {
			main: '#fff',
			contrastText: '#fff',
		},
	},
	typography: {
		h6: {
			fontWeight: 500,
		},
		subtitle1: {
			margin: '20px 0px',
		},
	},
});
// Customizable Area End

const styles = ({ breakpoints }: Theme) =>
	createStyles({
		consolidatedBox: {
			textAlign: 'right',
		},
		consolidatedButton: {
			backgroundColor: 'rgb(255, 179, 64)',
			color: '#FFF',
			borderRadius: 3,
			textTransform: 'capitalize',
			padding: '5px 15px',
			fontWeight: 400,
			'&:hover': {
				backgroundColor: 'rgb(255, 179, 64)',
			},
		},
		tableHeader1: {
			borderRight: '1px solid rgba(224, 224, 224, 1)',
			borderTop: '1px solid rgba(224, 224, 224, 1)',
			fontWeight: 600,
			width: '80%',
		},
		tableHeader2: {
			textAlign: "center",
			borderTop: '1px solid rgba(224, 224, 224, 1)',
			fontWeight: 600,
			width: '20%',
		},
	});

import {
	LeaderboardController,
	Props,
	configJSON,
} from './LeaderboardController';

class Leaderboard extends LeaderboardController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	componentDidMount() {
		this.gotoConsolidateLeaderboard();
	}
	// Customizable Area End

	render() {
		const { classes } = this.props;
		

		return (
			// Customizable Area Start
			<>
			{/* @ts-ignore */}
			<OrgHeaderWeb/>
				<div className="communityImpactSection-header">
					<header className="header-section">
						<div className="">
							<h1>Leaderboard</h1>
						</div>
					</header>
				</div>
				<Container maxWidth='md' style={{flex:1}}>
					<Box my={4}>
						{/* <Box className={classes.consolidatedBox}>
							<Link to="/consolidate_leaderboard">
							<Button
							
							 className={classes.consolidatedButton}>
								Go To Consolidated Leaderboard
							</Button>
							</Link>
						</Box> */}
						<Box my={2}>
						{this.state.leaderboardData.map((item:any,i:any) =>{	

							let temp = JSON.parse(JSON.stringify(item.attributes.organiztions));
							const orgs = item.showMore ? temp : temp.splice(0, 10);			
						return (<Box my={2} key={item[i]}>
							<TableContainer component={Paper}>
								<Table aria-label='simple table'>
									<TableHead>
										<TableRow>
											<TableCell className={classes.tableHeader1}>
											{item?.attributes?.category_name}
											</TableCell>
											<TableCell className={classes.tableHeader2 }>
												Final Score
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{orgs && orgs.length > 0 ? ((orgs).map((idm:any, idno:any) => {
									return (
										<>
										<TableRow  key={idm[idno]}>
											<TableCell className='table-border-right'> {idm.organization_name} </TableCell>
											<TableCell style={{textAlign: "center"}}> {idm.total_score} </TableCell>
										</TableRow>
										{idno === orgs.length - 1 && item?.attributes?.organiztions.length > 10 && (
											<div className='MuiTableCell-root MuiTableCell-head'><button id="more-btn" className="pink-button" onClick={() => this.handleToggle(i, idno)}>
												{item.showMore ? 'View Less' : 'View More'}
											</button>
											</div>
											)}
											</>
									)})) : (<p style={{textAlign: 'center', "padding": '1em'}}> Organizations not found! </p>)}
									
										
									</TableBody> 
								</Table>
							</TableContainer>
						</Box>)			
													}			
					)}
					
					

						</Box>

					</Box>
				</Container>
				<CustomFooter />
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start

export default withStyles(styles)(Leaderboard as React.ComponentType<any>);

// Customizable Area End
