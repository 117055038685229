Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.userCategoryApiEndPoint="bx_block_categories/categories/list_user_categories";
exports.userCategoryApiMethod="GET";
exports.categoryPriceApiMethod="POST";
exports.selectedCategoryApiEndPoint="bx_block_categories/category_prices";
exports.accountsAPiEndPoint = "account/accounts";
exports.categoryApiEndPoint = 'bx_block_categories/categories';
exports.paymentApiEndPoint = 'bx_block_cfrazorpay9/razorpay/orders';
exports.zeroAmountPaymentApiEndPoint = 'accounts/offline_payment';
exports.paymentAnotherApiEndPoint = 'bx_block_cfrazorpay9/razorpay/payments';
exports.finalRegistrationApiEndPoint = 'bx_block_categories/categories/create_user_categories';
exports.registrationApiEndPoint = 'accounts';
exports.loginApiEndPoint = 'logins';
exports.apiMethodTypeAddDetail = "POST";
exports.apiMethodTypeZeroAmount = "PUT";
exports.gstNumberApiEndPoint = 'accounts/add_gst_number';
exports.apiMethodTypeGstNumber = "PUT";

//jury
exports.organizationListApiEndPoint = 'accounts/list_users';
exports.applicationFormApiEndPoint = 'bx_block_categories/categories/show_application_form';
exports.applicationFormFeedbackApiEndPoint = 'bx_block_categories/categories/form_feedback';
exports.apiMethodTypeFeedback = "POST";
exports.NDAFormApiEndPoint = 'bx_block_categories/categories/get_nda_form';
exports.NDAFormAcceptApiEndPoint = 'bx_block_categories/categories/nda_form_verification';
exports.submitApplicationFormEndPoint = 'bx_block_categories/categories/add_application_form';
exports.EvaluationFormApiEndPoint = 'bx_block_contact_us/get_evaluation_parameters'; 
exports.getDocumentsEndPoints = 'bx_block_categories/categories'

//set score
exports.postSetScoreApiEndPoint = 'bx_block_categories/set_score';
exports.updateSetScoreApiEndPoint = 'bx_block_categories/set_score';

//Zoom 
exports.zoomApiEndPoint = 'bx_block_cfzoomintegration3/zoom/meeting';

// enable set score
exports.setScoreEnableApiEndPoint = 'bx_block_cfzoomintegration3/zoom';

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiMethodPatchType = "PATCH";

// trigger email
exports.triggerEmailEndPoint = "accounts/trigger_mail_l2";
exports.getLeaderboardApiEndPoint = "bx_block_leaderboard/leaderboards";

//forget password
exports.forgetPasswordApiEndPoint = "accounts/forgot_password";

// apply promocode
exports.apiMethodTypeApplyPromocode = "POST";
exports.applyPromocodeApiEndpoint = "bx_block_promo_codes/promo_codes/";

exports.apiMethodGetDiscountValue = "GET";
exports.getDiscountValuePromocodeApiEndpoint = "bx_block_promo_codes/promo_codes/";

exports.categoryPriceEndPoint = "bx_block_categories/categories"
exports.categoryPriceType = "multipart/form-data";

//banner
exports.advertisingBanner = "bx_block_banner/banner";

//terms and condition
exports.termsAndConditionsApiEndPoint = "bx_block_categories/terms_and_conditions";

//privacy and policy
exports.privacyAndPolicyApiEndPoint = "bx_block_categories/privacy_policies";

exports.apiMethodGetIndustryType = "GET";
exports.getIndustryTypeApiEndpoint = "bx_block_industries/industries";

//category price
exports.categoryPriceApiEndPoint = "bx_block_categories/category_prices/get_price";
// zoom api
exports.zoomGetApiEndPoint = "bx_block_cfzoomintegration3/zoom/meeting_details";
exports.zoomPostApiEndPoint = "bx_block_cfzoomintegration3/zoom/meeting";

// Customizable Area End
