//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react';
// Customizable Area Start
import './DynamicForm.css'
import { Card } from '@material-ui/core';
import { Button, Form, Input, InputNumber, Checkbox, Row, Col, Radio, Select, Table, Spin   } from 'antd';
import CustomformController , {Props} from '../CustomformController';
import CustomFooter from '../../../../components/src/Footer.web';
import OrgHeaderWeb from '../OrgHeader.web';
const { TextArea } = Input; 



// Customizable Area End
export default class DynamicForm extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
		
        // Customizable Area End
      }
// Customizable Area Start

async componentDidMount() {
    this.getDynamicFormData()
    this.setState({
		customFormHeading:localStorage.getItem("appHeading")
	})
    
}

generateTableData(data){
    let tableData = [];
    let rowIndex = 2;
    let rowData = {};
    for(let i=0; i< data?.length ?? 0; i++){
        let colData = data[i];
        if(colData.attributes.row_index == 1){
            continue;
        }

        if(colData.attributes.row_index != rowIndex){
            tableData.push(rowData);
            rowData = {};
            rowIndex++;
        }

        if(colData.attributes.row_index == rowIndex){
            rowData[`col_${colData.attributes.column_index}`] =  {type: colData.attributes.cell_type,
                data: colData.attributes.label,
                id: colData.id,
                value: colData.attributes.value
            }
        }

        if(i == (data?.length ?? 0) - 1){
            tableData.push(rowData);
        }
    }
    return tableData;
}

generateTableColumn(data, que, tableId){
    let column = [];
    let rowIndex = 1;
    for(let i=0; i< data?.length ?? 0; i++){
        let colData = data[i];
        if(rowIndex == colData.attributes.row_index){
            column.push({
                title: colData.attributes.label,
                key: i,
                dataIndex: `col_${colData.attributes.column_index}`,
                render: (e) =>  e?.type == 'label' ? e.data : <Input value = {e?.value} onChange = { event => this.onChangeTableInput(event.target.value, e.id, que, tableId)}/>
            })
        }        
    }

    return column//.sort((a, b) => a.row_index - b.row_index);
}
 
handelNextFormButton = async()=>{
    await localStorage.setItem('sectionId',this.state?.DynamicFormNextPageSectionId)
    window.scrollTo(0,0);
    this.getDynamicFormData()
    }

handelBackFormButton = async()=>{
    await localStorage.setItem('sectionId',this.state?.DynamicFormPreviousPageSectionId)
    window.scrollTo(0,0);
    this.getDynamicFormData()
    }


   onFinish = () => {
    };

     onFinishFailed = () => {
    };

    onChangeHandle = (e, que) => {
        this.setState({
            DynamicFormData: this.state.DynamicFormData.map(_que => {
                if(_que.id == que.id){
                    _que.attributes.value = e.target.value;
                }
                return _que;
            })
        })
    }
    
    renderForm = () => {
        return <>{this.state.DynamicFormData.map((que, index) => {
            let questionNum = index + 1 + ". "
            let req = que.attributes.question_required && this.state.requiredClassName != "" && que.attributes.selected_options?.length == 0;
            if (que?.attributes.answer_type === 'Text') return this.renderText(que, index, questionNum);
            if (que?.attributes.answer_type === 'Number') return this.renderNumber(que, index, questionNum);
            if (que?.attributes.answer_type === 'Email') return this.renderEmail(que, index, questionNum);
            if (que?.attributes.answer_type === 'Textarea') return this.renderTextArea(que, index, questionNum)

            if (que?.attributes.answer_type === 'Multiselect') return this.renderMultiSelect(que, index, questionNum)
                

            if (que?.attributes.answer_type === 'Radio select') return this.renderRadioSelect(que, index, questionNum, req)

            if (que?.attributes.answer_type === 'Single select') {
                return this.renderSingleSelect(que, index, questionNum, req)
            }

            if (que?.attributes.answer_type === 'File upload') return this.renderFileUpload(que, index, questionNum, req);


            if (que?.attributes.answer_type === 'Table') return this.renderTable(que, index, questionNum);
        })

        }</>
    }
    renderText = (que, index, questionNum) => {
        return (
            <div className="section-one-form" key={index}>
                <div>
                    <label ><b> {questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</b></label>
                    <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                    <Form.Item

                    >
                        <Input
                        id="text"
                            placeholder={que.attributes?.question_placeholder}
                            value={que.attributes?.value}
                            onChange={e => this.onChangeHandle(e, que)}
                            className={que.attributes.question_required && !que.attributes?.value ? this.state.requiredClassName : ""}
                        />
                    </Form.Item>
                </div>
            </div>
        )
    }

    renderNumber = (que, index, questionNum) => {
        return (
            <div className="section-one-form" key={index}>
                <div>
                    <label style={{ fontWeight: 'bold' }}> {questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                    <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                    <Form.Item

                    >
                        <InputNumber
                            type='number'
                            id="number"
                            style={{ width: '100%' }}
                            placeholder={que.attributes?.question_placeholder}
                            value={que.attributes?.value}
                            onChange={e => this.onChangeHandle({ target: { value: e } }, que)}
                            className={que.attributes.question_required && !que.attributes?.value ? this.state.requiredClassName : ""}

                        />

                    </Form.Item>
                </div>
            </div>
        )
    }

    renderEmail = (que, index, questionNum) => {
        return (
            <div className="section-one-form" key={index}>
                <div>
                    <label style={{ fontWeight: 'bold' }} >{questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                    <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                    <Form.Item
                        rules={[
                            { type: 'email', message: "Please enter valid email address" }
                        ]}

                    >
                        <Input type='email'
                            id="email"
                            placeholder={que.attributes?.question_placeholder}
                            defaultValue={que.attributes?.value}
                            value={que.attributes?.value}
                            onChange={e => this.onChangeHandle(e, que)}
                            className={que.attributes.question_required && !que.attributes?.value ? this.state.requiredClassName : ""}
                        />

                    </Form.Item>
                </div>
            </div>
        )
    }

    renderTextArea = (que, index, questionNum) => {
        return (
            <div className="section-one-form" key={index}>
                <div>
                    <label ><b> {questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</b></label>
                    <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>

                    <Form.Item>
                        <TextArea
                        id="textarea"
                            placeholder={que.attributes?.question_placeholder} showCount
                            value={que.attributes?.value}
                            onChange={e => this.onChangeHandle(e, que)}
                            className={que.attributes.question_required && !que.attributes?.value ? this.state.requiredClassName : ""}
                        />
                    </Form.Item>
                </div>
            </div>
        )
    }

    renderRadioSelect = (que, index, questionNum, req) => {
        const flattened = que?.attributes?.options?.data.map((x) => { return ({ ...x, ...x.attributes, label: x.attributes.value, value: x.id }) });
        const selected = flattened && flattened.length ? flattened.find((x) => x.is_selected) : {};
        return (
            <div style={{ marginBottom: "15px" }} className="section-one-form" key={index}>
                <label style={{ fontWeight: 'bold' }} > {questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                <Radio.Group id="radio" options={flattened} value={selected?.id} onChange={(_e) => this.onChangeRadio(_e.target.value, que)} />
                {req && <p className="required_message">Please select your option.</p>}
            </div>
        )
    }


    renderFileUpload = (que, index, questionNum, req) => {
        return (
            <div style={{ marginBottom: "15px" }} className="section-one-form" key={index}>
                <label style={{ fontWeight: 'bold' }} >{questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                {que?.attributes.media && typeof que?.attributes.media == "string" &&
                    <div>
                        <a href={que?.attributes.media} target='blank'>View uploaded file</a>
                    </div>
                }
                <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                <input id="file" type='file' onChange={(e) => { this.dynamicFileUpload(e, que) }} />
                {(req && !que.attributes.media) ? <p className="required_message">Please upload your file.</p> : <></>}
            </div>
        )
    }

    renderMultiSelect = (que, index, questionNum, req) => {return (
        <div className="section-one-form" key={index}>
            <div style={{ marginBottom: "15px" }}>
                <label style={{ fontWeight: 'bold' }} >{questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                <Row gutter={[16, 16]}>
                    {que.attributes.options.data.map((e, idx) => (

                        <Col span={12}>
                            <Checkbox
                            id="checkbox"
                                checked={que.attributes.selected_options.includes(e.id)}
                                key={idx.id}
                                onChange={(_e) => this.handleMultiSelectChange(_e.target.checked, que, e)}>
                                {e.attributes.value}
                            </Checkbox>
                        </Col>
                    ))}
                    {req && <p className="required_message">Please select your option.</p>}
                </Row>


            </div>
        </div>
    )}

    renderSingleSelect = (que, index, questionNum, req) => {
        const defaultSelected = que.attributes.options.data.find(x => x.attributes.is_selected)?.id;
        return (
            <div style={{ marginBottom: "15px" }} className="section-one-form" key={index}>
                <label style={{ fontWeight: 'bold' }} >{questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
                <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
                <Select style={{ width: '50%' }}
                    showSearch
                    defaultValue={defaultSelected}
                    placeholder={que.attributes?.question_placeholder}
                    optionFilterProp="children"
                    id="select"
                    onChange={(e) => this.handleSingleSelectChange(e, que)}
                >
                    {
                        que.attributes.options.data.map((e, idx) => (
                            <Option value={e.id} key={idx.id}>{e.attributes.value}</Option>
                        ))
                    }
                </Select>
                {req && <p className="required_message">Please select your option.</p>}
            </div>
        )
    }

    renderTable = (que, index, questionNum) => {return (
        <>
            <label style={{ fontWeight: 'bold' }} >{questionNum + que.attributes.question_label}{que.attributes.question_required ? <span style={{ color: "red", fontSize: "20px" }}>*</span> : ""}</label>
            <br />
            <div dangerouslySetInnerHTML={{ __html: que.attributes.question_description }}></div>
            {
                que?.attributes?.question_table?.data.map(data => (
                    <Table className='dynamic_table' bordered
                        dataSource={this.generateTableData(data.attributes?.cells?.data?.sort((a, b) => a.attributes?.row_index - b.attributes?.row_index))}
                        columns={this.generateTableColumn(data.attributes?.cells?.data?.sort((a, b) => a.attributes?.column_index - b.attributes?.column_index), que, data.id)}
                        pagination={false}
                        scroll={{ x: 1300 }}
                    />
                ))
            }


        </>
    );}
    
    
    // Customizable Area End
    render(){
        // Customizable Area Start
        
        const formName = localStorage.getItem("form_name")
        const categoryName = localStorage.getItem("category_name")
        return (
            <div id="content">
				<OrgHeaderWeb/>
				<div className="communityImpactSection-header">
					<header className="header-section">
						<div className="itemCente">
						<div className="">
							<h2 >Application Form: {formName}</h2>
							<h3 className="itemCente" style={{color:'#fff'}}>Category: {categoryName}</h3>
							<h2></h2>           

						</div>
						</div>
					</header>
				</div> 
                    <div className='aplication_form_main' >
                    <Card className='card-custom' elevation={0}>
					<Form
					name="basic"
                     onFinish={this.onFinish}
					 onFinishFailed={this.onFinishFailed}
                    className="form"
                    > 
                    {this.state?.sectionDefinition?.length > 0 &&
                    <div className='form-definition'>
                        <p className='form-definition-title'>Definition: </p>    
                        <span dangerouslySetInnerHTML={{__html: this.state.sectionDefinition}}></span>                           
                    </div>  }
                    <br/>
                    {this.state?.formSectionDetails !== undefined &&                    
                    <div className='form-definition'>
                        <span dangerouslySetInnerHTML={{__html: this.state?.formSectionDetails.form_pre_subtitle}}></span>  
                    </div>  }
                    <div className='sectionHeaderDiv'>
                        <h1><u>{this.state.sectionName} </u></h1>
                    </div>
                    <div className='section-subtitle'>
                        <strong>
                        <p dangerouslySetInnerHTML={{__html: this.state.sectionSubtitle}}></p>
                        </strong>
                    </div>
                      
                        {this.renderForm()}
                
                            {this.state?.formSectionDetails !== undefined &&
                                <><br />
                                    <div className='form-definition'>
                                        <span dangerouslySetInnerHTML={{ __html: this.state?.formSectionDetails?.form_post_subtitle }}></span>
                                    </div>  </>
                            }
                            <br />
                            <br />
                            <div style={{ display: 'flex', alignItem: 'center', justifyContent: 'space-between' }} >
                                {this.state?.DynamicFormPreviousPageSectionId !== null && this.state?.DynamicFormPreviousPageSectionId !== '' ? <Button className='btn_secondary' type="primary" onClick={() => { this.handelBackFormButton() }}>
                                    <img style={{ height: "20px", width: "20px" }} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' />
                                </Button> : <Button className='btn_secondary' type="primary" onClick={() => { this.props.navigation.navigate('Org') }}>
                                    <img style={{ height: "20px", width: "20px" }} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' />
                                </Button>
                                }
                                {
                                    this.state.saveAsDraftLoading ? <Spin size="large" /> :
                                        <Button type="primary" htmlType="save" className="btn_primary" onClick={() => this.dynamicFormSubmit()}>
                                            Save as Draft
                                        </Button>
                                }

                                {this.state?.DynamicFormNextPageSectionId == null ? null :
                                    <Button className="btn_primary" onClick={() => { this.handelNextFormButton() }}>Next</Button>}

                            </div>
						
						</Form>
					</Card>
				</div>
				<CustomFooter/>
            </div>
        );
        //Customizable Area End
    }
}
