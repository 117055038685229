// Customizable Area Start
// @ts-nocheck 
import React from 'react';
import {Redirect, Link} from 'react-router-dom';
import { Button, Card, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
const {Option} = Select;
import info from '../assets/info.png';



class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: any) {
        super(props);
    }
    
    prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select 
            defaultValue = '+91'
           style={{ width: 70 }}
            >
            <Option value="91">+91</Option>
          </Select>
        </Form.Item>
      );

    buttonGroup = (
        <div id="button-group" style = {{display: 'flex', justifyContent:'center', background: '#EEEEEE',borderRadius: '18px'}}>                
                <Button style={{width: '250px',fontSize:'16px', backgroundColor: '#161362', border: 'transparent',borderRadius: '20px',}}
                    type = { this.state.onRegistrationPage ? 'primary' : 'default' }
                    onClick = { () =>  this.setState({onRegistrationPage: true}) }
                >Register</Button>
                <Link to="/login">
                <Button id="login-btn" style={{width: '250px',fontSize:'16px', backgroundColor: 'transparent',border: 'none',color:'black'}}
                    type = { this.state.onRegistrationPage ? 'default' : 'primary' }
                    onClick = { () =>  this.setState({onRegistrationPage: false}) }
                >Login</Button>
                </Link>
            </div>
    )
    validateMobileNumber = (rule, value, callback) => {
        const mobileNumberRegex = /^[6789]\d{9}$/;
        if (!value || mobileNumberRegex.test(value)) {
          callback();
        } else {
          callback('Phone number must be of 10 digits and a valid phone number');
        }
      };
    render(): React.ReactNode {
        if(this.state.redirectToDashboard){
            return(
            <Redirect to = '/organization' />
            );
        }

        if(this.state.redirectToJuryDashboard){
            return(
            <Redirect to = '/jury-member-dashboard' />
            );
        }
        if(this.state.redirect){
            return(
            <Redirect id="redirect" to = '/registration-category' />
            );
        }

        return(
            <>
            
            <div
            id="register"   
                style={{   
                display: 'flex',     
                justifyContent:'center',     
                alignItems:'center' ,
                marginTop: '30px',
                // marginRight: '30px'
                }}>
                    {this.buttonGroup}
            </div>
            <div className='registration-form' style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '800px', width: "500px", margin:'50px auto'}}>                
                <Card>
                    <Form
                    layout="vertical"
                     initialValues={{}}
                     onFinish={this.onFinish}
                         className="form"
                    >
                     <Row gutter={16}>
                        <Col className="gutter-row" span={24}>
                            
                            <Form.Item
                                        label="Organization name"
                                        name="organization_name"
                                        rules={[
                                            {required: true, message: "Please enter organization"},
                                            { max: 120, message: 'Organization name must be 120 character long.' },
                                        ]}
                                    >
                                    <Input />
                            </Form.Item>
                        </Col>
                       
                       
                       <Col xs={24}>
                        <Form.Item
                                label="SPOC name"
                                name="spoc_name"
                                rules={[
                                    {required: true, message: "Please enter SPOC name"},
                                    { max: 50, message: 'SPOC name must be 50 character long.' },
                                ]}
                            >
                            <Input />
                        </Form.Item>
                       </Col>
                        <Col xs={12}>
                            <Form.Item
                                    label="Designation"
                                    name="designation"
                                    rules={[
                                        {required: true, message: "Please enter designation"},
                                        { max: 50, message: 'Designation must be 50 character long.' },
                                    ]}
                                >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={12}>
                            <Form.Item label="Industry type"  name="industry_id" rules={[
                                    {required: true, message: "Please select industry type"}
                            ]}>

                        <Select
                            showSearch
                            placeholder="Select a type"
                            optionFilterProp="children"
                            onChange={this.handleChange}                            
                        >
                            {
                                this.state.industryTypes.map( (e:any, idx:any) => (
                                    <Option value={e.id} key = {idx}>{e.name}</Option>
                                ) ) 
                            }
                        </Select>
                        </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Primary email address"
                                name="email"
                                rules={[
                                    {required: true, type: 'email',  message: "Please enter valid email address"}
                                ]}
                            >
                                  <div style={{display: 'flex',}}>
                                        <Input />
                                        <Tooltip placement="rightTop" title="Primary email of registering SPOC.">
                                            <img src={info} style={{ margin: "6px"}} height="20px" />
                                        </Tooltip>
                                    </div>
                           
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label="Secondary email address"
                                name="secondry_email"
                                rules={[
                                    {required: true, type: 'email',  message: "Please enter valid email address"}
                                ]}
                            >

                                    <div style={{display: 'flex',}}>
                                        <Input />
                                        <Tooltip placement="rightTop" title="Email of 2nd point of contact from the organisation. If primary SPOC is not reachable.">
                                            <img src={info} style={{ margin: "6px"}} height="20px" />
                                        </Tooltip>
                                    </div>
                        
                            </Form.Item>
                        </Col>
                      
                        <Col xs={24}>
                            <Form.Item
                                name="full_phone_number"
                                label="Phone number"
                                        rules={[
                                            { required: true, message: "Please enter phone number" },
                                            { validator: this.validateMobileNumber },
                                        ]}
                            >
                                <Input addonBefore={this.prefixSelector} style={{ width: '100%' }} />
                            </Form.Item>
                       
                        </Col>                       
                        <Col xs={24}>
                            <Form.Item
                                label="Address of the Organization"
                                name="address"
                                rules={[{ required: true, message: 'Please enter address' }]}
                            >
                                <Input.TextArea showCount maxLength={250} />
                            </Form.Item>
                        </Col>
                        <div>
                        <Col xs={24}>
                                <Button 
                                    htmlType="submit"
                                    type="primary" 
                                    loading={this.state.regiterBtnLoader}
                                    style={{ 
                                    background: "#f00768", 
                                    borderColor: "#f00768", 
                                    borderRadius: '5px',
                                    marginBottom: "20px"
                                }}
                                >
                                    Register
                                </Button>
                        </Col>                        
                        </div>
                        </Row>
    
                </Form>
                </Card>
            </div>
            </>
          
        );
    }
}
// Customizable Area End
// }
export default EmailAccountRegistration;

