//@ts-ignore
//@ts-nocheck
import React, { useState } from 'react';
// Customizable Area Start
import { Card, Box, TextField, Button, CardContent, Modal, Paper, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom'
import CustomformController , {Props} from '../CustomformController';
import {StyleSheet} from 'react-native';
import {srmLogo} from '../assets';
import {facebookIcon} from '../assets';
import {twitterIcon} from '../assets';
import {linkdlnIcon} from '../assets';
import {youtubeIcon} from '../assets';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import OrgHeaderWeb from '../OrgHeader.web';
import CustomFooter from '../../../../components/src/Footer.web';

const styles = StyleSheet.create({
    root: {
        flexGrow: 1,
    },
    container: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius:'4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'boxShadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxSizing:'border-box',
        display: 'block',
        textAlign: 'center',
        width:'50%'
    },
    cardContent: {
       padding:'16px',
    },
    textField: {
        marginBottom: '10px',
    },
    innerTextField: {
        marginBottom: '10px',
    },
    table: {
        width: '50%',
        display: 'table',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        borderColor:'grey',
        whiteSpace: 'normal',
        lineHeight: 'normal',
        fontWeight: 'normal',
        fontSize: 'medium',
        fontStyle: 'normal',
        color: '-internal-quirk-inherit',
    },
    tableCell: {
        borderRight: '0.1px solid #D3D3D3',
        padding: '0 30px',
        borderRight: '0.1px solid #D3D3D3'
    },
    tableHead: {
        display: 'table-header-group',
        borderColor: 'inherit',
        verticalAlign: 'middle',
    },
    tableRow:{
        color: 'inherit',
        display: 'table-row',
        outline: 0,
        verticalAlign: 'middle',
        border:'inherit'
    },
    buttons: {
        display: 'flex',
    },
    footer:{
        padding: '24px 50px',
        color: 'rgba(0, 0, 0, 0.85)',
        fontSize: '14px',
        background: '#f0f2f5',
        textAlign: 'center',
    }
	
});



// Customizable Area End
export default class LeaveragingHRTechnologySectionA extends CustomformController{
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
		this.UploadFile= (files)=>{              
			this.setState({...this.state,files:files})
		  }
        // Customizable Area End
      }
// Customizable Area Start
componentDidMount() {
	this.setState({
		customFormHeading:localStorage.getItem("appHeading")
	})	
}

generatePDF = async () => {
	this.setState({
		isdownloading:true,
	})
	setTimeout(() => {
		const divToPrint = document.querySelector('#content') as HTMLElement;
		const domElement = document.getElementById("content") as HTMLImageElement;
		const printbtn = document.getElementById("print") as HTMLImageElement;
		// const draftbtn = document.getElementById("draft") as HTMLImageElement;
		const inputfile = document.getElementById("inputfile") as HTMLImageElement;
		const submitbtn = document.getElementById("submitbtn") as HTMLImageElement;
			printbtn.style.visibility = "hidden";
			// draftbtn.style.visibility = "hidden";
			inputfile.style.visibility = "hidden";
			submitbtn.style.visibility = "hidden";
			html2canvas(divToPrint).then(canvas => {
			const jsPDF = require('jspdf');
			const imgData = canvas.toDataURL('image/png');
			const imgWidth = 190;
			const pageHeight = 290;
			const imgHeight = Math.ceil((canvas.height * imgWidth) / canvas.width);
			let heightLeft = imgHeight;
			const doc = new jsPDF('p', 'mm', 'A4', true);
			let position = 0;
			doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25,'','FAST');
			heightLeft -= pageHeight;
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				doc.addPage();
				doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25,'','FAST');
				heightLeft -= pageHeight;
			}
			doc.save( `${localStorage.getItem('appHeading')}.pdf`,
			this.setState({
				isdownloading:false
			}));
			setTimeout(() => {
				location.reload();
			}, 5000);
		});
	}, 1000);
}
// Customizable Area End
    render(){
        // Customizable Area Start
		// onUpload=(e:any) => {
		// 	let file = e.target.files
		// 	this.setState({
		// 		files:file}
		// 	)
		// 	console.log("file ", file);		
		// }
        const rows = [
            1, 159, 6.0, 24, 4.0
        ]
        return (
            <div id='content'>
                 <OrgHeaderWeb/>
				 <div className="communityImpactSection-header">
					<header className="header-section">
						<div className="">
							<h2 >Application Form</h2>
							<h1>{this.state.customFormHeading}</h1>
						</div>
					</header>
				</div>
                <div  style={{display:"flex",justifyContent: 'center', alignItems: "center", margin: '50px auto', borderRadius:"20px", width:"50%"}}>
                    <Card className={styles.container} style={{boxShadow: "none", border: '1px solid #d6d6d6'}} elevation={0}>
                        <CardContent className={styles.cardContent}>
                            <Box>
                                <Box>
                                    <p><b>Definition:</b> This award seeks to recognize organizations that understand the importance of digitizing HR processes with the aim of Leveraging HR Technology for competitive advantage, and have therefore proactively identified areas for automation to improve HR deliverables and help strengthen the strategic role of HR.</p>
                                </Box>
                                <Box>
                                    <h1 style={{ textAlign: 'center' }}><u>Section A-Participant Information</u></h1>
                                    <p> All questions are mandatory, unless stated otherwise; your questionnaire may not be considered complete if these questions are left blank.</p>
                                </Box>
                                <Box>
								<Box>
										<h4 >Company Name and Date of Incorporation</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Company Name and Date of Incorporation"
											className={styles.textField}
											placeholder="Company name"
											// helperText="Full width!"
											fullWidth required
											value={this.state.company}
											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ company: e.target.value })}
										/>
									</Box>
									<Box>
										Details of SPOC (Single Point of Contact): This could be the CHRO or relevant Functionoal Head.
									</Box>

									<Box  >
										<h4>Name</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Name"
											className={styles.textField}
											placeholder="Name"
											// helperText="Full width!"
											fullWidth required
											value= {this.state.name}
											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ name: e.target.value })}
										/>
									</Box>
									<Box  >
										<h4>Designation</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Designation"
											className={styles.textField}
											placeholder="Enter Designation"
											// helperText="Full width!"
											fullWidth required
											value= {this.state.designation}
											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) =>
												this.setState({ designation: e.target.value })
											}
										/>
									</Box>
									<Box  >
										<h4>Contact Number</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Contact Number"
											className={styles.textField}
											placeholder="Enter Contact Number"
											// helperText="Full width!"
											type="number"
											fullWidth required
											value= {this.state.contact}

											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ contact: e.target.value })}
										/>
									</Box>
									<Box  >
										<h4>Email Address</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Email Address"
											className={styles.textField}
											placeholder="Enter Email Address"
											// helperText="Full width!"
											fullWidth required
											value= {this.state.mail}

											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ mail: e.target.value })}
										/>
									</Box>
									<Box>
										<h4>Nature of Business</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Nature of Business"
											className={styles.innerTextField}
											placeholder="Enter Nature of Business"
											value={this.state.bussiness}
											// helperText="Full width!"
											fullWidth required
											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ bussiness: e.target.value })}
										/>
									</Box>
									<Box>
										<h4>Type of Entity</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Type of Entity"
											className={styles.innerTextField}
											placeholder="Type of Entity"
											// helperText="Full width!"
											fullWidth required
											value={this.state.entity}
											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ entity: e.target.value })}
										/>
									</Box>
									<Box  >
										<h4>Nature of Ownership in India</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Nature of Ownership in India"
											className={styles.innerTextField}
											placeholder="Nature of Ownership in India"
											// helperText="Full width!"
											value={this.state.ownership}
											fullWidth required

											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ ownership: e.target.value })}
										/>
									</Box>
									<Box  >
										<h4>Revenues (as of FY 2021-22)</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="Enter Revenues "
											className={styles.innerTextField}
											placeholder="Enter Revenues"
											// helperText="Full width!"
											value={this.state.revenue}
											fullWidth required

											// InputLabelProps={{
											//     shrink: true,
											// }}
											variant="outlined"
											onChange={(e) => this.setState({ revenue: e.target.value })}
										/>
									</Box>
								</Box>
							</Box>
							
									<Box >
										<h4>In which year did your organization start/implement the HR digitization process? </h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="HR digitization"
											className={styles.innerTextField}
											placeholder="Enter HR digitization"
											fullWidth
											value={this.state.digitization}
											variant="outlined"
											onChange={(e) => this.setState({ digitization: e.target.value })}
										/>
									</Box>
									<Box >
										<h4>Is HR analytics an independent function in your organization? Please elaborate on the existing team composition.</h4>
										<TextField helperText={<p style={{color: "red"}}>Required*</p>}
											id="outlined-full-width"
											label="HR analytics"
											className={styles.innerTextField}
											placeholder="Enter HR analytics"
											fullWidth
											value={this.state.team}
											variant="outlined"
											onChange={(e) => this.setState({ team: e.target.value })}
										/>
									</Box>
							<Box className='formtable'>
								<h4>HR Metrics</h4>
								<Paper style={{overflowX: "auto"}}>
								<Table className={styles.table} aria-label='simple table'>
									<TableHead className={styles.tableHead} style={{background:'#F6F6F6'}}>
										<TableRow className={styles.tableRow}>
											<TableCell><b>Metrics</b></TableCell>
											<TableCell><b>Unit</b></TableCell>
											<TableCell><b>FY 2019-20</b></TableCell>
											<TableCell><b>FY 2020-21</b></TableCell>
											<TableCell><b>FY 2021-22</b></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow className={styles.tableRow}>
											<TableCell>Revenue Growth</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.revenueGrowth['18']}
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.revenueGrowth['19']}
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.revenueGrowth['20']}
													onChange={(e) =>
														this.setState({
															revenueGrowth: {
																...this.state.revenueGrowth,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Profit Growth
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.profitGrowth['18']}
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.profitGrowth['19']}
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.profitGrowth['20']}
													onChange={(e) =>
														this.setState({
															profitGrowth: {
																...this.state.profitGrowth,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total Employee Headcount – FTE/ Permanent Employees			
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeHeadcount['18']}
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeHeadcount['19']}
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeHeadcount['20']}
													onChange={(e) =>
														this.setState({
															employeeHeadcount: {
																...this.state.employeeHeadcount,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total Contractual Employee Headcount
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.contractualEmployeeHeadcount['18']}
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.contractualEmployeeHeadcount['19']}
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.contractualEmployeeHeadcount['20']}
													onChange={(e) =>
														this.setState({
															contractualEmployeeHeadcount: {
																...this.state.contractualEmployeeHeadcount,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Total HR Employee Strength
											</TableCell>
											<TableCell>Nos.</TableCell>
											<TableCell>
												<TextField
													fullWidth required
													type="number"
													value= {this.state.hrEmployeeStrength['18']}
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.hrEmployeeStrength['19']}
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.hrEmployeeStrength['20']}
													onChange={(e) =>
														this.setState({
															hrEmployeeStrength: {
																...this.state.hrEmployeeStrength,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												HR to Employee Ratio
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.hrEmployeeRatio['18']}
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.hrEmployeeRatio['19']}
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.hrEmployeeRatio['20']}
													onChange={(e) =>
														this.setState({
															hrEmployeeRatio: {
																...this.state.hrEmployeeRatio,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Gender Diversity Ratio [females to male]
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.genderDiversityRatio['18']}
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.genderDiversityRatio['19']}
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.genderDiversityRatio['20']}
													onChange={(e) =>
														this.setState({
															genderDiversityRatio: {
																...this.state.genderDiversityRatio,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Average Employee Age
											</TableCell>
											<TableCell>Years/Months</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.avgEmployeeAge['18']}
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.avgEmployeeAge['19']}
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.avgEmployeeAge['20']}
													onChange={(e) =>
														this.setState({
															avgEmployeeAge: {
																...this.state.avgEmployeeAge,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Voluntary Attrition Rate (Annual)
											</TableCell>
											<TableCell>%</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.attritionRate['18']}
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.attritionRate['19']}
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.attritionRate['20']}
													onChange={(e) =>
														this.setState({
															attritionRate: {
																...this.state.attritionRate,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell component='th' scope='row'>
												Employee Engagement Scores
											</TableCell>
											<TableCell>On a scale of 1 to 10</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeEngagementScores['18']}
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																18: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeEngagementScores['19']}
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																19: e.target.value,
															},
														})
													}
												/>
											</TableCell>
											<TableCell style={{ width: 'fit-content' }}>
												<TextField
												type="number"
													fullWidth required
													value= {this.state.employeeEngagementScores['20']}
													onChange={(e) =>
														this.setState({
															employeeEngagementScores: {
																...this.state.employeeEngagementScores,
																20: e.target.value,
															},
														})
													}
												/>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								</Paper>
							</Box>
							<Grid container spacing={3}>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "left" }}>
							<Link to="/organization">
									<Button size='large' variant='contained' id='print' style={{
										backgroundColor: 'white',
										color: '#fff',
										marginLeft: '10px',
										marginTop: '15px',
										cursor: 'pointer'
									}}>  <img style={{height: "20px", width: "20px"}} src='https://cdn-icons-png.flaticon.com/128/2223/2223615.png' /> </Button>
									</Link>
								</Grid>
								<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"center"}}>
										<Button
										onClick={()=>{ this.handleSaveDraft() }}
										size='large' variant='contained' id='print' style={{
																		backgroundColor: '#e6257e',
																		color: '#fff',
																		marginRight: '10px',
																		marginTop: '15px',
																		cursor:'pointer'
																	}}> Save as Draft </Button>
								</Grid>
								<Grid item xs={4} sm={4} md={4} lg={4} xl={4} style={{textAlign:"right"}}>
									<Link to="/hr_technology_b">
										<Button 
										onClick={()=>{ 
											this.handleSaveDraft() }}
										size='large' variant='contained' id='print' style={{
																		backgroundColor: '#e6257e',
																		color: '#fff',
																		marginRight: '10px',
																		marginTop: '15px',
																		cursor:'pointer'
																	}}> Next </Button>
									</Link>									
								</Grid>
							</Grid>

                        </CardContent>
                    </Card>
                </div>
				
               <CustomFooter/>
            </div>
        );
        //Customizable Area End
    }
}
