// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import HomeScreenWeb from '../../components/src/HomeScreenWeb';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Videos4 from '../../blocks/Videos4/src/Videos4';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
// import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import PaymentAdmin2 from '../../blocks/PaymentAdmin2/src/PaymentAdmin2';
import Leaderboard from '../../blocks/Leaderboard/src/Leaderboard';
import CustomForm from '../../blocks/customform/src/Customform';
import DynamicForm from '../../blocks/customform/src/DynamicForm/DynamicForm'
import Analytics from '../../blocks/analytics/src/Analytics';
import CfZoomIntegration3 from '../../blocks/CfZoomIntegration3/src/CfZoomIntegration3';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CfRazorpay9 from '../../blocks/CfRazorpay9/src/CfRazorpay9';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import CvresumeCandidateManagement2 from '../../blocks/CvresumeCandidateManagement2/src/CvresumeCandidateManagement2';
import Scoring from '../../blocks/Scoring/src/Scoring';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import RegistrationCategories from '../../blocks/email-account-registration/src/RegistrationCategories.web';
import CategorySelection from '../../blocks/email-account-registration/src/CategorySelection.web';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import SecBForm from '../../blocks/customform/src/SecBform.web';
import SecCForm from '../../blocks/customform/src/SecCForm.web';
import Org from '../../blocks/customform/src/Org.web';
import LoginSuccessfulDemoDashboard from '../../blocks/email-account-registration/src/LoginSuccessfulDemoDashboard.web';
import JuryMemberDashboard from '../../blocks/email-account-registration/src/JuryMemberDashboard.web';
import EvaluationParameterScreen from '../../blocks/email-account-login/src/EvaluationParameterScreen.web';
import Videos from '../../blocks/videos/src/Videos';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import CreateComment from '../../blocks/comments/src/CreateComment';
// Section A
// import CommunityImpactSectionA from '../../blocks/customform/src/CommunityImpact/CommunityImpactSectionA.web';
// import DevelopingLeaderSectionA from '../../blocks/customform/src/DevelopingLeaders/DevelopingLeaderSectionA.web';
import DiversityInclusionSectionA from '../../blocks/customform/src/DiversityInclusion/DiversityInclusionSectionA.web';
import HealthWealthSectionA from '../../blocks/customform/src/HealthWealth/HealthWealthSectionA.web';
import HRAnalyticSectionA from '../../blocks/customform/src/HRAnalytics/HRAnalyticSectionA.web';
import LearningDevelopmentSectionA from '../../blocks/customform/src/LearningDevelopment/LearningDevelopmentSectionA.web';
import LeaveragingHRTechnologySectionA from '../../blocks/customform/src/LeaveragingHr_Technology/LeaveragingHRTechnologySectionA.web';
import ManageHybridWorkplaceSectionA from '../../blocks/customform/src/ManageHybridWorkplace/ManageHybridWorkplaceSectionA.web';
import TalentAcquistionSectionA from '../../blocks/customform/src/TalentAcquistion/TalentAcquistionSectionA.web';

// Section B
// import CommunityImpactSectionB from '../../blocks/customform/src/CommunityImpact/CommunityImpactSectionB.web';
// import DevelopingLeaderSectionB from '../../blocks/customform/src/DevelopingLeaders/DevelopingLeaderSectionB.web';
import DiversityInclusionSectionB from '../../blocks/customform/src/DiversityInclusion/DiversityInclusionSectionB.web';
import HealthWealthSectionB from '../../blocks/customform/src/HealthWealth/HealthWealthSectionB.web';
import HRAnalyticSectionB from '../../blocks/customform/src/HRAnalytics/HRAnalyticSectionB.web';
import LearningDevelopmentSectionB from '../../blocks/customform/src/LearningDevelopment/LearningDevelopmentSectionB.web';
import LeaveragingHRTechnologySectionB from '../../blocks/customform/src/LeaveragingHr_Technology/LeaveragingHRTechnologySectionB.web';
import ManageHybridWorkplaceSectionB from '../../blocks/customform/src/ManageHybridWorkplace/ManageHybridWorkplaceSectionB.web';
import TalentAcquistionSectionB from '../../blocks/customform/src/TalentAcquistion/TalentAcquistionSectionB.web';

// // Section C
// import CommunityImpactSectionC from '../../blocks/customform/src/CommunityImpact/CommunityImpactSectionC.web';
// import DevelopingLeaderSectionC from '../../blocks/customform/src/DevelopingLeaders/DevelopingLeaderSectionC.web';
import DiversityInclusionSectionC from '../../blocks/customform/src/DiversityInclusion/DiversityInclusionSectionC.web';
import HealthWealthSectionC from '../../blocks/customform/src/HealthWealth/HealthWealthSectionC.web';
import HRAnalyticSectionC from '../../blocks/customform/src/HRAnalytics/HRAnalyticSectionC.web';
import LearningDevelopmentSectionC from '../../blocks/customform/src/LearningDevelopment/LearningDevelopmentSectionC.web';
import LeaveragingHRTechnologySectionC from '../../blocks/customform/src/LeaveragingHr_Technology/LeaveragingHRTechnologySectionC.web';
import ManageHybridWorkplaceSectionC from '../../blocks/customform/src/ManageHybridWorkplace/ManageHybridWorkplaceSectionC.web';
// import TalentAcquistionSectionC from '../../blocks/customform/src/TalentAcquistion/TalentAcquistionSectionC.web';


// import ConsolidateLeaderboard from '../../blocks/Leaderboard/src/ConsolidateLeaderboard.web';

import 'antd/dist/antd.css';
import './App.css'
import PrivacyPolicyScreen from '../../components/src/PrivacyPolicy.web';
import TermsAndConditionScreen from '../../components/src/TermsAndCondition.web';

const routeMap = {
  Org: {
    component: Org,
    path: '/organization'
  },
  // Videos: {
  //   component: Videos,
  //   path: '/Videos'
  // },
  // PostCreation: {
  //   component: PostCreation,
  //   path: '/PostCreation'
  // },
  // Posts: {
  //   component: Posts,
  //   path: '/Posts'
  // },
  // PostDetails: {
  //   component: PostDetails,
  //   path: '/PostDetails'
  // },
  // Comment: {
  //   component: Comment,
  //   path: '/Comment'
  // },
  // CreateComment: {
  //   component: CreateComment,
  //   path: '/CreateComment'
  // },

  // VisualAnalytics: {
  //   component: VisualAnalytics,
  //   path: '/VisualAnalytics'
  // },
  // CountryCodeSelector: {
  //   component: CountryCodeSelector,
  //   path: '/CountryCodeSelector'
  // },
  // PhoneNumberInput: {
  //   component: PhoneNumberInput,
  //   path: '/PhoneNumberInput'
  // },
  // AdditionalDetailForm: {
  //   component: AdditionalDetailForm,
  //   path: '/AdditionalDetailForm'
  // },
  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: '/ForgotPassword'
  // },
  // ForgotPasswordOTP: {
  //   component: ForgotPasswordOTP,
  //   path: '/ForgotPasswordOTP'
  // },
  // NewPassword: {
  //   component: NewPassword,
  //   path: '/NewPassword'
  // },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  // OTPInputAuth: {
  //   component: OTPInputAuth,
  //   path: '/OTPInputAuth'
  // },
  // Videos4: {
  //   component: Videos4,
  //   path: '/Videos4'
  // },
  // AdminConsole3: {
  //   component: AdminConsole3,
  //   path: '/AdminConsole3'
  // },
  // PhotoLibrary3: {
  //   component: PhotoLibrary3,
  //   path: '/PhotoLibrary3'
  // },
  // PaymentAdmin2: {
  //   component: PaymentAdmin2,
  //   path: '/PaymentAdmin2'
  // },
  Leaderboard: {
    component: Leaderboard,
    path: '/leaderboard'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  // CfZoomIntegration3: {
  //   component: CfZoomIntegration3,
  //   path: '/CfZoomIntegration3'
  // },
  // Categoriessubcategories: {
  //   component: Categoriessubcategories,
  //   path: '/Categoriessubcategories'
  // },
  // CfRazorpay9: {
  //   component: CfRazorpay9,
  //   path: '/CfRazorpay9'
  // },
  // EmailNotifications: {
  //   component: EmailNotifications,
  //   path: '/EmailNotifications'
  // },
  // CvresumeCandidateManagement2: {
  //   component: CvresumeCandidateManagement2,
  //   path: '/CvresumeCandidateManagement2'
  // },
  // Scoring: {
  //   component: Scoring,
  //   path: '/Scoring'
  // },
  // RolesPermissions2: {
  //   component: RolesPermissions2,
  //   path: '/RolesPermissions2'
  // },
  // SocialMediaAccountLoginScreen: {
  //   component: SocialMediaAccountLoginScreen,
  //   path: '/SocialMediaAccountLoginScreen'
  // },
  // Contactus: {
  //   component: Contactus,
  //   path: '/Contactus'
  // },
  // AddContactus: {
  //   component: AddContactus,
  //   path: '/AddContactus'
  // },
  // Reviews: {
  //   component: Reviews,
  //   path: '/Reviews'
  // },
  // AddReview: {
  //   component: AddReview,
  //   path: '/AddReview'
  // },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  // SocialMediaAccountRegistrationScreen: {
  //   component: SocialMediaAccountRegistrationScreen,
  //   path: '/SocialMediaAccountRegistrationScreen'
  // },
  // BulkUploading: {
  //   component: BulkUploading,
  //   path: '/BulkUploading'
  // },
  

  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  // Customizable Area Start
  HomeScreen: {
    component: HomeScreenWeb,
    path: '/',
    exact: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicyScreen,
    path: '/privacy-policy',
    exact: true
  },
  TermsAndCondition: {
    component: TermsAndConditionScreen,
    path: '/terms-and-condition',
    exact: true
  },
  DynamicForm:{
    component:DynamicForm,
    path:'/dynamic-form',
    exactt:true,
  },
  // CustomForm: {
  //   component: CustomForm,
  //   path: '/section-a',
  //   exact: true
  // },
  // SecBForm: {
  //   component: SecBForm,
  //   path: '/section-b',
  //   exact: true
  // },
  // SecCForm: {
  //   component: SecCForm,
  //   path: '/section-c'
  // },
  RegistrationCategories: {
    component: RegistrationCategories,
    path: '/registration-category',
    exact: true
  },
  // CategorySelection: {
  //   component: CategorySelection,
  //   path: '/registration-categories',
  //   exact: true
  // },
  RegistrationCategoriesWithID: {
    component: RegistrationCategories,
    path: '/registration-category/:id',
    exact: true
  },
  // LoginSuccessfulDemoDashboard: {
  //   component: LoginSuccessfulDemoDashboard,
  //   path: '/demo-dashboard',
  //   exact: true
  // },
  JuryMemberDashboard: {
    component: JuryMemberDashboard,
    path: '/jury-member-dashboard',
    exact: true
  },
  EvaluationParameterScreen: {
    component: EvaluationParameterScreen,
    path: '/evaluation-parameter',
    exact: true
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/login',
    exact: true
  },
  // Customizable Area End
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: '*/AlertWeb',
  //   modal: true
  // },

  // category form starts here
  // Section A
  //form - 1
  // DiversityInclusionSectionA :{
  //   component: DiversityInclusionSectionA,
  //   path: '/diversity_inclusion'
  // },

  //form - 2 
  // CommunityImpactSectionA :{
  //   component: CommunityImpactSectionA,
  //   path: '/community_impact'
  // },

  // form - 3
  // DevelopingLeaderSectionA : {
  //   component: DevelopingLeaderSectionA,
  //   path: '/develop_leaders'
  // },

  // form - 4 
  // HRAnalyticSectionA : {
  //   component: HRAnalyticSectionA,
  //   path: '/hr_analytic'
  // },

  // form - 5
  // HealthWealthSectionA : {
  //   component: HealthWealthSectionA,
  //   path: '/health_wealth'
  // }, 

  // form - 6
  // LearningDevelopmentSectionA : {
  //   component: LearningDevelopmentSectionA,
  //   path: '/learn_develop'
  // },

  // form - 7
  // LeaveragingHRTechnologySectionA :{
  //   component: LeaveragingHRTechnologySectionA,
  //   path: '/hr_technology'
  // },

  // form - 8
  // ManageHybridWorkplaceSectionA : {
  //   component: ManageHybridWorkplaceSectionA,
  //   path :'/hybrid_workplace'
  // },

  // form - 9
  // TalentAcquistionSectionA : {
  //   component: TalentAcquistionSectionA,
  //   path: '/talent_acquisition'
  // },

  // Section B
  //form - 1
  // DiversityInclusionSectionB :{
  //   component: DiversityInclusionSectionB,
  //   path: '/diversity_inclusion_b'
  // },

  //form - 2 
  // CommunityImpactSectionB :{
  //   component: CommunityImpactSectionB,
  //   path: '/community_impact_b'
  // },

  // form - 3
  // DevelopingLeaderSectionB : {
  //   component: DevelopingLeaderSectionB,
  //   path: '/develop_leaders_b'
  // },

  // form - 4 
  // HRAnalyticSectionB : {
  //   component: HRAnalyticSectionB,
  //   path: '/hr_analytic_b'
  // },

  // form - 5
  // HealthWealthSectionB : {
  //   component: HealthWealthSectionB,
  //   path: '/health_wealth_b'
  // }, 

  // form - 6
  // LearningDevelopmentSectionB : {
  //   component: LearningDevelopmentSectionB,
  //   path: '/learn_develop_b'
  // },

  // form - 7
  // LeaveragingHRTechnologySectionB :{
  //   component: LeaveragingHRTechnologySectionB,
  //   path: '/hr_technology_b'
  // },

  // form - 8
  // ManageHybridWorkplaceSectionB : {
  //   component: ManageHybridWorkplaceSectionB,
  //   path :'/hybrid_workplace_b'
  // },

  // form - 9
  // TalentAcquistionSectionB : {
  //   component: TalentAcquistionSectionB,
  //   path: '/talent_acquisition_b'
  // },

  // Section C
  //form - 1
  // DiversityInclusionSectionC :{
  //   component: DiversityInclusionSectionC,
  //   path: '/diversity_inclusion_c'
  // },

  //form - 2 
  // CommunityImpactSectionC :{
  //   component: CommunityImpactSectionC,
  //   path: '/community_impact_c'
  // },

  // form - 3
  // DevelopingLeaderSectionC : {
  //   component: DevelopingLeaderSectionC,
  //   path: '/develop_leaders_c'
  // },

  // form - 4 
  // HRAnalyticSectionC : {
  //   component: HRAnalyticSectionC,
  //   path: '/hr_analytic_c'
  // },

  // form - 5
  // HealthWealthSectionC : {
  //   component: HealthWealthSectionC,
  //   path: '/health_wealth_c'
  // }, 

  // form - 6
  // LearningDevelopmentSectionC : {
  //   component: LearningDevelopmentSectionC,
  //   path: '/learn_develop_c'
  // },

  // form - 7
  // LeaveragingHRTechnologySectionC :{
  //   component: LeaveragingHRTechnologySectionC,
  //   path: '/hr_technology_c'
  // },

  // form - 8
  // ManageHybridWorkplaceSectionC : {
  //   component: ManageHybridWorkplaceSectionC,
  //   path :'/hybrid_workplace_c'
  // },

  // form - 9
  // TalentAcquistionSectionC : {
  //   component: TalentAcquistionSectionC,
  //   path: '/talent_acquisition_c'
  // },


  // consolidated form
  // ConsolidateLeaderboard : {
  //   component : ConsolidateLeaderboard, 
  //   path : '/consolidate_leaderboard'
  // },

  Leaderboard : {
    component : Leaderboard, 
    path : '/leaderboard'
  }


};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh'}}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
