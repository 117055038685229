import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
	getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from './assets';
import { FailedToast } from '../../email-account-registration/src/Toast';
// Customizable Area End

export const configJSON = require('./config');

const styles = createStyles({});

export interface Props extends WithStyles<typeof styles> {
	navigation: any;
	id: string;
	classes: any;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	txtInputValue: string;
	txtSavedValue: string;
	enableField: boolean;
	// Customizable Area Start
	leaderboardData: any;
	isViewMore:boolean;
	scoreId:any,
	loading: any
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export class LeaderboardController extends BlockComponent<Props, S, SS> { 
	// Customizable Area Start
	leaderboardApiCallId: any = "";
	consolidatedLeaderboardApiCallId: any = "";
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area Start
			// Customizable Area End
		];

		this.state = {
			txtInputValue: '',
			txtSavedValue: 'A',
			enableField: false,
			isViewMore:false,
			scoreId:'',
			// Customizable Area Start
			leaderboardData: [],
			loading: true,
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start
		// Customizable Area End
	}

	async receive(from: String, message: Message) {
		
	  
		  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
			  getName(MessageEnum.RestAPIResponceDataMessage)
			);
	  
			var responseJson = message.getData(
			  getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
	  
			var errorReponse = message.getData(
			  getName(MessageEnum.RestAPIResponceErrorMessage)
			);
			
			// Leaderboard
			  if(apiRequestCallId === this.leaderboardApiCallId){

			if(responseJson && responseJson?.assigned_categories_leaderboard)	 {
				let resp = responseJson?.assigned_categories_leaderboard.data.sort((a: any, b: any) => {
					return b.attributes.organiztions.length - a.attributes.organiztions.length;
				  });

				resp.forEach((item: {
					attributes: {
						sum: any; organiztions: any[];
					}
				}) => {
					if (item.attributes.organiztions.length > 0) {
							const totalScoreSum = item.attributes.organiztions.reduce((accumulator, currentValue) => {
								return accumulator + currentValue.total_score;
							}, 0);
							item.attributes.sum = totalScoreSum;
							item.attributes.organiztions.sort((a, b) => b.total_score - a.total_score);
						}
					
					
				  });
				  resp = resp.sort((a: any, b: any) => {
					return b.attributes.sum - a.attributes.sum;
				  });

				  
				  for(let i = 0 ; i < resp.length; i++) {
					if(resp[i].attributes.organiztions_with_null_scores && resp[i].attributes.organiztions_with_null_scores.length)  {
						for(let j = 0; j < resp[i].attributes.organiztions_with_null_scores.length; j++) {
							resp[i].attributes.organiztions_with_null_scores[j].total_score = 'N/A';
						}
					}
					resp[i].attributes.organiztions = [...resp[i].attributes.organiztions, ...resp[i].attributes.organiztions_with_null_scores]
				  }

				this.setState({
					leaderboardData: resp
				});
			} 
				
				else {
					FailedToast("Error", responseJson.errors[0])
				 }
			  }
			  if (apiRequestCallId === this.consolidatedLeaderboardApiCallId) {
				  if (responseJson && responseJson?.consolidated_leaderboard) {
					  let resp = responseJson?.consolidated_leaderboard.data.sort((a: any, b: any) => {
						  return b.attributes.organizations.length - a.attributes.organizations.length;
					  });

					  resp.forEach((item: {
						  attributes: {
							  sum: any; organizations: any[];
						  }
					  }) => {
						  if (item.attributes.organizations.length > 0) {
							  const totalScoreSum = item.attributes.organizations.reduce((accumulator, currentValue) => {
								  return accumulator + currentValue.final_score;
							  }, 0);
							  item.attributes.sum = totalScoreSum;
							  item.attributes.organizations.sort((a, b) => b.final_score - a.final_score);
						  }

					  });
					  resp = resp.sort((a: any, b: any) => {
						  return b.attributes.sum - a.attributes.sum;
					  });

					  this.setState({
						  leaderboardData: resp
					  });
				  }

				  else {
					  FailedToast("Error", responseJson.errors[0])
				  }
				  this.setState({ loading: false });
			  }
			}
			

			 
  
	}


	// Consolidated Leaderboard
	gotoConsolidateLeaderboard = () => {
		const userId= localStorage.getItem("user_id");
		 
			const header = {
			  token: localStorage.getItem("authToken"),
			}; 				
		
			const requestMessage = new Message(
			  getName(MessageEnum.RestAPIRequestMessage)
			);		
			
			this.leaderboardApiCallId = requestMessage.messageId;
				
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.validationApiMethodType
			  );

			requestMessage.addData(
			  getName(MessageEnum.RestAPIResponceEndPointMessage),
			  `${configJSON.getLeaderboardApiEndPoint}?account_id=${userId}`
		
			);
		
			requestMessage.addData(
			  getName(MessageEnum.RestAPIRequestHeaderMessage),
			  JSON.stringify(header)
			);
		
			runEngine.sendMessage(requestMessage.id, requestMessage);
			
	}

	fetchConsolidatedLeaderboard = () => {
		const userId= localStorage.getItem("user_id");
		 
			const header = {
			  token: localStorage.getItem("authToken"),
			}; 				
		
			const requestMessage = new Message(
			  getName(MessageEnum.RestAPIRequestMessage)
			);		
			
			this.consolidatedLeaderboardApiCallId = requestMessage.messageId;
				
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.validationApiMethodType
			  );

			requestMessage.addData(
			  getName(MessageEnum.RestAPIResponceEndPointMessage),
			  `${configJSON.getConsolidatedLeaderboardApiEndPoint}`
		
			);
		
			requestMessage.addData(
			  getName(MessageEnum.RestAPIRequestHeaderMessage),
			  JSON.stringify(header)
			);
		
			runEngine.sendMessage(requestMessage.id, requestMessage);
			
	}
	handleToggle(i: any, idno: any) {
		const x = JSON.parse(JSON.stringify(this.state.leaderboardData));
		x[i].showMore = !x[i].showMore;
		this.setState({leaderboardData: x})
	  }

	// web events
	setInputValue = (text: string) => {
		this.setState({ txtInputValue: text });
	};

	setEnableField = () => {
		this.setState({ enableField: !this.state.enableField });
	};

	// Customizable Area Start
	// Customizable Area End
}

const StyleComponent = withStyles(styles)(LeaderboardController);

export default StyleComponent;
